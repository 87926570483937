import {API, Resource} from '@coveo/platform-client';
import {SynonymConfigurationModel, SynonymRulesListModel} from '../../interfaces';
import {SynonymsListOptions} from '../../interfaces/commerce/SynonymsInterface';

export class Synonyms extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/search/synonyms`;

    create(synonymRule: SynonymConfigurationModel, trackingId: string): Promise<SynonymConfigurationModel> {
        return this.api.post<SynonymConfigurationModel>(
            this.buildPath(`${Synonyms.baseUrl}`, {trackingId}),
            synonymRule,
        );
    }

    list(options: SynonymsListOptions): Promise<SynonymRulesListModel> {
        return this.api.get<SynonymRulesListModel>(this.buildPath(`${Synonyms.baseUrl}`, options));
    }

    delete(synonymRuleId: string, trackingId: string): Promise<void> {
        return this.api.delete<void>(this.buildPath(`${Synonyms.baseUrl}/${synonymRuleId}`, {trackingId}));
    }

    get(synonymRuleId: string, trackingId: string): Promise<SynonymConfigurationModel> {
        return this.api.get<SynonymConfigurationModel>(
            this.buildPath(`${Synonyms.baseUrl}/${synonymRuleId}`, {trackingId}),
        );
    }

    update(
        synonymRuleId: string,
        synonymRule: SynonymConfigurationModel,
        trackingId: string,
    ): Promise<SynonymConfigurationModel> {
        return this.api.put<SynonymConfigurationModel>(
            this.buildPath(`${Synonyms.baseUrl}/${synonymRuleId}`, {trackingId}),
            synonymRule,
        );
    }
}
