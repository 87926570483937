import {API, Resource} from '@coveo/platform-client';
import {
    ChunkInspectorStartJobResponse,
    ChunkInspectorStatusResponse,
    QueryChunkResponse,
} from '../../../interfaces/knowledge/ChunkInspectorTypes';

export class ChunkInspectorSearchResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/chunkinspector/searchquery/v1/chunkretrievaljobs`;

    startJob(params: {searchId: string}): Promise<ChunkInspectorStartJobResponse> {
        return this.api.post<ChunkInspectorStartJobResponse>(ChunkInspectorSearchResource.baseUrl, params);
    }

    getJobStatus(jobId: string): Promise<ChunkInspectorStatusResponse> {
        return this.api.get<ChunkInspectorStatusResponse>(`${ChunkInspectorSearchResource.baseUrl}/${jobId}/status`);
    }

    getChunks(jobId: string): Promise<QueryChunkResponse> {
        return this.api.get<QueryChunkResponse>(`${ChunkInspectorSearchResource.baseUrl}/${jobId}/result`);
    }
}
