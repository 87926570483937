import {API, ModifierModel, ModifierStatementDetailModel, Resource} from '@coveo/platform-client';
import {LicenseRestrictedModel} from '../interfaces/OrganizationSettingsInterfaces';

/**
 *
 * This API is not publicly available. It is intended for Coveo use only (it's not in the public Swagger API).
 */
export class OrganizationSettingsResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}`;

    getLicenseRestricted() {
        return this.api.get<LicenseRestrictedModel>(
            this.buildPath(`${OrganizationSettingsResource.baseUrl}/license/restricted`),
        );
    }

    getAvailableStatementDetails() {
        return this.api.get<ModifierStatementDetailModel[]>(
            this.buildPath(`${OrganizationSettingsResource.baseUrl}/definition/statementdetails`),
        );
    }

    getAvailableModifierTemplates() {
        return this.api.get<ModifierModel[]>(
            this.buildPath(`${OrganizationSettingsResource.baseUrl}/definition/modifiertemplates`),
        );
    }
}
