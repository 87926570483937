import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {FormatWithResolvedOptions, MissingFormatOptions} from '../api';
import {MissingFormatWithOptions} from './internal';

// While not used directly in this file, ensure patterns like 'LL', 'LT' et cetera work.
dayjs.extend(localizedFormat);

export interface DayjsFormatOptions {
    pattern: string;
}

export interface DayjsResolvedFormatOptions {
    locale: string;
    pattern: string;
}

export class DayjsFormat implements FormatWithResolvedOptions<dayjs.ConfigType, DayjsResolvedFormatOptions> {
    protected readonly pattern: string;

    constructor(options: DayjsFormatOptions) {
        Object.defineProperty(this, 'pattern', {value: options.pattern});
    }

    format(value: dayjs.ConfigType): string {
        return dayjs(value).format(this.pattern);
    }

    resolvedOptions(): DayjsResolvedFormatOptions {
        return {locale: dayjs.locale(), pattern: this.pattern};
    }
}

export class DayjsMissingFormat extends MissingFormatWithOptions<dayjs.ConfigType, DayjsResolvedFormatOptions> {
    constructor(options: DayjsFormatOptions & MissingFormatOptions) {
        super(new DayjsFormat(options), options);
    }
}
