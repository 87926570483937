import {ProjectModel, ProjectResourceType} from '..';

/**
 * Converts the provided query params into a string
 *
 * @param queryObject Query params in an object format
 * @returns Stringified query parameters
 */
const convertObjectToQueryString = (queryObject?: Record<string, string>): string => {
    if (!queryObject) {
        return '';
    }

    return Object.keys(queryObject)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]))
        .join('&');
};

/**
 * Enriches request URL by taking into account a selected project
 *
 * @param requestUrl URL of incoming request
 * @param subStringToAppend String to apped at the end of the base URL
 * @param queryOptions Query parameters to add to the request
 * @returns Enriched request URL with provided parameters
 */
const enrichRequestUrlFilteredByProject = (
    requestUrl: string,
    subStringToAppend = '',
    queryOptions?: Record<string, any>,
): string => {
    const splitUrl = requestUrl.split('?');
    const hasParams = splitUrl.length > 1;
    const stringifiedQueryOptions = convertObjectToQueryString(queryOptions);

    return hasParams
        ? `${splitUrl[0]}${subStringToAppend}?${splitUrl[1]}${stringifiedQueryOptions && `&${stringifiedQueryOptions}`}`
        : `${splitUrl[0]}${subStringToAppend}${stringifiedQueryOptions && `?${stringifiedQueryOptions}`}`;
};

/**
 * Creates the body to append to a request that needs to take into account a project
 *
 * @param currentProject Selected project
 * @param resourceType Current resource type
 * @param idsIntersectionParamName Parameter to add to the body
 * @returns Body to pass to enriched request by project
 */
const createRequestBodyFilteredByProject = (
    currentProject: ProjectModel,
    resourceType: ProjectResourceType,
    idsIntersectionParamName?: string,
): string[] | Record<string, any> =>
    idsIntersectionParamName
        ? {
              [idsIntersectionParamName]: currentProject.resources?.[resourceType] ?? [`no-associated-${resourceType}`],
          }
        : (currentProject.resources?.[resourceType] ?? [`no-associated-${resourceType}`]);

export const FeaturesHelpers = {
    enrichRequestUrlFilteredByProject,
    createRequestBodyFilteredByProject,
};
