import {ComboboxStore, Factory, factory} from '@components/mantine';
import {OrganizationModel} from '@core/api';
import {ForwardedRef, ReactNode} from 'react';

interface ChildrenParams {
    combobox: ComboboxStore;
    currentOrg: OrganizationModel;
    ref: ForwardedRef<HTMLButtonElement>;
}

export interface TargetProps {
    children?: ({combobox, currentOrg, ref}: ChildrenParams) => ReactNode;
    combobox?: never;
    currentOrg?: never;
}

export type OrganizationPickerTargetFactory = Factory<{
    props: TargetProps;
    ref: HTMLButtonElement;
    stylesNames: string;
    compound: true;
}>;

export const OrganizationPickerTarget = factory<OrganizationPickerTargetFactory>(
    ({children, combobox, currentOrg}, ref) => children({combobox, currentOrg, ref}),
);
