import {Resource} from '@coveo/platform-client';
import {TransformPipelinePreviewRequest, TransformPipelinePreviewResponse} from '../interfaces/PreviewInterfaces';

/**
 * API used to get a preview of search results.
 *
 * This API is documented in the internal Swagger documentation: https://platform.cloud.coveo.com/docs/internal?urls.primaryName=Search%20API#/Analysis%20V3/preview
 *
 * @private
 */
export class PreviewResource extends Resource {
    static baseUrl = `/rest/search/v3/analysis/preview`;

    /**
     * Get a preview of the results returned by a query going through a query pipeline.
     *
     * @param {string} pipelineId The unique identifier of the query pipeline.
     * @param {TransformPipelineReviewRequest} transformRequest The transformation to apply to the query pipeline.
     *
     */
    pipelinePreview(pipelineId: string, transformRequest: TransformPipelinePreviewRequest) {
        return this.api.post<TransformPipelinePreviewResponse>(
            this.buildPath(`${PreviewResource.baseUrl}/pipelines/${pipelineId}`, {
                organizationId: this.api.organizationId,
            }),
            transformRequest,
        );
    }
}
