import {Center, Loader} from '@components/mantine';
import {FunctionComponent} from 'react';
import {Page} from './Page';

export const PageLoader: FunctionComponent = () => (
    <Page.Body display="flex">
        <Center h="100%" w="100%">
            <Loader />
        </Center>
    </Page.Body>
);
