import {
    FormatToParts,
    FormatValueType,
    isNumberAutoScaleFormatAutoUnit,
    MissingFormatOptions,
    MissingFormatPart,
    MissingValue,
    NumberAutoScaleFormatAutoUnit,
    ResolvedMissingFormatOptions,
} from '../api';
import {Formatting, NumberMissingFormat} from '../implementation';
import {formatLocale, resolveMissing} from './sharedConfig';

const largeNumberThreshold = 10_000;

const cache = new Map<string, NumberFormatType>();

type NumberFormatType = FormatToParts<
    FormatValueType<Intl.NumberFormat> | MissingValue,
    Intl.ResolvedNumberFormatOptions & ResolvedMissingFormatOptions,
    Intl.NumberFormatPart | MissingFormatPart
>;

type NumberFormatterAllowedOptions = Pick<
    Intl.NumberFormatOptions,
    'currency' | 'notation' | 'minimumFractionDigits' | 'maximumFractionDigits' | 'unit' | 'unitDisplay'
>;

const createNumberFormatter = ({
    currency,
    notation,
    minimumFractionDigits,
    maximumFractionDigits,
    unit,
    unitDisplay,
}: NumberFormatterAllowedOptions): NumberFormatType => {
    let style: Intl.NumberFormatOptions['style'];
    let key: string = '';
    if (currency) {
        style = 'currency';
        key = currency.toUpperCase();
    } else if (unit) {
        style = 'unit';
        key = `${unit.toLowerCase()},${unitDisplay ?? 'default'}`;
    } else {
        style = 'decimal';
    }
    key = `${style}[${key}]-${notation ?? 'standard'}[${minimumFractionDigits ?? 'auto'},${maximumFractionDigits ?? 'auto'}]`;
    let fmt = cache.get(key);
    if (fmt) {
        return fmt;
    }
    const options: Intl.NumberFormatOptions & MissingFormatOptions = {
        missing: resolveMissing(notation === 'compact'),
        style,
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
        unit,
        unitDisplay,
    };
    if (isNumberAutoScaleFormatAutoUnit(unit)) {
        // Limit the default number of maximum digits for compact.
        if (notation === 'compact' && typeof options.maximumFractionDigits !== 'number') {
            options.maximumFractionDigits = Math.max(2, options.minimumFractionDigits ?? 0);
        }
        // Note: don't combine compact notation with an auto scale selecting format
        fmt = new NumberMissingFormat(formatLocale, options);
    } else {
        fmt = Formatting.hybridNumberFormat(
            formatLocale,
            options,
            largeNumberThreshold,
            // Note: compact notation needs fraction digits to show a value like '1.25M'.
            {notation, minimumFractionDigits: 0, maximumFractionDigits: notation === 'compact' ? 2 : 0},
        );
    }
    cache.set(key, fmt);
    return fmt;
};

const createPercentFormatter = ({
    minimumFractionDigits,
    maximumFractionDigits,
}: Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'> = {}): NumberFormatType =>
    new NumberMissingFormat(formatLocale, {
        // Always use the "compact" representation of missing for percentages, as their representation is generally short.
        missing: resolveMissing(true),
        style: 'percent',
        minimumFractionDigits,
        maximumFractionDigits,
    });

// Don't specify fraction digits for currency, each currency has their own default
const createCurrencyFormatter = (notation: 'compact' | 'standard', currency: string) =>
    createNumberFormatter({notation, currency});

type NumberFormatterUnitOptions = Omit<NumberFormatterAllowedOptions, 'notation' | 'currency' | 'number'> & {
    unit: string | NumberAutoScaleFormatAutoUnit;
};

const createUnitFormatter = (notation: 'compact' | 'standard', options: NumberFormatterUnitOptions) => {
    if (!options?.unit) {
        throw new RangeError('Unit formatters require a unit');
    }
    return createNumberFormatter({
        ...options,
        notation,
        // Select a default unitDisplay based on notation, if not provided.
        unitDisplay: options.unitDisplay ?? (notation === 'standard' ? 'long' : 'short'),
    });
};

/**
 * Contains default Admin-UI number formatters.
 * Note that all formatters come in two "flavors": compact and full.
 * The compact formatter is meant for places with less space, for example titles or axis ticks in charts.
 * The full formatters aim to show the data in full detail, and should be used for tooltips and table cell display of values.
 */
export const NumberFormatter = Object.freeze({
    /**
     * The locale used by these formatters.
     */
    formatLocale,

    /**
     * Formatter for numbers that may have decimals, switching to compact notation for higher values.
     */
    decimalCompact: createNumberFormatter({notation: 'compact', maximumFractionDigits: 2}),

    /**
     * Formatter for numbers that may have decimals.
     */
    decimalFull: createNumberFormatter({maximumFractionDigits: 2}),

    /**
     * Formatter for integer numbers (no decimals), switching to compact notation for higher values.
     */
    integerCompact: createNumberFormatter({notation: 'compact', maximumFractionDigits: 0}),

    /**
     * Formatter for integer numbers (no decimals).
     */
    integerFull: createNumberFormatter({minimumFractionDigits: 0, maximumFractionDigits: 0}),

    /**
     * Formatter for percentages, rounding to whole integers percentages.
     * Note that `1` represents `100%`, so the value should be the ratio (not multiplied by a hundred).
     */
    percentCompact: createPercentFormatter({minimumFractionDigits: 0, maximumFractionDigits: 0}),

    /**
     * Formatter for percentages, allowing decimals for precision.
     * Note that `1` represents `100%`, so the value should be the ratio (not multiplied by a hundred).
     */
    percentFull: createPercentFormatter({minimumFractionDigits: 0, maximumFractionDigits: 2}),

    /**
     * Formatter for file sizes (in bytes), automatically selecting the best display scale (kilo, mega, up to peta).
     * Note that this uses binary 2¹⁰ == 1024 scale, with SI units, just like Windows displays sizes.
     */
    fileSizeCompact: createUnitFormatter('compact', {
        unit: 'auto-bibyte',
        maximumFractionDigits: 1,
    }),

    /**
     * Formatter for file sizes (in bytes), automatically selecting the best display scale (kilo, mega, up to peta).
     * Note that this uses binary 2¹⁰ == 1024 scale, with SI units, just like Windows displays sizes.
     */
    fileSizeFull: createUnitFormatter('standard', {
        unit: 'auto-bibyte',
        maximumFractionDigits: 3,
    }),

    /**
     * Get a formatter for a currency, switching to compact notation for higher values.
     * @param currency the three-letter (uppercase) currency code.
     * @returns The formatter for the specified currency.
     */
    currencyCompact: (currency: string) => createCurrencyFormatter('compact', currency),

    /**
     * Get a formatter for a currency, switching to compact notation for higher values.
     * @param currency the three-letter (uppercase) currency code.
     * @returns The formatter for the specified currency.
     */
    currencyFull: (currency: string) => createCurrencyFormatter('standard', currency),

    /**
     * Get a formatter for a unit, switching to compact notation for higher values.
     * @param options The options for the formatter, containing at least the unit.
     * @returns The formatter for the specified unit.
     */
    unitCompact: (options: NumberFormatterUnitOptions) => createUnitFormatter('compact', options),

    /**
     * Get a formatter for a unit, switching to compact notation for higher values.
     * @param options The options for the formatter, containing at least the unit.
     * @returns The formatter for the specified unit.
     */
    unitFull: (options: NumberFormatterUnitOptions) => createUnitFormatter('standard', options),
});
