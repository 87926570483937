import {Badge, MantineColor} from '@components/mantine';
import {FunctionComponent} from 'react';
import {Locales} from '../strings/Locales';

export type BadgeVariant = 'beta' | 'wip' | 'new';

const badgeColorMapping: Record<BadgeVariant, MantineColor> = {
    wip: 'warning',
    beta: 'red.3',
    new: 'new',
};

export const NavigationBadge: FunctionComponent<{variant: BadgeVariant}> = ({variant}) => (
    <Badge color={badgeColorMapping[variant]} variant="light" tt="none" size="lg" radius="md" data-navbadge={variant}>
        {Locales.format(`Badge.${variant}`)}
    </Badge>
);
