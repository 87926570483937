import {Notifications} from '@components/mantine';
import {PlatformAppTheme} from '@configurations/platform';
import {NavigationBlockerProvider} from '@components/page';
import {Outlet} from '@core/routes';
import {getStore} from '@core/store';
import {FunctionComponent, PropsWithChildren} from 'react';
import {Provider} from 'react-redux';

import {ExternalPage} from '../page';

export const ExternalLayout: FunctionComponent<PropsWithChildren> = ({children}) => (
    <Provider store={getStore()}>
        <PlatformAppTheme>
            <NavigationBlockerProvider>
                <ExternalPage>
                    <Notifications position="top-center" zIndex={4000} />
                    <Outlet />
                    {children}
                </ExternalPage>
            </NavigationBlockerProvider>
        </PlatformAppTheme>
    </Provider>
);
