import {API, ProjectResourceType, Resource} from '@coveo/platform-client';

/**
 * This API manages the resources of the project feature.
 *
 * This API is intended for Coveo's internal use.
 */
export class ProjectInternal extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/projects`;

    /**
     * Sets the project ids associated to the provided resource.
     *
     * @param {string} resourceId
     * @param {ProjectResourceType} resourceType
     * @param {string[]} associatedProjectIds
     * @returns {Promise<void>}
     */
    updateAssociations(
        resourceId: string,
        resourceType: ProjectResourceType,
        associatedProjectIds: string[],
    ): Promise<void> {
        return this.api.post<void>(
            this.buildPath(
                `${ProjectInternal.baseUrl}/resources/${resourceId}/projectids?resourceType=${resourceType}`,
            ),
            associatedProjectIds,
        );
    }
}
