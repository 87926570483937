import {API, ListHostedInterfacesParams, NewHostedInterface, PageModel, Resource} from '@coveo/platform-client';
import {DuplicateInProductExperienceModel, IPXInterfaceConfiguration} from '../interfaces';

/**
 * Temporary resource as a workaround until the platform-client corresponding resource is updated
 * to accept the new IPXInterfaceConfiguration interface and the createPipelines query parameter.
 */
export class IPXInterfaceExperimentalResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/ipxinterface/v1/interfaces`;

    /**
     * Create a new IPX interface.
     * @param ipxInterfaceConfig The IPX interface configuration.
     * @param createPipelines Whether to create the pipelines or not.
     * @returns The created IPX interface configuration.
     */
    create(
        ipxInterfaceConfig: NewHostedInterface<IPXInterfaceConfiguration>,
        createPipelines: boolean = false,
    ): Promise<IPXInterfaceConfiguration> {
        return this.api.post(
            `${IPXInterfaceExperimentalResource.baseUrl}?createPipelines=${createPipelines}`,
            ipxInterfaceConfig,
        );
    }

    /**
     * List all hosted IPX interfaces.
     * @param options The list options.
     * @returns The list of hosted IPX interfaces.
     */
    list(options?: ListHostedInterfacesParams): Promise<PageModel<IPXInterfaceConfiguration>> {
        return this.api.get<PageModel<IPXInterfaceConfiguration>>(
            this.buildPath(IPXInterfaceExperimentalResource.baseUrl, options),
        );
    }

    /**
     * Duplicate a hosted IPX interface.
     * @param duplicateParams The required params to duplicate an IPX.
     * @returns The duplicated IPX interface configuration.
     */
    duplicate(duplicateParams: DuplicateInProductExperienceModel, ipxId: string): Promise<IPXInterfaceConfiguration> {
        return this.api.post(`${IPXInterfaceExperimentalResource.baseUrl}/${ipxId}`, duplicateParams);
    }
}
