import {Anchor, Button, Group, notifications, Text} from '@components/mantine';
import {LaunchDarklyFeatureFlags} from '@core/feature-flags';
import {Translation} from '@core/locales';
import {FunctionComponent} from 'react';
import {LaunchDarklyFlags} from '../feature-flags/FeatureFlags';
import {Locales} from '../strings/Locales';
import {survey} from '../utils/SurveyEvents';

interface UserFeedbackModalFooterProps {
    canSubmit: boolean;
    onSubmit: () => void;
    onSkip: () => void;
    onDontAskAgain: () => void;
}

export const UserFeedbackModalFooter: FunctionComponent<UserFeedbackModalFooterProps> = ({
    canSubmit,
    onSubmit,
    onSkip,
    onDontAskAgain,
}) => (
    <>
        <Text pt="sm" ta="left">
            {LaunchDarklyFeatureFlags.isActive(LaunchDarklyFlags.SurveyIncognitoOrgs) ? (
                Locales.format('UserFeedbackModal.legalDeclarationUntrackedOrgs.description')
            ) : (
                <Translation t={Locales} i18nKey="UserFeedbackModal.legalDeclaration.description">
                    <Anchor href="https://www.coveo.com/en/pages/privacy-policy" />
                </Translation>
            )}
        </Text>
        <Group justify="space-between" pt="xl">
            <Button
                variant="subtle"
                onClick={() => {
                    onDontAskAgain();
                    survey.hide();
                    notifications.showSuccess({
                        title: Locales.format('UserFeedbackNotification.thankYou.title'),
                        message: Locales.format('UserFeedbackNotification.stopShowingSurvey.description'),
                    });
                }}
            >
                {Locales.format('UserFeedbackModal.dontaskmeagainButton')}
            </Button>
            <Group justify="right">
                <Button
                    variant="default"
                    onClick={() => {
                        onSkip();
                        survey.hide();
                    }}
                >
                    {Locales.format('UserFeedbackModal.footer.notNowButton')}
                </Button>
                <Button
                    variant="filled"
                    disabled={!canSubmit}
                    onClick={() => {
                        onSubmit();
                        survey.hide();
                        notifications.showSuccess({
                            title: Locales.format('UserFeedbackNotification.gotIt.title'),
                            message: Locales.format('UserFeedbackNotification.helpingFeedback.description'),
                        });
                    }}
                >
                    {Locales.format('UserFeedbackModal.footer.submitButton')}
                </Button>
            </Group>
        </Group>
    </>
);
