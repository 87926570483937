import {API, Resource} from '@coveo/platform-client';
import {
    BasePreviewRequestModel,
    PreviewResultModel,
    RecommendationBaseRuleModel,
    RecommendationRulePreviewRequestModel,
} from '../../interfaces';

export class RecommendationRulePreview<T extends RecommendationBaseRuleModel> extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/recommendations/preview/rules`;

    /**
     * Preview the impact of creating a recommendation rule on a config.
     * @param requestModel RecommendationRulePreviewRequestModel<T>
     * @param options RecommendationRulePreviewOptions
     * @returns PreviewResponeModel
     */
    create(requestModel: RecommendationRulePreviewRequestModel<T>, options?: RecommendationRulePreviewOptions) {
        return this.api.post<PreviewResultModel>(
            this.buildPath(RecommendationRulePreview.baseUrl, options),
            requestModel,
        );
    }

    /**
     * Preview the impact of a recommendation rule in a given slot configuration.
     * @param ruleId string
     * @param option BasePreviewRequestModel
     * @returns PreviewResultModel
     */
    get(ruleId: string, options?: BasePreviewRequestModel) {
        return this.api.get<PreviewResultModel>(
            this.buildPath(`${RecommendationRulePreview.baseUrl}/${ruleId}`, options),
        );
    }

    /**
     * Preview the impact of an updated recommendation's rule in a given slot configuration.
     * The updated rule is compared against not having the rule or,
     * if compareWithPreviousState is true, against the previous state of the rule.
     * @param ruleId string
     * @param options RecommendationRulePreviewUpdateOptions
     * @param model T extends RecommendationBaseRuleModel
     * @returns PreviewResultModel
     */
    update(
        ruleId: string,
        options: RecommendationRulePreviewUpdateOptions,
        model: RecommendationRulePreviewRequestModel<T>,
    ) {
        return this.api.put<PreviewResultModel>(
            this.buildPath(`${RecommendationRulePreview.baseUrl}/${ruleId}`, options),
            model,
        );
    }
}

// temp - interfaces to be moved elsewhere
interface RecommendationRulePreviewOptions {
    /**
     * Whether the rule is for the global config.
     * @default false
     */
    isEditingGlobal?: boolean;
}
interface RecommendationRulePreviewUpdateOptions {
    /**
     * When previewing an update to a rule, whether to compare the preview results against
     * those from one executed with the previous state of the rule,
     * or against a one executed without that rule at all.
     */
    compareWithPreviousState?: boolean;
}
