import {EntitlementModel, IndexType, LicenseConnectorModel, LicenseModel, ProductType, SourceType} from '@core/api';
import {TableSelectors} from '@coveord/plasma-react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {createSelector} from 'reselect';

import {LicenseConstants} from '../constants';
import {LicenseHelper} from '../helpers';
import {LicenseState, LicenseStore} from '../store';

dayjs.extend(isSameOrBefore);

export const permittedUndefinedValues: string[] = [
    'listFieldValuesQuotaPerUserLimit',
    'listFieldValuesQuotaPerOrgLimit',
];

export const editableSearchOrder: string[] = [
    'maxPipelinesLimit',
    'maxPipelineConditionsLimit',
    'maxPipelineStatementsLimit',
    'queryQuotaPerUserLimit',
    'queryQuotaPerOrgLimit',
    'querySuggestQuotaPerOrgLimit',
    'querySuggestQuotaPerUserLimit',
    'listFieldValuesQuotaPerUserLimit',
    'listFieldValuesQuotaPerOrgLimit',
];

export const editableContentOrder: string[] = [
    'numberOfSourcesLimit',
    'numberOfMappingPerSourcesLimit',
    'numberOfSourceTypesLimit',
    'numberOfFieldsLimit',
    'licenseExtensionsLimit',
    'numberOfExtensionsPerSourceLimit',
    'numberOfSecurityProvidersLimit',
    'PushApiFileSizeLimit',
    'numberOfDocumentsLimit',
    'pushApiMaximumDailyInvocations',
    'pushApiMaximumDailyDocumentLimit',
    'pushApiMaximumDailyRelationshipLimit',
];

export const contentHistoryLimits: string[] = [
    'numberOfDocumentsLimit',
    'pushApiMaximumDailyInvocations',
    'pushApiMaximumDailyDocumentLimit',
    'pushApiMaximumDailyRelationshipLimit',
];

export const throttlingLimits = [
    'listFieldValuesQuotaPerOrgLimit',
    'listFieldValuesQuotaPerUserLimit',
    'numberOfDailyEmailNotificationsSentPerOrganizationLimit',
    'pushApiMaximumDailyDocumentLimit',
    'pushApiMaximumDailyInvocations',
    'pushApiMaximumDailyRelationshipLimit',
    'queryQuotaPerOrgLimit',
    'queryQuotaPerUserLimit',
    'querySuggestQuotaPerOrgLimit',
    'querySuggestQuotaPerUserLimit',
];

const unaddableSources: string[] = [SourceType.ADOBE_EXPERIENCE_MANAGER, SourceType.CUSTOM, SourceType.TWITTER2];

const getLicense = (state: LicenseState): LicenseModel | undefined => state.license;

const getLicenseProperties = createSelector(getLicense, (license): Record<string, any> => license?.properties);

const getConnectors = createSelector(getLicense, (license): LicenseConnectorModel[] => license?.connectors ?? []);

// TODO [CTCORE-10301]: Should handle missing type behind feature flag directly in the license API.
const getAvailableConnectors = (isSalesforceSitesAvailable: boolean = false) =>
    createSelector(getLicense, (license): LicenseConnectorModel[] => {
        const sourceTypeNotAvailable = isSalesforceSitesAvailable
            ? unaddableSources
            : [...unaddableSources, SourceType.SALESFORCE_SITES];

        return (
            license?.connectors?.filter(
                (connector) =>
                    !sourceTypeNotAvailable.includes(connector.type) &&
                    Object.values(SourceType).includes(connector.type as unknown as SourceType),
            ) ?? []
        );
    });

const getSearchProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, any> => licenseProperties?.searchapi,
);

const getContentProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, any> => licenseProperties?.content,
);

const getOrganizationProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, any> => licenseProperties?.organization,
);

const getUsageAnalyticsProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, any> => licenseProperties?.usageAnalytics,
);

const getMachineLearningProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, any> => licenseProperties?.machineLearning,
);

const getCommerceProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, unknown> => licenseProperties?.commerce,
);

const getInternalProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, any> => licenseProperties?.internal,
);

const getInProductExperienceProperties = createSelector(
    getLicenseProperties,
    (licenseProperties): Record<string, any> => licenseProperties?.inProductExperience,
);

const getLicenseType = createSelector(getLicense, (license): string | undefined => license?.type);

const getProductType = createSelector(getLicense, (license): ProductType | undefined => license?.productType);

const getProductName = createSelector(getLicense, (license): string | undefined => license?.productName);

const getIndexType = createSelector(getLicense, (license): IndexType | undefined => license?.indexType);

const getExpirationDate = createSelector(getLicense, (license): number | undefined => license?.expirationDate);

const getProductEdition = createSelector(getLicense, (license): string | undefined => license?.productEdition);

const getEntitlements = createSelector(getLicense, (license): EntitlementModel[] | undefined => license?.entitlements);

const getModelConfigurability = createSelector(
    getLicenseProperties,
    (licenseProperties): string => licenseProperties?.machineLearning?.modelConfigurability,
);

const isABTestEnabled = createSelector(
    getSearchProperties,
    (searchApiProperties): boolean => searchApiProperties?.abTestEnabled ?? true,
);

const isResourceSnapshotsEnabled = createSelector(
    getOrganizationProperties,
    (organizationProperties): boolean => organizationProperties?.snapshotsEnabled ?? false,
);

const isModelTestingAvailable = createSelector(
    getMachineLearningProperties,
    (machineLearningProperties): boolean => machineLearningProperties?.modelTesterEnabled ?? true,
);

const isContentPropertyLimitReached = (state: LicenseState, tableId: string, property: string, dataCount?: number) => {
    const count = dataCount ?? TableSelectors.getDataCount(state, {id: tableId, data: [], isServer: true});
    return count >= LicenseSelectors.getContentProperties(state)?.[property];
};

const isCommercePropertyLimitReached = (state: LicenseState, tableId: string, property: string) => {
    const count = TableSelectors.getDataCount(state, {id: tableId, data: [], isServer: true});
    return count >= ((LicenseSelectors.getCommerceProperties(state)?.[property] ?? 0) as number);
};

const isOrganizationPropertyLimitReached = (
    state: LicenseState,
    tableId: string,
    property: string,
    dataCount?: number,
) => {
    const count = dataCount ?? TableSelectors.getDataCount(state, {id: tableId, data: [], isServer: true});
    return count >= LicenseSelectors.getOrganizationProperties(state)?.[property];
};

const isUsageAnalyticsPropertyLimitReached = (
    state: LicenseState,
    tableId: string,
    property: string,
    dataCount?: number,
) => {
    const count = dataCount ?? TableSelectors.getDataCount(state, {id: tableId, data: [], isServer: true});
    return count >= LicenseSelectors.getUsageAnalyticsProperties(state)?.[property];
};

const isMachineLearningPropertyLimitReached = (
    state: LicenseState,
    tableId: string,
    property: string,
    dataCount?: number,
) => {
    const count = dataCount ?? TableSelectors.getDataCount(state, {id: tableId, data: [], isServer: true});
    return count >= LicenseSelectors.getMachineLearningProperties(state)?.[property];
};

const isSearchPropertyLimitReached = (state: LicenseState, tableId: string, property: string, dataCount?: number) => {
    const count = dataCount ?? TableSelectors.getDataCount(state, {id: tableId, data: [], isServer: true});
    return count >= LicenseSelectors.getSearchProperties(state)?.[property];
};

const isIntelligentTermDetectionEnabled = (state: LicenseState) =>
    !!LicenseSelectors.getMachineLearningProperties(state)?.[LicenseConstants.ITDEnable];

const isIpxEnabled = (state: LicenseState) =>
    !!LicenseSelectors.getInProductExperienceProperties(state)?.[LicenseConstants.isIpxEnabled];

const isSitecoreLicense = createSelector(getLicenseType, (licenseType) =>
    licenseType ? LicenseConstants.SITECORE_LICENSES.includes(licenseType) : false,
);

const isTrialLicense = createSelector(getLicense, (license) => (license ? LicenseHelper.isTrial(license) : false));

const isProductionOrganization = createSelector(getProductType, (productType) => productType === ProductType.STANDARD);

const isSearchGroupsEnabled = createSelector(
    getSearchProperties,
    (properties: Record<string, any>): boolean => properties?.groupsEnabled ?? true,
);

const isTrialExpired = createSelector(getLicense, (license) =>
    license ? LicenseHelper.isTrialExpired(license) : false,
);

const hasExpired = (state = LicenseStore.getState()): boolean => isTrialExpired(state);
const isTrialOrg = (state = LicenseStore.getState()): boolean => isTrialLicense(state);

export const LicenseSelectors = {
    isTrialExpired,
    hasExpired,
    getLicense,
    getConnectors,
    getAvailableConnectors,
    getSearchProperties,
    getContentProperties,
    getCommerceProperties,
    getInternalProperties,
    getInProductExperienceProperties,
    getOrganizationProperties,
    getUsageAnalyticsProperties,
    getMachineLearningProperties,
    getLicenseType,
    getProductType,
    getProductName,
    getIndexType,
    getExpirationDate,
    getProductEdition,
    getEntitlements,
    getModelConfigurability,
    isABTestEnabled,
    isProductionOrganization,
    isResourceSnapshotsEnabled,
    isSitecoreLicense,
    isTrialOrg,
    isTrialLicense,
    isModelTestingAvailable,
    isContentPropertyLimitReached,
    isCommercePropertyLimitReached,
    isOrganizationPropertyLimitReached,
    isUsageAnalyticsPropertyLimitReached,
    isMachineLearningPropertyLimitReached,
    isSearchPropertyLimitReached,
    isIntelligentTermDetectionEnabled,
    isIpxEnabled,
    isSearchGroupsEnabled,
};
