import {
    Combobox,
    ComboboxFactory,
    ComboboxProps,
    factory,
    Input,
    InputBase,
    Text,
    useCombobox,
    useProps,
    useUncontrolled,
} from '@coveord/plasma-mantine';
import {FunctionComponent, ReactNode} from 'react';
import {InfiniteScroll} from '../InfiniteScroll';
import {groupOptions} from '../../../utilities';

export interface InfiniteScrollSelectItem extends Record<string, unknown> {
    value: string;
    label: string;
    group?: string;
}

export interface InfiniteScrollSelectItemComponentProps {
    data: InfiniteScrollSelectItem;
    selected: boolean;
}

export type InfiniteScrollSelectItemComponent = FunctionComponent<InfiniteScrollSelectItemComponentProps>;

const DefaultInfiniteScrollSelectItem: InfiniteScrollSelectItemComponent = ({selected, data}) => (
    <Text fw={selected ? 500 : 400}>{data.label}</Text>
);

export interface InfiniteScrollSelectProps extends ComboboxProps {
    /**
     * The data to render in the component
     */
    data: InfiniteScrollSelectItem[];
    /**
     * Function called when an item is selected or unselected
     *
     * @param value the selected item
     */
    onChange?: (value: string) => void;
    /**
     * * Initially selected item
     */
    defaultValue?: string;
    /**
     * Determines item selection
     */
    value?: string;
    /**
     * Custom item component
     *
     * @default a checkbox with the label of the item
     */
    itemComponent?: InfiniteScrollSelectItemComponent;
    /**
     * Search input placeholder
     */
    searchPlaceholder?: string;
    /**
     * Called when the search query changes
     *
     * @param value the search query
     */
    onSearch?: (value: string) => void;
    /**
     * Function to filter search results
     *
     * @param query value of the search input
     * @param item the current item
     *
     * @default function that compare the query with the label and value, case-insensitive
     */
    filter?: (query: string, item: InfiniteScrollSelectItem) => boolean;
    /**
     * Value of the search input
     */
    query?: string;
    /**
     * Nothing found message
     *
     * @default No matching items
     */
    nothingFoundMessage?: ReactNode;
    /**
     * Displayed when a list is empty and there is no search query
     *
     * @default No items
     */
    placeholder?: ReactNode;
    /**
     * Label of the select
     */
    label: string;
    /**
     * Description of the select
     */
    description?: string;
    /**
     * Maximum height, only used when there is more than 7 values
     *
     * @default 200
     */
    height?: number | 'auto';
    /**
     * Predefined border-radius value from theme.radius or number for border-radius in px
     *
     * @default md
     */
    radius?: number | string;
    /**
     * Change list component, can be used to add custom scrollbars
     */
    listComponent?: FunctionComponent<any>;
    /**
     * Limit amount of items showed at a time
     *
     * @default Infinity
     */
    limit?: number;
    /**
     * Control the displaying of the search input.
     *
     * @default data.length > 7
     */
    searchable?: boolean;
    __staticSelector?: string;
}

export type InfiniteScrollSelectFactory = ComboboxFactory & {
    props: InfiniteScrollSelectProps;
};

const defaultProps: Partial<InfiniteScrollSelectProps> = {
    searchPlaceholder: 'Search',
    nothingFoundMessage: 'No matching items',
    placeholder: 'No items',
    height: 200,
    limit: Infinity,
    itemComponent: DefaultInfiniteScrollSelectItem,
};

export const InfiniteScrollSelect = factory<InfiniteScrollSelectFactory>((_props, ref) => {
    const props = useProps('InfiniteScrollSelect', defaultProps, _props);
    const {
        data,
        onChange,
        defaultValue = null,
        value,
        itemComponent: ItemComponent,
        searchPlaceholder,
        query,
        searchable = data.length > 7,
        onSearch,
        filter = defaultFilter,
        nothingFoundMessage,
        placeholder,
        height,
        radius,
        __staticSelector,
        classNames,
        styles,
        limit,
        vars,
        unstyled,
        description,
        label,
        ...others
    } = props;
    const combobox = useCombobox({
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            combobox.focusTarget();
            handleSearch('');
        },

        onDropdownOpen: () => {
            combobox.focusSearchInput();
        },
    });
    const [search, handleSearch] = useUncontrolled({
        value: query,
        defaultValue: '',
        finalValue: '',
        onChange: onSearch,
    });

    const [_selection, handleSelection] = useUncontrolled({
        value,
        defaultValue,
        finalValue: null,
        onChange,
    });

    const filteredData = data.filter((item) => filter(search, item)).slice(0, limit);
    const sortedData = groupOptions({data: filteredData}).map((item) => (
        <Combobox.Option
            active={_selection === item.value}
            aria-selected={_selection === item.value}
            value={item.value}
            key={item.value}
            onMouseEnter={() => combobox.resetSelectedOption()}
        >
            <ItemComponent data={item} selected={_selection === item.value} />
        </Combobox.Option>
    ));

    const handleValueSelect = (val: string) => {
        handleSelection(val);
        combobox.closeDropdown();
    };

    return (
        <Combobox store={combobox} onOptionSubmit={handleValueSelect} {...others}>
            <Combobox.Target>
                <InputBase
                    component="button"
                    label={label}
                    description={description}
                    type="button"
                    pointer
                    rightSection={<Combobox.Chevron />}
                    onClick={() => combobox.toggleDropdown()}
                    rightSectionPointerEvents="none"
                >
                    {_selection || <Input.Placeholder>{placeholder}</Input.Placeholder>}
                </InputBase>
            </Combobox.Target>
            <Combobox.Dropdown>
                <Combobox.Search
                    value={search}
                    onChange={(event) => handleSearch(event.currentTarget.value)}
                    placeholder={searchPlaceholder}
                />
                <Combobox.Options>
                    <InfiniteScroll
                        style={{
                            height: searchable ? height : 'auto',
                            overflow: 'auto',
                            position: 'relative',
                        }}
                    >
                        {sortedData.length > 0 ? (
                            sortedData
                        ) : (
                            <Combobox.Empty>
                                <Text c="dimmed" unstyled={unstyled} size="sm" ta="center" my="sm">
                                    {!query && placeholder ? placeholder : nothingFoundMessage}
                                </Text>
                            </Combobox.Empty>
                        )}
                    </InfiniteScroll>
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
});

InfiniteScrollSelect.displayName = 'InfiniteScrollSelect';

const defaultFilter = (query: string, item: InfiniteScrollSelectItem) =>
    item.label.toLowerCase().trim().includes(query.toLowerCase().trim()) ||
    item.value.toLowerCase().trim().includes(query.toLowerCase().trim());
