import {Platform} from '@core/api';
import {Config} from '@core/configuration';
import dayjs from 'dayjs';
const parseResponse = (response: XMLDocument) => {
    const urls: string[] = [];
    const locElements = response.querySelectorAll('url loc');

    locElements.forEach((loc) => {
        urls.push(loc.textContent.toLowerCase());
    });

    return urls;
};

const WhatsNewUrlRegex = /ccv2ac\/whats_new(?:_.+)?_(\d{4})-?(\d{2})-?(\d{2})\/$/;

const getLatestUrl = (urls: string[]) =>
    urls
        .filter((url: string) => WhatsNewUrlRegex.test(url))
        .filter((url: string) => {
            const dateParts = url.match(/(\d{4})-?(\d{2})-?(\d{2})/);
            return dayjs(dateParts[0], 'YYYYMMDD') <= dayjs();
        })
        .sort((a: string, b: string) => {
            const dateA = parseInt(WhatsNewUrlRegex.exec(a).splice(1).join(''), 10);
            const dateB = parseInt(WhatsNewUrlRegex.exec(b).splice(1).join(''), 10);
            return dateA - dateB;
        })
        .at(-1) ?? '';

const getLatestWhatsNew = async () => {
    try {
        const res = await fetch(`${Config.OnlineHelp.url}en/sitemap.xml`);
        const text = await res.text();
        const xml = new window.DOMParser().parseFromString(text, 'text/xml');
        const urls = parseResponse(xml);
        return getLatestUrl(urls);
    } catch (e) {
        console.error('Error fetching latest whats new.');
    }
};

const markAsRead = async (additionalInformation: Record<string, string>, latestWhatsNew: string) => {
    try {
        await Platform.user.update({
            additionalInformation: {
                ...additionalInformation,
                LastWhatsNew: latestWhatsNew,
            },
        });
    } catch (e) {
        console.error('Error marking whats new as read.');
    }
};

export const WhatsNewActions = {
    getLatestWhatsNew,
    markAsRead,
};
