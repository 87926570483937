import {API, Resource} from '@coveo/platform-client';
import {PreviewResultModel, SearchPreviewRequestModel, SynonymsPreviewRequestModel} from '../../interfaces';

export class SearchPreview extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/search`;

    preview(options: SearchPreviewRequestModel) {
        return this.api.post<PreviewResultModel>(`${SearchPreview.baseUrl}/preview`, options);
    }

    previewCreateSynonyms(options: SynonymsPreviewRequestModel, trackingId: string) {
        return this.api.post<PreviewResultModel>(
            this.buildPath(`${SearchPreview.baseUrl}/preview/synonyms`, {trackingId}),
            options,
        );
    }

    previewUpdateSynonyms(options: SynonymsPreviewRequestModel, trackingId: string, synonymRuleId: string) {
        return this.api.put<PreviewResultModel>(
            this.buildPath(`${SearchPreview.baseUrl}/preview/synonyms/${synonymRuleId}`, {trackingId}),
            options,
        );
    }

    previewGetSynonyms(options: SynonymsPreviewRequestModel, synonymRuleId: string) {
        return this.api.get<PreviewResultModel>(
            this.buildPath(`${SearchPreview.baseUrl}/preview/synonyms/${synonymRuleId}`, options),
        );
    }
}
