import {UseInfiniteQueryResult} from '@core/api';
import {ReactNode, useEffect, useState} from 'react';
import {InfiniteScroll, InfiniteScrollProps} from '../infinite-scroll';

export interface InfiniteQueryProps<T> extends InfiniteScrollProps {
    /**
     * `react-query` instance.
     *
     * Used internally to provide automatic loader and infinite scroll experience.
     *
     * Has to be an [Infinite Query](https://tanstack.com/query/v4/docs/react/guides/infinite-queries).
     */
    query: UseInfiniteQueryResult<T>;

    /**
     * Determines the current count of items visible in the dropdown from the data object. This is not the total number of entries.
     */
    count: () => number;

    /**
     * The children of the component.
     */
    children: ReactNode;
}

export const InfiniteQueryScrollArea = <T,>({
    query,
    count,
    children,
    ...infiniteScrollProps
}: InfiniteQueryProps<T>) => {
    const [length, setLength] = useState(0);

    useEffect(() => {
        setLength(count());
    }, [query?.data]);

    return (
        <InfiniteScroll.Provider hasMore={query.hasNextPage} next={query.fetchNextPage} dataLength={length}>
            <InfiniteScroll {...infiniteScrollProps}>{children}</InfiniteScroll>
        </InfiniteScroll.Provider>
    );
};
