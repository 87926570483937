import {API, Resource, SlackTokenValidationResult} from '@coveo/platform-client';

interface ValidateSlackTokenParams {
    accessToken?: string;
    accessTokenGuid?: string;
}

/**
 * Temporary resource as a workaround until the platform-client corresponding resource is updated
 * to accept access token GUID. Modifying the platform-client first would introduce a breaking change.
 */
export class SchemaServiceTemporaryResource extends Resource {
    static getBaseUrl = () => `/rest/organizations/${API.orgPlaceholder}/schema/sources`;

    /**
     * @param params Either the Slack access token to validate or it's corresponding GUID.
     * @returns The token validation result from Slack.
     */
    validateSlackToken(params: ValidateSlackTokenParams) {
        let auth = '';
        Object.keys(params).forEach((param) => (auth += `${param}=${params[param]}&`));

        return this.api.post<SlackTokenValidationResult>(
            `${SchemaServiceTemporaryResource.getBaseUrl()}/SLACK/validateToken?${auth.slice(0, -1)}`,
        );
    }
}
