import {AccessLevel, GroupAccessModel} from '@core/api';
import {AccessTableUtils} from '../AccessTableUtils';

const isPartOfGroup = (groupAccessModels: GroupAccessModel[], groupId: string): boolean =>
    groupAccessModels.find((accessModel) => accessModel.id === groupId && accessModel.callerPartOf) !== undefined;

const isPartOfGroupWithFullEditAccess = (groupAccessModels: GroupAccessModel[]): boolean => {
    const editGroupsPartOf: GroupAccessModel[] = groupAccessModels.filter(
        (accessModel) => accessModel.accessLevel === AccessLevel.EDIT_ALL && accessModel.callerPartOf,
    );

    return editGroupsPartOf.length > 0;
};

const isPartOfGroupThatCanEdit = (groupAccessModels: GroupAccessModel[], resourceId: string): boolean => {
    const groupThatCanEdit = groupAccessModels.find(
        (accessModel) => accessModel.callerPartOf && AccessTableUtils.hasEditAccessOnResource(accessModel, resourceId),
    );

    return groupThatCanEdit !== undefined;
};

const preventGroupLockout = (groupAccessModels: GroupAccessModel[], resourceId: string): GroupAccessModel[] => {
    const isNewResource = AccessTableUtils.isNewResource(resourceId);
    const partOfGroupWithFullEditAccess = isPartOfGroupWithFullEditAccess(groupAccessModels);
    return groupAccessModels.map((groupAccessModel) => {
        if (
            isNewResource &&
            AccessTableUtils.isModifiableAccess(groupAccessModel) &&
            groupAccessModel.callerPartOf &&
            !partOfGroupWithFullEditAccess
        ) {
            // When creating a resource, we grant edit access to the group the user is part of by default if he cannot edit all groups, otherwise he might lose access to the resource after creation
            return AccessTableUtils.setEditAccessOnResource(groupAccessModel, resourceId);
        }
        return groupAccessModel;
    });
};

export const GroupsAccessTableUtils = {
    isPartOfGroup,
    isPartOfGroupThatCanEdit,
    preventGroupLockout,
};
