import {API, Resource} from '@coveo/platform-client';
import {
    AnswerPage,
    AnswerPagingParams,
    AnswerRule,
    CreateAnswerRuleOptions,
} from '../../../interfaces/knowledge/AnswerInterfaces';

export class AnswerRuleResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/answer/v1/configs`;

    list(configId: string, params?: AnswerPagingParams): Promise<AnswerPage<AnswerRule>> {
        return this.api.get<AnswerPage<AnswerRule>>(
            this.buildPath(`${AnswerRuleResource.baseUrl}/${configId}/rules`, params),
        );
    }

    get(configId: string, ruleId: string): Promise<AnswerRule> {
        return this.api.get<AnswerRule>(this.buildPath(`${AnswerRuleResource.baseUrl}/${configId}/rules/${ruleId}`));
    }

    delete(configId: string, ruleId: string) {
        return this.api.delete(this.buildPath(`${AnswerRuleResource.baseUrl}/${configId}/rules/${ruleId}`));
    }

    edit(configId: string, ruleId: string, params: CreateAnswerRuleOptions): Promise<AnswerRule> {
        return this.api.put<AnswerRule>(`${AnswerRuleResource.baseUrl}/${configId}/rules/${ruleId}`, params);
    }

    create(configId: string, createParams: CreateAnswerRuleOptions): Promise<AnswerRule> {
        return this.api.post<AnswerRule>(`${AnswerRuleResource.baseUrl}/${configId}/rules`, createParams);
    }
}
