import {
    Format,
    FormatFunction,
    FormatOrFunction,
    FormatRange,
    FormatToParts,
    FormatValueType,
    FormatWithResolvedOptions,
    MissingFormatOptions,
    MissingFormatPart,
    MissingValue,
    ResolvedMissingFormatOptions,
    SelectFormatterA,
} from '../api';
import {DateTimeMissingFormat} from './DateTimeMissingFormat';
import {NumberMissingFormat} from './NumberMissingFormat';
import {
    ABFormat,
    ABFormatToParts,
    ABFormatWithOptions,
    BoundFormat,
    createNumberFormat,
    MissingFormat,
    MissingFormatRange,
    MissingFormatToParts,
    MissingFormatWithOptions,
    toFormat,
} from './internal';

const isFormatWithResolvedOptions = <TValue>(
    value: FormatOrFunction<TValue>,
): value is FormatWithResolvedOptions<TValue, object> => typeof value !== 'function' && 'resolvedOptions' in value;

const isFormatToParts = <TValue>(value: FormatOrFunction<TValue>): value is FormatToParts<TValue, object, unknown> =>
    isFormatWithResolvedOptions(value) && 'formatToParts' in value;

const isFormatRange = <TValue>(
    value: FormatOrFunction<TValue>,
): value is FormatRange<TValue, object, unknown, unknown> => isFormatToParts(value) && 'formatRange' in value;

const isNumeric = (value: unknown): value is bigint | number => {
    switch (typeof value) {
        case 'bigint':
        case 'number':
            return true;
        default:
            return false;
    }
};

/** Resolve the combined type when FormatA is a plain format (function). */
type CombineFormatOrFunction<TValueA, TValueB, TFormatB extends FormatOrFunction<TValueB>> =
    TFormatB extends FormatWithResolvedOptions<TValueB, infer TOptionsB>
        ? FormatWithResolvedOptions<TValueA | TValueB, TOptionsB>
        : Format<TValueA | TValueB>;

/** Resolve the combined type when FormatA is a format with `resolvedOptions()`. */
type CombineFormatWithResolvedOptions<TValueA, TOptionsA, TValueB, TFormatB extends FormatOrFunction<TValueB>> =
    TFormatB extends FormatWithResolvedOptions<TValueB, infer TOptionsB>
        ? FormatWithResolvedOptions<TValueA | TValueB, TOptionsA & TOptionsB>
        : FormatWithResolvedOptions<TValueA | TValueB, TOptionsA>;

/**
 * Resolve the combined type when FormatA is a format with `formatToParts(value)`.
 * If FormatB does not support formatToParts, defer to CombineFormatWithResolvedOptions.
 */
type CombineFormatToParts<TValueA, TOptionsA, TPartA, TValueB, TFormatB extends FormatOrFunction<TValueB>> =
    TFormatB extends FormatToParts<TValueB, infer TOptionsB, infer TPartB>
        ? FormatToParts<TValueA | TValueB, TOptionsA & TOptionsB, TPartA | TPartB>
        : CombineFormatWithResolvedOptions<TValueA, TOptionsA, TValueB, TFormatB>;

const combine = ((
    selectA: SelectFormatterA<unknown>,
    a: FormatOrFunction<unknown>,
    b: FormatOrFunction<unknown>,
): Format<unknown> => {
    if ((a as unknown) === (b as unknown)) {
        return toFormat(a);
    }
    if (isFormatToParts(a) && isFormatToParts(b)) {
        return new ABFormatToParts(selectA, a, b);
    } else if (isFormatWithResolvedOptions(a) || isFormatWithResolvedOptions(b)) {
        // Note: the || (instead of &&) above is not a mistake, support proxying just one formatter's options.
        return new ABFormatWithOptions(selectA, a, b);
    }
    return new ABFormat(selectA, a, b);
}) as <
    TFormatA extends FormatOrFunction<TValueA>,
    TFormatB extends FormatOrFunction<TValueB>,
    TValueA = FormatValueType<TFormatA>,
    TValueB = FormatValueType<TFormatB>,
    TSelect extends TValueA | TValueB = TValueA | TValueB,
>(
    selectA: SelectFormatterA<TSelect>,
    a: TFormatA,
    b: TFormatB,
) => TFormatA extends FormatToParts<TValueA, infer TOptionsA, infer TPartA>
    ? CombineFormatToParts<TValueA, TOptionsA, TPartA, TValueB, TFormatB>
    : TFormatA extends FormatWithResolvedOptions<TValueA, infer TOptionsA>
      ? CombineFormatWithResolvedOptions<TValueA, TOptionsA, TValueB, TFormatB>
      : CombineFormatOrFunction<TValueA, TValueB, TFormatB>;

const selectByThreshold = (threshold: number | readonly [number, number]) => {
    if (threshold === 0) {
        return (value: unknown) => !isNumeric(value) || value >= 0;
    }
    if (typeof threshold === 'number') {
        threshold = +threshold;
        threshold = [-threshold, threshold];
    } else if (threshold[0] >= threshold[1]) {
        throw new RangeError(`Lower bound ${threshold[0]} must be below upper bound ${threshold[1]}.`);
    }
    return (value: unknown) => !isNumeric(value) || (value > threshold[0] && value < threshold[1]);
};

const hybridNumberFormat = (
    locales: Intl.LocalesArgument,
    options: Intl.NumberFormatOptions & MissingFormatOptions,
    threshold: number | readonly [number, number],
    secondaryOptions: Intl.NumberFormatOptions,
): FormatToParts<
    FormatValueType<Intl.NumberFormat> | MissingValue,
    Intl.ResolvedNumberFormatOptions & ResolvedMissingFormatOptions,
    Intl.NumberFormatPart | MissingFormatPart
> => {
    const primary = new NumberMissingFormat(locales, options);
    const combinedOptions = {...options, ...secondaryOptions};
    // If the secondary options end up specifying a lower maximumFractionDigits,
    // automatically lower the secondary minimumFractionDigits.
    if (
        typeof secondaryOptions.maximumFractionDigits === 'number' &&
        secondaryOptions.minimumFractionDigits === undefined
    ) {
        const min = options.minimumFractionDigits ?? primary.resolvedOptions().minimumFractionDigits ?? 0;
        if (min > secondaryOptions.maximumFractionDigits) {
            combinedOptions.minimumFractionDigits = secondaryOptions.maximumFractionDigits;
        }
    }
    const secondary = createNumberFormat(locales, combinedOptions);

    return combine(selectByThreshold(threshold), primary, secondary);
};

const missing = ((formatter: FormatOrFunction<unknown>, options?: MissingFormatOptions): Format<unknown> => {
    if (formatter instanceof Intl.DateTimeFormat) {
        return new DateTimeMissingFormat(formatter, options);
    } else if (formatter instanceof Intl.NumberFormat) {
        return new NumberMissingFormat(formatter, options);
    } else if (isFormatRange(formatter)) {
        return new MissingFormatRange(formatter, options);
    } else if (isFormatToParts(formatter)) {
        return new MissingFormatToParts(formatter, options);
    } else if (isFormatWithResolvedOptions(formatter)) {
        return new MissingFormatWithOptions(formatter, options);
    }
    return new MissingFormat(formatter, options);
}) as <TFormat extends FormatOrFunction<TValue>, TValue = FormatValueType<TFormat>>(
    formatter: TFormat,
    options?: MissingFormatOptions,
) => TFormat extends Intl.DateTimeFormat
    ? DateTimeMissingFormat
    : TFormat extends Intl.NumberFormat
      ? NumberMissingFormat
      : TFormat extends FormatRange<TValue, infer TOptions, infer TPart, infer TRangePart>
        ? FormatRange<
              TValue | MissingValue,
              TOptions & ResolvedMissingFormatOptions,
              TPart | MissingFormatPart,
              TRangePart
          >
        : TFormat extends FormatToParts<TValue, infer TOptions, infer TPart>
          ? FormatToParts<TValue | MissingValue, TOptions & ResolvedMissingFormatOptions, TPart | MissingFormatPart>
          : TFormat extends FormatWithResolvedOptions<TValue, infer TOptions>
            ? FormatWithResolvedOptions<TValue | MissingValue, TOptions & ResolvedMissingFormatOptions>
            : FormatWithResolvedOptions<TValue | MissingValue, ResolvedMissingFormatOptions>;

const toFunction = <TValue>(format: FormatOrFunction<TValue>): FormatFunction<TValue> => {
    if (typeof format === 'function') {
        return format;
    } else if (
        format instanceof BoundFormat ||
        format instanceof MissingFormat ||
        format instanceof Intl.DateTimeFormat ||
        format instanceof Intl.NumberFormat
    ) {
        // The format function of these formatters is implicitly bound
        return format.format;
    }
    return format.format.bind(format);
};

export const Formatting = {
    /**
     * Create a combined formatter from two formatters, with a condition function that selects which formatter to use.
     * You can read the arguments of this as a ternary operation: `selectA(value) ? a.format(value) : b.format(value)`.
     * Note that `resolvedOptions()`, if available on both sources, will return a simple merge of both options.
     * This means that if both formatters have a resolved option with the same name, the value of formatter `b` will be returned.
     *
     * @param selectA: Function that determines whether to use formatter A (`true`) or B (`false`).
     * If formatters `a` and `b` support different types, it is highly recommended to use a type assertion function.
     * @param a: The first formatter to combine.
     * @param b: The second formatter to combine.
     * @returns A formatter that is the combination of the two formatters, forwarding as many features as possible.
     */
    combine,

    /**
     * Create a hybrid (missing) number format that allows overriding options for values starting from a
     * specific value defined by the threshold. This allows omitting fractions for larger values for example.
     * This is basically a shortcut for combine, creating a primary `NumberMissingFormat` for values within
     * the threshold bounds, and a secondary `Intl.NumberFormat` to handle "larger" values.
     *
     * @param locales The locales to use for the number formatters.
     * @param options The options to use for both number formatters.
     * @param threshold The (inclusive) threshold from which to use the secondary formatter.
     *
     * Any single number threshold is expanded to be `[-threshold, threshold]`,
     * for example `1_000_000` becomes `[-1_000_000, 1_000_000]`.
     * An exception is `threshold === 0`, which means the primary format is used for positive,
     * and the secondary is used for negative numbers.
     * In all other cases, the primary formatter is used if the value is in within the range
     * `value > threshold[0] && value < threshold[1]`, and the secondary for all other values.
     * @param secondaryOptions The overrides to use for numbers exceeding the threshold (both positive and negative).
     * Defaults to forcing the maximum fraction digits to 0.
     * @returns A combined number formatter that uses the "large" option overrides for values passing the threshold.
     */
    hybridNumberFormat,

    /**
     * Create a formatter with support for "missing" (`null` or `undefined`) values from an existing formatter.
     * In case you call this method with a `new` {@link Intl.DateTimeFormat} or {@link Intl.NumberFormat},
     * you can instead also use the {@link DateTimeMissingFormat} and {@link NumberMissingFormat} types directly.
     *
     * @param formatter The existing formatter to wrap.
     * @param options The optional {@link MissingFormatOptions}, that allow specifying which string to use for missing values.
     * @returns A formatter that is the combination of the two formatters, forwarding as many features as possible.
     */
    missing,

    /**
     * Normalize a {@link FormatFunction} or {@link Format} to be a `Format` instance.
     *
     * @param format The value to normalize.
     * @returns The normalized value.
     * @typeParam TValue The supported value types for formatting.
     */
    toFormat,

    /**
     * Normalize a formatter or format function to a function that is always safe to call without context (e.g. with no `this`).
     *
     * @param formatter The formatter to get the (bound) format function of, or a format function.
     * @returns The (possibly bound) format function.
     * @typeParam TValue The supported value types for formatting.
     */
    toFunction,
};
