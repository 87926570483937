import {Divider, Group, ScrollArea, Spotlight, Text} from '@components/mantine';
import {BrokenSearchSize24Px, SearchSize16Px} from '@coveord/plasma-react-icons';
import {FunctionComponent, ReactNode, useEffect, useState} from 'react';
import {Locales} from '../../strings/Locales';
import {BadgeVariant} from '../NavigationBadge';
import {NavigationSearchItem, NavigationSearchResult} from './NavigationSearchItem';
import classes from './NavigationSearchProvider.module.css';

type LinkBySection = Record<string, NavigationSearchResult[]>;

const extractSearchResultsFromNavLinks = (navLinks: NodeListOf<HTMLAnchorElement>): LinkBySection => {
    const actions: LinkBySection = {};
    navLinks.forEach((navLink) => {
        const section = navLink.parentElement.dataset.navsection ?? Locales.format('Section.main');
        const badge = navLink.querySelector<HTMLSpanElement>('.mantine-NavLink-section [data-navbadge]')?.dataset
            ?.navbadge as BadgeVariant;
        if (!actions[section]) {
            actions[section] = [];
        }
        actions[section].push({
            id: navLink.href,
            title: navLink.querySelector<HTMLSpanElement>('.mantine-NavLink-body').innerText,
            href: navLink.href,
            group: section,
            badge,
            keywords: [section, badge].filter(Boolean),
        });
    });
    return actions;
};
export const NavigationSearchProvider: FunctionComponent<{children: ReactNode}> = ({children}) => {
    const [searchResults, setSearchResults] = useState<LinkBySection>({});
    const [query, setQuery] = useState('');
    const onActionTriggered = (action: NavigationSearchResult) => {
        window.location.assign(action.href);
    };

    useEffect(() => {
        const navLinks = document.querySelectorAll<HTMLAnchorElement>('a[data-navlink]');
        setSearchResults(extractSearchResultsFromNavLinks(navLinks));
    }, []);

    const items = Object.entries(searchResults)
        .map(([section, navSearchResults]) => {
            const filteredItems = navSearchResults.filter(
                (item) =>
                    item.keywords.some((keyword) => keyword.toLowerCase().includes(query.toLowerCase().trim())) ||
                    item.title.toLowerCase().includes(query.toLowerCase().trim()),
            );
            if (filteredItems.length) {
                return (
                    <Spotlight.ActionsGroup key={section} className={classes.actionsGroup}>
                        <Group px="xs">
                            <Text className={classes.groupLabel}>{section || Locales.format('Section.main')}</Text>
                            <Divider className={classes.groupDivider} />
                        </Group>
                        {filteredItems.map((item) => (
                            <Spotlight.Action
                                className={classes.action}
                                key={item.title}
                                label={item.title}
                                description={item.description}
                                tabIndex={-1}
                                onClick={() => onActionTriggered(item)}
                            >
                                <NavigationSearchItem action={item} />
                            </Spotlight.Action>
                        ))}
                    </Spotlight.ActionsGroup>
                );
            }
            return null;
        })
        .filter(Boolean);

    return (
        <>
            <Spotlight.Root shortcut={['mod + K']} query={query} onQueryChange={setQuery}>
                <Spotlight.Search
                    placeholder={Locales.format('Search.label')}
                    leftSection={<SearchSize16Px height={16} />}
                />
                <Spotlight.ActionsList p="xs">
                    <ScrollArea.Autosize mah={360}>
                        {items.length > 0 ? (
                            items
                        ) : (
                            <Spotlight.Empty>
                                <Group justify="center" gap="xs">
                                    <BrokenSearchSize24Px height={24} />
                                    {Locales.format('Search.nothingFound')}
                                </Group>
                            </Spotlight.Empty>
                        )}
                    </ScrollArea.Autosize>
                </Spotlight.ActionsList>
            </Spotlight.Root>
            {children}
        </>
    );
};
