import HTML from 'html-parse-stringify';
import {cloneElement, createElement, isValidElement, ReactElement} from 'react';

const keepArray = ['br', 'strong', 'i', 'p'];

/**
 * The content of that file is based on https://github.com/i18next/react-i18next/blob/master/src/TransWithoutContext.js
 * It was adapted to remove options and make it compatible with typescript/eslint and our Locales.
 */

const hasChildren = (node, checkLength = false) => {
    if (!node) {
        return false;
    }
    const base = node.props ? node.props.children : node.children;
    if (checkLength) {
        return base.length > 0;
    }
    return !!base;
};

const getChildren = (node) => {
    if (!node) {
        return [];
    }
    return node.props ? node.props.children : node.children;
};

const hasValidReactChildren = (children) => {
    if (Object.prototype.toString.call(children) !== '[object Array]') {
        return false;
    }
    return children.every((child) => isValidElement(child));
};

const getAsArray = (data) => (Array.isArray(data) ? data : [data]);

const mergeProps = (source, target) => {
    const newTarget = {...target};
    // overwrite source.props when target.props already set
    newTarget.props = Object.assign(source.props, target.props);
    return newTarget;
};

export const nodesToString = (children) => {
    if (!children) {
        return '';
    }
    let stringNode = '';

    // do not use `React.Children.toArray`, will fail at object children
    const childrenArray = getAsArray(children);

    // e.g. lorem <br/> ipsum dolor <strong>bold</strong> amet
    childrenArray.forEach((child: string | ReactElement<any, string>, childIndex) => {
        if (typeof child === 'string') {
            // actual e.g. lorem
            // expected e.g. lorem
            stringNode += `${child}`;
        } else if (isValidElement(child)) {
            const childPropsCount = Object.keys(child.props).length;
            const shouldKeepChild = keepArray.indexOf(child.type) > -1;
            const childChildren = child.props.children;

            if (!childChildren && shouldKeepChild && childPropsCount === 0) {
                // actual e.g. lorem <br/> ipsum
                // expected e.g. lorem <br/> ipsum
                stringNode += `<${child.type}/>`;
            } else if (!childChildren && (!shouldKeepChild || childPropsCount !== 0)) {
                // actual e.g. lorem <hr className="test" /> ipsum
                // expected e.g. lorem <0></0> ipsum
                stringNode += `<${childIndex}></${childIndex}>`;
            } else if (child.props.i18nIsDynamicList) {
                // we got a dynamic list like
                // e.g. <ul i18nIsDynamicList>{['a', 'b'].map(item => ( <li key={item}>{item}</li> ))}</ul>
                // expected e.g. "<0></0>", not e.g. "<0><0>a</0><1>b</1></0>"
                stringNode += `<${childIndex}></${childIndex}>`;
            } else if (shouldKeepChild && childPropsCount === 1 && typeof childChildren === 'string') {
                // actual e.g. dolor <strong>bold</strong> amet
                // expected e.g. dolor <strong>bold</strong> amet
                stringNode += `<${child.type}>${childChildren}</${child.type}>`;
            } else {
                // regular case mapping the inner children
                const content = nodesToString(childChildren);
                stringNode += `<${childIndex}>${content}</${childIndex}>`;
            }
        } else if (child === null) {
            console.warn(`Trans: the passed in value is invalid - seems you passed in a null child.`);
        } else {
            console.warn(`Translation: the passed in value is invalid.`, child);
        }
    });

    return stringNode;
};

export const renderNodes = (children, targetString) => {
    if (targetString === '') {
        return [];
    }

    const data = {};

    const getData = (childs) => {
        const childrenArray = getAsArray(childs);

        childrenArray.forEach((child) => {
            if (typeof child === 'string') {
                return;
            }
            if (hasChildren(child)) {
                getData(getChildren(child));
            } else if (typeof child === 'object' && !isValidElement(child)) {
                Object.assign(data, child);
            }
        });
    };

    getData(children);

    // parse ast from string with additional wrapper tag
    // -> avoids issues in parser removing prepending text nodes
    const ast = HTML.parse(`<0>${targetString}</0>`);

    const renderInner = (child, node, rootReactNode) => {
        const childs = getChildren(child);
        const mappedChildren = mapAST(childs, node.children, rootReactNode);
        return hasValidReactChildren(childs) && mappedChildren.length === 0 ? childs : mappedChildren;
    };

    const pushTranslatedJSX = (child, inner, mem, i, isVoid = false) => {
        if (child.dummy) {
            child.children = inner;
        } // needed on preact!
        mem.push(cloneElement(child, {...child.props, key: i}, isVoid ? undefined : inner));
    };

    // reactNode (the jsx root element or child)
    // astNode (the translation string as html ast)
    // rootReactNode (the most outer jsx children array or trans components prop)
    const mapAST = (reactNode, astNode, rootReactNode) => {
        const reactNodes = getAsArray(reactNode);
        const astNodes = getAsArray(astNode);

        return astNodes.reduce((mem, node, i) => {
            const translationContent = node.children && node.children[0] && node.children[0].content;

            if (node.type === 'tag') {
                let tmp = reactNodes[parseInt(node.name, 10)]; // regular array (components or children)
                if (!tmp && rootReactNode.length === 1 && rootReactNode[0][node.name]) {
                    tmp = rootReactNode[0][node.name];
                }
                if (!tmp) {
                    tmp = {};
                }
                const child = Object.keys(node.attrs).length !== 0 ? mergeProps({props: node.attrs}, tmp) : tmp;

                const isElement = isValidElement(child);

                const isValidTranslationWithChildren = isElement && hasChildren(node, true) && !node.voidElement;

                const isKnownComponent =
                    typeof children === 'object' &&
                    children !== null &&
                    Object.hasOwnProperty.call(children, node.name);

                if (typeof child === 'string') {
                    mem.push(child);
                } else if (
                    hasChildren(child) || // the jsx element has children -> loop
                    isValidTranslationWithChildren // valid jsx element with no children but the translation has -> loop
                ) {
                    const inner = renderInner(child, node, rootReactNode);
                    pushTranslatedJSX(child, inner, mem, i);
                } else if (Number.isNaN(parseFloat(node.name))) {
                    if (isKnownComponent) {
                        const inner = renderInner(child, node, rootReactNode);
                        pushTranslatedJSX(child, inner, mem, i, node.voidElement);
                    } else if (keepArray.indexOf(node.name) > -1) {
                        if (node.voidElement) {
                            mem.push(createElement(node.name, {key: `${node.name}-${i}`}));
                        } else {
                            const inner = mapAST(
                                reactNodes /* wrong but we need something */,
                                node.children,
                                rootReactNode,
                            );

                            mem.push(createElement(node.name, {key: `${node.name}-${i}`}, inner));
                        }
                    } else if (node.voidElement) {
                        mem.push(`<${node.name} />`);
                    } else {
                        const inner = mapAST(
                            reactNodes /* wrong but we need something */,
                            node.children,
                            rootReactNode,
                        );

                        mem.push(`<${node.name}>${inner}</${node.name}>`);
                    }
                } else if (typeof child === 'object' && !isElement) {
                    const content = node.children[0] ? translationContent : null;

                    // v1
                    // as interpolation was done already we just have a regular content node
                    // in the translation AST while having an object in reactNodes
                    // -> push the content no need to interpolate again
                    if (content) {
                        mem.push(content);
                    }
                } else if (node.children.length === 1 && translationContent) {
                    // If component does not have children, but translation - has
                    // with this in component could be components={[<span class='make-beautiful'/>]} and in translation - 'some text <0>some highlighted message</0>'
                    mem.push(cloneElement(child, {...child.props, key: i}, translationContent));
                } else {
                    mem.push(cloneElement(child, {...child.props, key: i}));
                }
            } else if (node.type === 'text') {
                mem.push(node.content);
            }
            return mem;
        }, []);
    };

    // call mapAST with having react nodes nested into additional node like
    // we did for the string ast from translation
    // return the children of that extra node to get expected result
    const result = mapAST([{dummy: true, children: children || []}], ast, getAsArray(children || []));
    return getChildren(result[0]);
};
