import {API, Resource} from '@coveo/platform-client';
import {ConfigurationType, RuleAction, RuleBlueprintModel} from '../../interfaces';

export class Rules extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/configurations/rules`;

    get(configurationType: ConfigurationType, ruleAction: RuleAction, ruleId: string): Promise<RuleBlueprintModel> {
        return this.api.get<RuleBlueprintModel>(constructDeleteOrGetUrl(configurationType, ruleAction, ruleId));
    }

    create(ruleBlueprint: RuleBlueprintModel): Promise<RuleBlueprintModel> {
        return this.api.post<RuleBlueprintModel>(Rules.baseUrl, ruleBlueprint);
    }

    update(ruleId: string, ruleBlueprint: RuleBlueprintModel): Promise<RuleBlueprintModel> {
        return this.api.put<RuleBlueprintModel>(`${Rules.baseUrl}/${ruleId}`, ruleBlueprint);
    }

    delete(configurationType: ConfigurationType, ruleAction: RuleAction, ruleId: string) {
        return this.api.delete(constructDeleteOrGetUrl(configurationType, ruleAction, ruleId));
    }
}

const constructDeleteOrGetUrl = (configurationType: ConfigurationType, ruleAction: RuleAction, ruleId: string) =>
    [Rules.baseUrl, configurationType.toUpperCase(), ruleAction.toUpperCase(), ruleId].join('/');
