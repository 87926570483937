import {FunctionComponent, useEffect} from 'react';
import {useNavigate} from '@core/routes';

const isLink = (el: HTMLElement): el is HTMLAnchorElement => el.tagName === 'A';

export const LinkHandler: FunctionComponent = () => {
    const navigate = useNavigate();

    const onClickElement = (evt: MouseEvent) => {
        if (evt.ctrlKey || evt.metaKey) {
            // do nothing, the browser will handle the navigation
            return;
        }

        const el = evt.target as HTMLElement;
        let link: HTMLAnchorElement;
        if (isLink(el)) {
            link = el;
        } else {
            const parentLink = el.closest('a');
            if (parentLink) {
                link = parentLink;
            }
        }

        if (link) {
            const removeListener = link.getAttribute('data-remove-listener') !== null;
            if (removeListener) {
                return;
            }

            const url = link.getAttribute('href');
            const isDisabled = link.getAttribute('disabled') !== null;
            if (url?.startsWith('#') && !isDisabled) {
                navigate(url.replace(/^#\/?/, '/'));
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', onClickElement);
        return () => {
            document.removeEventListener('click', onClickElement);
        };
    }, []);

    return null;
};
