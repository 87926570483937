import {
    type ColumnDef,
    createColumnHelper,
    type Factory,
    factory,
    type StylesApiProps,
    Table,
    type TableProps,
    useProps,
    useStyles,
    useTable,
    useUncontrolled,
} from '@components/mantine';
import {AccessLevel, ApiKeyAccessModel, IdAndDisplayNameModel, Platform, useQuery} from '@core/api';
import {useCallback, useMemo} from 'react';
import {Locales} from '../../../strings/Locales';
import {AccessTableContextProvider} from '../AccessTable.context';
import {AccessTableProps, AccessTableStylesNames} from '../AccessTable.types';
import {AccessTableAccessLevelCell} from '../AccessTableAccessLevelCell';
import {AccessTableUtils} from '../AccessTableUtils';
import {AccessTableConstants} from '../AccessTableConstants';

export interface APIKeysAccessTableProps
    extends AccessTableProps<ApiKeyAccessModel>,
        Omit<TableProps<ApiKeyAccessModel>, 'data' | 'columns' | 'store' | 'classNames' | 'styles' | 'vars'>,
        StylesApiProps<APIKeysAccessTableFactory> {}

export type APIKeysAccessTableFactory = Factory<{
    props: APIKeysAccessTableProps;
    ref: HTMLDivElement;
    stylesNames: AccessTableStylesNames;
    compound: true;
}>;

const defaultProps: Partial<APIKeysAccessTableProps> = {};

export const APIKeysAccessTable = factory<APIKeysAccessTableFactory>((_props, ref) => {
    const {
        privilegeOwner: owner,
        privilegeTargetDomain: targetDomain,
        resourceId,
        onChange,
        defaultValue,
        onInitialization,
        value,
        data,
        loading,
        classNames,
        styles,
        vars,
        className,
        style,
        readOnly,
        ...others
    } = useProps('APIKeysAccessTable', defaultProps, _props);

    const [_value, handleChange, isControlled] = useUncontrolled({
        value,
        defaultValue,
        finalValue: null,
        onChange,
    });

    const internalOnChange = useCallback((newValue: IdAndDisplayNameModel[]) => {
        if (newValue?.length > 0) {
            handleChange(newValue.sort(AccessTableUtils.sortIdNameModels));
        } else {
            handleChange?.(AccessTableConstants.noAccess);
        }
    }, []);

    const table = useTable<ApiKeyAccessModel>({
        initialState: {
            totalEntries: isControlled && data ? data.length : undefined,
        },
    });

    const query = useQuery({
        enabled: !isControlled,
        queryKey: ['access', 'api-keys', {targetDomain, owner}],
        queryFn: async () => {
            try {
                const accessModels = (await Platform.organizationAccess.getApiKeys({
                    accessLevel: [AccessLevel.CUSTOM, AccessLevel.VIEW_ALL, AccessLevel.NONE, AccessLevel.EDIT_ALL],
                    privilegeOwner: owner,
                    privilegeTargetDomain: targetDomain,
                })) as ApiKeyAccessModel[];
                table.setTotalEntries(accessModels.length);
                const initialAccessModels = AccessTableUtils.overrideAccessModelsWithValue(
                    accessModels,
                    _value,
                    resourceId,
                );
                const initialValue = AccessTableUtils.serializeAccessModels(initialAccessModels, resourceId);
                internalOnChange(initialValue);
                onInitialization?.(initialValue);
                return accessModels;
            } catch (error) {
                console.error(error);
                return [];
            }
        },
    });

    const getStyles = useStyles<APIKeysAccessTableFactory>({
        name: 'APIKeysAccessTable',
        classes: {},
        vars,
        classNames,
        className,
        style,
        props: _props,
        styles,
    });

    const rows = useMemo(() => {
        const accessModels = isControlled ? data : query.data;
        return accessModels ? AccessTableUtils.sortModels(accessModels) : undefined;
    }, [isControlled, data, query.data]);

    return (
        <AccessTableContextProvider
            value={{
                getStyles,
                targetDomain,
                onChange: internalOnChange,
                value: _value,
                readOnly,
            }}
        >
            <Table<ApiKeyAccessModel>
                ref={ref}
                store={table}
                data={rows}
                loading={isControlled ? loading : query.isLoading || _value === null}
                getRowId={(row) => row.id}
                columns={columns}
                {...others}
            />
        </AccessTableContextProvider>
    );
});

const columnHelper = createColumnHelper<ApiKeyAccessModel>();
const columns: Array<ColumnDef<ApiKeyAccessModel>> = [
    columnHelper.accessor('displayName', {
        header: Locales.format('APIKeysAccessTable.headerLabel.apiKeys'),
        enableSorting: false,
    }),
    columnHelper.accessor('accessLevel', {
        header: Locales.format('AccessTable.headerLabel.accessLevel'),
        enableSorting: false,
        cell: (info) => <AccessTableAccessLevelCell {...info.row.original} />,
    }),
];
