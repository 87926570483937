import {Center, Rating, Stack, Title} from '@components/mantine';
import {FunctionComponent} from 'react';
import {Locales} from '../../strings/Locales';

interface StarRatingSurveyComponentProps {
    surveyTitleContext: string;
    onRatingChanged: (value: number) => void;
}
export const StarRatingSurveyComponent: FunctionComponent<StarRatingSurveyComponentProps> = ({
    surveyTitleContext,
    onRatingChanged,
}) => (
    <Stack gap="sm">
        <Title order={4} ta="center">
            {surveyTitleContext}
        </Title>
        <Title order={3} ta="center">
            {Locales.format('UserFeedbackModal.ratingQuestion.title')}
        </Title>
        <Center>
            <Rating size="60px" onChange={onRatingChanged}></Rating>
        </Center>
    </Stack>
);
