import dayjs from 'dayjs';
import dayJsDuration from 'dayjs/plugin/duration';
import {Locales} from '../strings/Locales';
dayjs.extend(dayJsDuration);

interface DurationInformation {
    milliseconds?: number;
    seconds?: number;
    minutes?: number;
    hours?: number;
}

export const getFormatedTimeUntilLogout = (duration: dayJsDuration.Duration): string => {
    const parsedDuration: DurationInformation = parse(duration);
    return humanize(parsedDuration);
};

/* * * * * * * * * * * * * *
 *   Private functions
 * * * * * * * * * ** * * * */

const parse = (duration: dayJsDuration.Duration): DurationInformation => {
    const milliseconds = duration.asMilliseconds();
    if (milliseconds < 1000) {
        return milliseconds > 500 ? {seconds: 1} : {milliseconds};
    }

    let secondsLeft: number = duration.asSeconds();
    const durationMap: DurationInformation = {};

    const hours = duration.hours();
    if (hours) {
        durationMap.hours = hours;
        secondsLeft -= hours * 60 * 60;
    }

    const minutes = duration.minutes();
    if (minutes) {
        durationMap.minutes = minutes;
        secondsLeft -= minutes * 60;
    }

    const seconds = duration.seconds();
    if (seconds) {
        durationMap.seconds = seconds;
        secondsLeft -= seconds;
    }

    return durationMap;
};

const humanize = (durationInformation: DurationInformation): string => {
    const {milliseconds, seconds, minutes, hours} = durationInformation;

    const addSpaceIfNeeded = () => {
        if (result.length) {
            result += ' ';
        }
    };

    let result = '';

    if (hours) {
        addSpaceIfNeeded();
        result += hours + ' ' + Locales.format('hours', {smart_count: hours});
    }

    if (minutes) {
        addSpaceIfNeeded();
        result += minutes + ' ' + Locales.format('minutes', {smart_count: minutes});
    }

    if (seconds) {
        addSpaceIfNeeded();
        result += seconds + ' ' + Locales.format('seconds', {smart_count: seconds});
    }

    if (result.length && milliseconds) {
        return milliseconds + ' ' + Locales.format('milliseconds', {smart_count: milliseconds});
    }

    return result;
};
