import {API, Resource} from '@coveo/platform-client';
import {ListingPreviewRequestModel, PreviewResultModel} from '../../interfaces';

export class ListingPreview extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/listing`;

    preview(options: ListingPreviewRequestModel) {
        return this.api.post<PreviewResultModel>(`${ListingPreview.baseUrl}/preview`, options);
    }
}
