import {API, Resource} from '@coveo/platform-client';
import {AnswerEvaluation, AnswerEvaluationParams, AnswerPage} from '../../../interfaces/knowledge/AnswerInterfaces';

export class AnswerEvaluationResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/answer/v1/configs`;

    list(configId: string, params?: AnswerEvaluationParams): Promise<AnswerPage<AnswerEvaluation>> {
        return this.api.get<AnswerPage<AnswerEvaluation>>(
            this.buildPath(`${AnswerEvaluationResource.baseUrl}/${configId}/evaluations`, params),
        );
    }

    get(configId: string, evaluationId: string): Promise<AnswerEvaluation> {
        return this.api.get<AnswerEvaluation>(
            this.buildPath(`${AnswerEvaluationResource.baseUrl}/${configId}/evaluations/${evaluationId}`),
        );
    }
}
