import {Box, Group, Stack} from '@components/mantine';
import {forwardRef} from 'react';

import {SourceIdentityCategoryIcon} from './source-identity-category-icon';
import {SourceIdentityCopyInfoButton} from './source-identity-copy-info-button';
import {SourceIdentityName} from './source-identity-name';
import {SourceIdentityTypeImage} from './source-identity-type-image';
import {SourceIdentityTypeLabel} from './source-identity-type-label';
import {SourceIdentityVisibilityIcon} from './source-identity-visibility-icon';
import {SourceIdentityProps} from './SourceIdentity';
import {useSourceIdentityContext} from './SourceIdentityContext';

export const SourceIdentityListVariant = forwardRef<HTMLDivElement, SourceIdentityProps>(
    ({children: _children, trackingHandler, ...props}, ref) => {
        const {getStyles} = useSourceIdentityContext();

        return (
            <Box ref={ref} flex={1} {...getStyles('root')} {...props}>
                <Group align="top" gap="xs">
                    <SourceIdentityTypeImage size="lg" mt="4px" />

                    <Stack gap={0}>
                        <Group gap={0}>
                            <SourceIdentityName />
                            <SourceIdentityCopyInfoButton trackCopyInfoButton={trackingHandler?.trackCopyInfoButton} />
                        </Group>

                        <SourceIdentityTypeLabel size="sm" />

                        <Group align="top" gap="xs">
                            <SourceIdentityCategoryIcon showTooltip size="sm" />
                            <SourceIdentityVisibilityIcon showTooltip size="sm" />
                        </Group>
                    </Stack>
                </Group>
            </Box>
        );
    },
);
SourceIdentityListVariant.displayName = '@resourceIdentity/source/SourceIdentity/ListVariant';
