import {track} from '@core/tracking';

const trackClickedScheduledMaintenance = () => track({action: 'clicked', subject: 'scheduled maintenance'});

const trackClickedSystemsStatus = () => track({action: 'clicked', subject: 'systems status'});

const trackClickedOrganizationStatus = () => track({action: 'clicked', subject: 'organization status'});

const trackViewedWhatsNew = () => track({action: 'viewed', subject: 'whats new'});

const trackClickedUserNotifications = () => track({action: 'opened', subject: 'user notifications'});

export const NotificationsTracking = {
    trackClickedScheduledMaintenance,
    trackClickedSystemsStatus,
    trackClickedOrganizationStatus,
    trackViewedWhatsNew,
    trackClickedUserNotifications,
};
