import {ReactNode} from 'react';

import {Locales, TranslationOptions} from './Locales';
import {renderNodes} from './react-i18n/renderNodes';

interface TranslationProps<T, U> {
    i18nKey: T;
    t: U;
    children: ReactNode;
    options?: TranslationOptions;
}

export const Translation = <T extends string, U extends Locales<T>>({
    i18nKey,
    t,
    children,
    options,
}: TranslationProps<T, U>) => renderNodes(children, t.format(i18nKey, options));
