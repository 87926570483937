import {AppShell, AppShellNavbarProps, Factory, factory, useProps} from '@components/mantine';
import {FunctionComponent} from 'react';

import {useNavigation} from '../hooks';

export type NavigationSideBarStylesNames = 'navbar';

export interface NavigationSideBarProps extends Omit<AppShellNavbarProps, 'hidden'> {}

export type NavigationSideBarFactory = Factory<{
    props: NavigationSideBarProps;
    ref: HTMLInputElement;
    stylesNames: NavigationSideBarStylesNames;
}>;

const defaultProps: Partial<NavigationSideBarProps> = {};

export const NavigationSideBar: FunctionComponent<NavigationSideBarProps> = factory<NavigationSideBarFactory>(
    (_props, ref) => {
        const {collapsed} = useNavigation();
        const props = useProps('NavigationSideBar', defaultProps, _props);

        return <AppShell.Navbar ref={ref} {...props} hidden={collapsed || undefined} data-collapsed={!collapsed} />;
    },
);
