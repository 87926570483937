import {API, Resource} from '@coveo/platform-client';
import {ChunkInspectorAnswerResource} from './answer/ChunkInspectorAnswer';
import {ChunkInspectorDocumentResource} from './document/ChunkInspectorDocument';
import {ChunkInspectorSearchResource} from './search/ChunkInspectorSearch';

export default class ChunkInspector extends Resource {
    answer: ChunkInspectorAnswerResource;
    search: ChunkInspectorSearchResource;
    document: ChunkInspectorDocumentResource;

    constructor(api: API, serverlessApi: API) {
        super(api, serverlessApi);

        this.answer = new ChunkInspectorAnswerResource(api, serverlessApi);
        this.search = new ChunkInspectorSearchResource(api, serverlessApi);
        this.document = new ChunkInspectorDocumentResource(api, serverlessApi);
    }
}
