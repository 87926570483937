import google from '@coveord/plasma-style/resources/icons/svg/domain-google.svg';
import salesforce from '@coveord/plasma-style/resources/icons/svg/source-salesforce.svg';
import office365 from '@coveord/plasma-style/resources/icons/svg/domain-office365.svg';
import {EmailSize32Px, SamlSize32Px} from '@coveord/plasma-react-icons';
import {FunctionComponent} from 'react';
import {svg} from '@coveord/plasma-style';

interface ProviderSvgIconProps {
    provider: string;
    height?: number;
    className?: string;
}

export const ProviderSvgIcon: FunctionComponent<ProviderSvgIconProps> = ({provider, height = 16, className}) => {
    const commonProps = {height: height, className: className};

    switch (provider) {
        case DomainProviders.ANY:
        case DomainProviders.EMAIL:
            return <EmailSize32Px style={{color: 'var(--links-color'}} {...commonProps} />;
        case DomainProviders.GOOGLE:
            return <img src={google} {...commonProps} />;
        case DomainProviders.SALESFORCE:
        case DomainProviders.SALESFORCE_SANDBOX:
            return <img src={salesforce} {...commonProps} />;
        case DomainProviders.OFFICE365:
            return <img src={office365} {...commonProps} />;
        case DomainProviders.SAML:
            return <SamlSize32Px {...commonProps} />;
        default:
            return null;
    }
};

export const DomainProviders = {
    ANY: 'ANY',
    GOOGLE: 'GOOGLE',
    SALESFORCE: 'SALESFORCE',
    SALESFORCE_SANDBOX: 'SALESFORCE_SANDBOX',
    OFFICE365: 'OFFICE365',
    SAML: 'SAML',
    EMAIL: 'EMAIL',
};

export const ProviderSvgName = {
    [DomainProviders.ANY]: svg.email.name,
    [DomainProviders.GOOGLE]: svg.domainGoogle.name,
    [DomainProviders.SALESFORCE]: svg.domainSalesforce.name,
    [DomainProviders.SALESFORCE_SANDBOX]: svg.domainSalesforce.name,
    [DomainProviders.OFFICE365]: svg.domainOffice365.name,
    [DomainProviders.SAML]: svg.domainSamlGradient.name,
    [DomainProviders.EMAIL]: svg.email.name,
};

export const DomainProviderIconModifier = {
    [DomainProviders.SALESFORCE]: 'salesforce-blue',
    [DomainProviders.SALESFORCE_SANDBOX]: 'salesforce-blue',
    [DomainProviders.OFFICE365]: 'office365-red',
    [DomainProviders.SAML]: 'documentation-link',
    [DomainProviders.ANY]: 'documentation-link',
    [DomainProviders.EMAIL]: 'documentation-link',
};
