import {ColumnDef, createColumnHelper, useTable} from '@components/mantine';
import {GroupModel, Platform, useQuery} from '@core/api';
import {FunctionComponent} from 'react';
import {PrivilegeRow} from '../../PrivilegesTable.types';
import {GenericGranularPrivilegesTable} from '../GenericGranularPrivilegesTable';
import {Locales} from '../../../../strings/Locales';

const columnHelper = createColumnHelper<GroupModel>();

const columns: Array<ColumnDef<GroupModel>> = [
    columnHelper.accessor('displayName', {
        header: Locales.format('PrivilegesTable.Groups.column.name.header'),
        enableSorting: false,
    }),
];

export const GroupsPrivilegesTable: FunctionComponent<PrivilegeRow> = (props) => {
    const store = useTable<GroupModel>();
    const query = useQuery({
        queryKey: ['group', 'list'],
        queryFn: async () => {
            const response = await Platform.group.list();
            store.setTotalEntries(response.length);
            return response;
        },
    });
    return (
        <GenericGranularPrivilegesTable<GroupModel>
            store={store}
            columns={columns}
            query={query}
            resourceName={props.name.toLowerCase()}
            {...props}
        />
    );
};
