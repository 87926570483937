import {Radio} from '@coveord/plasma-mantine';
import {ComponentProps} from 'react';
import {Controller} from 'react-hook-form';
import {BasicReactHookFormPropsWrappedComponents} from '../interfaces';

export interface RHFRadioGroupProps
    extends ComponentProps<typeof Radio.Group>,
        BasicReactHookFormPropsWrappedComponents {}

export const RHFRadioGroup = ({
    control,
    onBlur: externalOnBlur,
    onChange: externalOnChange,
    ...restProps
}: RHFRadioGroupProps) => (
    <Controller
        control={control}
        name={restProps.name}
        render={({field: {onBlur, onChange, ...restField}, fieldState}) => (
            <Radio.Group
                {...restField}
                error={fieldState.error?.message}
                onBlur={(event) => {
                    externalOnBlur?.(event);
                    onBlur();
                }}
                onChange={(event) => {
                    externalOnChange?.(event);
                    onChange(event);
                }}
                {...restProps}
            />
        )}
    />
);
