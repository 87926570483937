import {
    BoxProps,
    Button,
    Center,
    Factory,
    factory,
    Group,
    Stack,
    StylesApiProps,
    useProps,
    useStyles,
} from '@coveord/plasma-mantine';
import {Children, ReactElement, ReactNode} from 'react';
import classes from './EmptyState.module.css';
import {EmptyStateBody, EmptyStateBodyStylesNames} from './EmptyStateBody';
import {EmptyStateProvider, EmptyStateVariantType} from './EmptyStateContext';
import {EmptyStateImage, EmptyStateImageStylesNames} from './EmptyStateImage';
import {EmptyStateLink, EmptyStateLinkStylesNames} from './EmptyStateLink';
import {EmptyStatePrerequisiteList, PrerequisiteListStylesName} from './EmptyStatePrerequisite';
import {EmptyStatePrerequisiteListItem, PrerequisiteItemStylesName} from './EmptyStatePrerequisiteListItem';
import {EmptyStateTitle, EmptyStateTitleStylesNames} from './EmptyStateTitle';

export type EmptyStateStylesNames =
    | 'root'
    | 'container'
    | 'actionsContainer'
    | EmptyStateBodyStylesNames
    | EmptyStateImageStylesNames
    | EmptyStateLinkStylesNames
    | EmptyStateTitleStylesNames
    | PrerequisiteListStylesName
    | PrerequisiteItemStylesName;

export interface EmptyStateProps extends BoxProps, StylesApiProps<EmptyStateFactory> {
    children: ReactNode;
    /**
     * The variant of the empty state.
     *
     * @default primary
     */
    variant?: EmptyStateVariantType;
}

export type EmptyStateFactory = Factory<{
    props: EmptyStateProps;
    ref: HTMLDivElement;
    stylesNames: EmptyStateStylesNames;
    variant: EmptyStateVariantType;
    vars: never;
    staticComponents: {
        Action: typeof Button;
        Title: typeof EmptyStateTitle;
        Body: typeof EmptyStateBody;
        Link: typeof EmptyStateLink;
        Image: typeof EmptyStateImage;
        PrerequisiteList: typeof EmptyStatePrerequisiteList;
        PrerequisiteListItem: typeof EmptyStatePrerequisiteListItem;
    };
}>;

const defaultProps: Partial<EmptyStateProps> = {
    variant: 'first-use',
};

export const EmptyState = factory<EmptyStateFactory>((props, ref) => {
    const {variant, children, style, className, classNames, styles, unstyled, ...others} = useProps(
        'EmptyState',
        defaultProps,
        props,
    );
    const getStyles = useStyles<EmptyStateFactory>({
        name: 'EmptyState',
        classes,
        props,
        className,
        style,
        classNames,
        styles,
        unstyled,
    });
    const childrenArray = Children.toArray(children) as ReactElement[];
    const actions = childrenArray.filter((child) => child.type === Button);
    const links = childrenArray.filter((child) => child.type === EmptyStateLink);
    const image = childrenArray.find((child) => child.type === EmptyStateImage);
    const title = childrenArray.find((child) => child.type === EmptyStateTitle);
    const body = childrenArray.find((child) => child.type === EmptyStateBody);
    const prerequisitesList = childrenArray.find((child) => child.type === EmptyStatePrerequisiteList);

    if (variant === 'small') {
        return (
            <EmptyStateProvider value={{variant, getStyles}}>
                <Center flex={1}>
                    <Stack ref={ref} variant={variant} gap="sm" p="lg" {...others} {...getStyles('root')}>
                        <Stack {...getStyles('container')} gap="xs">
                            {image}
                            {title}
                            {body}
                        </Stack>
                        {links}
                        <Group justify="center" gap="sm">
                            {actions}
                        </Group>
                    </Stack>
                </Center>
            </EmptyStateProvider>
        );
    }

    return (
        <EmptyStateProvider value={{variant, getStyles}}>
            <Center flex={1}>
                <Group ref={ref} p="lg" wrap="nowrap" gap="xl" variant={variant} {...others} {...getStyles('root')}>
                    <Stack {...getStyles('container')} gap="lg" flex={1}>
                        <Stack gap="xs">
                            {title}
                            {body}
                        </Stack>
                        {prerequisitesList}
                        {links.length ? <Stack gap="xs">{links}</Stack> : null}
                        {actions.length > 0 ? (
                            <Group {...getStyles('actionsContainer')} gap="sm">
                                {actions}
                            </Group>
                        ) : null}
                    </Stack>
                    {image}
                </Group>
            </Center>
        </EmptyStateProvider>
    );
});

EmptyState.Action = Button;
EmptyState.Title = EmptyStateTitle;
EmptyState.Body = EmptyStateBody;
EmptyState.Link = EmptyStateLink;
EmptyState.Image = EmptyStateImage;
EmptyState.PrerequisiteList = EmptyStatePrerequisiteList;
EmptyState.PrerequisiteListItem = EmptyStatePrerequisiteListItem;
EmptyState.displayName = '@components/mantine/EmptyState';
