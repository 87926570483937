import {
    Container,
    Group,
    StickyFooter,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
} from '@components/mantine';
import {forwardRef, type ForwardRefExoticComponent, type ReactNode, type RefAttributes} from 'react';
import {usePageContext} from '../contexts';
import {type PageVariant} from './Page';

export type PageFooterStylesNames = 'footerContainer';

export interface PageFooterProps extends CompoundStylesApiProps<PageFooterFactory> {
    children: ReactNode;
}

export type PageFooterFactory = Factory<{
    props: PageFooterProps;
    ref: HTMLDivElement;
    stylesNames: PageFooterStylesNames;
    compound: true;
}>;

const defaultProps: Partial<PageFooterProps> = {};

const FullPageFooter = forwardRef<HTMLDivElement, PageFooterProps>(({children}, ref) => (
    <StickyFooter ref={ref} borderTop>
        {children}
    </StickyFooter>
));

const CenteredPageFooter = forwardRef<HTMLDivElement, PageFooterProps>(({children}, ref) => {
    const {getStyles} = usePageContext();

    return (
        <StickyFooter ref={ref} borderTop>
            <Container
                w="100%"
                renderRoot={(props) => <Group justify="flex-end" gap="sm" {...props} />}
                {...getStyles('footerContainer')}
            >
                {children}
            </Container>
        </StickyFooter>
    );
});

const pageFooterVariants: Record<
    PageVariant,
    ForwardRefExoticComponent<PageFooterProps & RefAttributes<HTMLDivElement>>
> = {
    full: FullPageFooter,
    centered: CenteredPageFooter,
};

export const PageFooter = factory<PageFooterFactory>((_props, ref) => {
    const {variant} = usePageContext();
    const props = useProps('PageFooter', defaultProps, _props);
    const PageFooterComponent = pageFooterVariants[variant] ?? FullPageFooter;

    return <PageFooterComponent ref={ref} {...props} />;
});
