import {
    Format,
    FormatOrFunction,
    FormatRange,
    FormatToParts,
    FormatWithResolvedOptions,
    isMissingValue,
    MissingFormatOptions,
    MissingFormatPart,
    MissingValue,
    ResolvedMissingFormatOptions,
} from '../../api';
import {toFormat} from './BoundFormat';

export class MissingFormat<TValue> implements Format<TValue | MissingValue> {
    protected readonly wrapped: Format<TValue>;
    protected readonly missing: string;

    constructor(wrapped: FormatOrFunction<TValue>, options?: MissingFormatOptions) {
        Object.defineProperties(this, {
            format: {value: this.format.bind(this)},
            wrapped: {value: toFormat(wrapped)},
            missing: {value: options?.missing ?? ''},
        });
    }

    format(value: TValue | MissingValue): string {
        return isMissingValue(value) ? this.missing : this.wrapped.format(value);
    }

    resolvedOptions(): ResolvedMissingFormatOptions {
        return {missing: this.missing};
    }
}

export class MissingFormatWithOptions<TValue, TOptions>
    extends MissingFormat<TValue>
    implements FormatWithResolvedOptions<TValue | MissingValue, TOptions & ResolvedMissingFormatOptions>
{
    protected readonly wrapped: FormatWithResolvedOptions<TValue, TOptions>;

    constructor(wrapped: FormatWithResolvedOptions<TValue, TOptions>, options?: MissingFormatOptions) {
        super(wrapped, options);
    }

    resolvedOptions(): TOptions & ResolvedMissingFormatOptions {
        return {...this.wrapped.resolvedOptions(), missing: this.missing};
    }
}

export class MissingFormatToParts<TValue, TOptions, TPart>
    extends MissingFormatWithOptions<TValue, TOptions>
    implements FormatToParts<TValue | MissingValue, TOptions & ResolvedMissingFormatOptions, TPart | MissingFormatPart>
{
    protected readonly wrapped: FormatToParts<TValue, TOptions, TPart>;

    constructor(wrapped: FormatToParts<TValue, TOptions, TPart>, options?: MissingFormatOptions) {
        super(wrapped, options);
    }

    formatToParts(value: TValue): TPart[];
    formatToParts(value: MissingValue): [MissingFormatPart];
    formatToParts(value: TValue | MissingValue): TPart[] | [MissingFormatPart];
    formatToParts(value: TValue | MissingValue): TPart[] | [MissingFormatPart] {
        if (isMissingValue(value)) {
            return [{type: 'missing', value: this.missing}];
        }
        return this.wrapped.formatToParts(value);
    }
}

export class MissingFormatRange<TValue, TOptions, TPart, TRangePart extends TPart>
    extends MissingFormatToParts<TValue, TOptions, TPart>
    implements
        FormatRange<
            TValue | MissingValue,
            TOptions & ResolvedMissingFormatOptions,
            TPart | MissingFormatPart,
            TRangePart
        >
{
    protected readonly wrapped: FormatRange<TValue, TOptions, TPart, TRangePart>;

    constructor(wrapped: FormatRange<TValue, TOptions, TPart, TRangePart>, options?: MissingFormatOptions) {
        super(wrapped, options);
    }

    formatRange(start: Exclude<TValue, MissingValue>, end: Exclude<TValue, MissingValue>): string {
        return this.wrapped.formatRange(start, end);
    }

    formatRangeToParts(start: Exclude<TValue, MissingValue>, end: Exclude<TValue, MissingValue>): TRangePart[] {
        return this.wrapped.formatRangeToParts(start, end);
    }
}
