import {SourceCategory, SourceType, SourceVisibility} from '@core/api';
import {createSafeContext, type GetStylesApi} from '@components/mantine';

import {SourceIdentityFactory, SourceIdentityVariant} from './SourceIdentity';
import {SourceIdentityBadgeType} from './SourceIdentityCardVariant';

export interface SourceIdentityContextValue {
    badge?: SourceIdentityBadgeType;
    id?: string;
    name?: string;
    category?: SourceCategory;
    sourceType: SourceType;
    sourceVisibility?: SourceVisibility;
    variant: SourceIdentityVariant;
    getStyles: GetStylesApi<SourceIdentityFactory>;
}

export const [SourceIdentityProvider, useSourceIdentityContext] = createSafeContext<SourceIdentityContextValue>(
    'SourceIdentity component was not found in the tree',
);
