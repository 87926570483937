import {LaunchDarklyFeatureFlags} from '@core/feature-flags';
import {UserSelectors} from '@core/user';
import {FunctionComponent, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {LaunchDarklyFlags} from '../feature-flags/FeatureFlags';
import {UserFeedbackAnswer, UserFeedbackSurvey} from '../interfaces';
import {UserFeedbackTracking} from '../tracking';
import {UserFeedbackModalUtils} from '../utils';
import {UserFeedbackModal} from './UserFeedbackModal';

export const UserFeedbackProvider: FunctionComponent = () => {
    const [id, setId] = useState('');
    const [type, setType] = useState(null);
    const [title, setTitle] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const userEmail = useSelector(UserSelectors.getUserEmail);

    const initSurvey = (e: CustomEvent<UserFeedbackSurvey>) => {
        setId(e.detail.id);
        setType(e.detail.type);
        setTitle(e.detail.surveyTitleContext);
        setAdditionalInfo(e.detail?.additionalInfoTitle);
        setOpen(true);
        UserFeedbackTracking.trackView(e.detail.id, e.detail.type, e.detail.surveyTitleContext);
    };
    const endSurvey = () => {
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('show_survey', initSurvey);
        document.addEventListener('hide_survey', endSurvey);
        return () => {
            document.removeEventListener('show_survey', initSurvey);
            document.removeEventListener('hide_survey', endSurvey);
        };
    }, []);

    return (
        <>
            <UserFeedbackModal
                userFeedbackSurvey={{
                    id: id,
                    type: type,
                    surveyTitleContext: title,
                    additionalInfoTitle: additionalInfo,
                }}
                onSubmit={(answer: UserFeedbackAnswer) => {
                    const noIdentification = LaunchDarklyFeatureFlags.isActive(LaunchDarklyFlags.SurveyIncognitoOrgs);
                    UserFeedbackTracking.trackClickSubmit({
                        numberOfStars: answer.value,
                        surveyId: id,
                        surveyType: type,
                        surveyTitle: title,
                        additionalInfo: answer.additionalInfo,
                        email: noIdentification ? null : userEmail,
                    });
                    UserFeedbackModalUtils.setSurveyCompleted({item: id, type: type});
                }}
                onSkip={() => {
                    UserFeedbackTracking.trackClickSkip(id, type, title);
                    UserFeedbackModalUtils.setSurveySkippedDate({item: id, type: type});
                }}
                onDontAskAgain={() => {
                    UserFeedbackTracking.trackClickDontAskAgain(id, type, title);
                    UserFeedbackModalUtils.setDontAskAgainSurvey({item: id, type: type});
                }}
                opened={isOpen}
                onClose={() => setOpen(false)}
                owner="frontend-foundation"
                framework="Mantine"
            />
        </>
    );
};
