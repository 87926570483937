const registry = new Map();

const register = (key: any, value: any): void => {
    registry.set(key, value);
};

const get = <R = any>(key: string): R => {
    if (!registry.has(key)) {
        throw new Error(`Key "${key}" does not exist in the registry.`);
    }
    return registry.get(key) as R;
};

const clear = (): void => {
    registry.clear();
};

const toObject = (): any => Object.fromEntries(registry);

export const Registry = {
    /**
     * Registers a value at the specified key in the registry.
     *
     * @param key The unique identifier with which the value can be retrieved.
     * @param value The value to store in the registry.
     */
    register,

    /**
     * Retrieves the value at the specified key in the registry.
     *
     * @param key The unique identifier with which the value can be retrived.
     * @returns The value stored at the specified key.
     * @throws An error if the key has never been registered in the registry.
     */
    get,

    /**
     * Erases all the information stored in the registry.
     * Useful during tests to ensure tests independance.
     */
    clear,

    /**
     * @returns The content of the registry.
     */
    toObject,
};

export default Registry;
