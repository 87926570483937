import {useEffect, useRef} from 'react';
import {usePageContext} from '../contexts';

export const usePageTitle = (title: string, resetOnUnmount = true) => {
    const {setPageTitle, pageTitle} = usePageContext();
    const originalTitle = useRef(pageTitle);

    useEffect(() => {
        setPageTitle(title);
    }, [title]);

    useEffect(
        () => () => {
            if (resetOnUnmount) {
                setPageTitle(originalTitle.current);
            }
        },
        [],
    );
};
