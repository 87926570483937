import {Modal, ModalProps, Stack} from '@components/mantine';
import {FunctionComponent, useState} from 'react';
import {UserFeedbackAnswer, UserFeedbackSurvey} from '../interfaces';
import {UserFeedbackModalFooter} from './UserFeedbackModalFooter';
import {AdditionalInfoSurveyComponent} from './survey-components/AdditionalInfoSurveyComponent';
import {StarRatingSurveyComponent} from './survey-components/StarRatingSurveyComponent';

interface UserFeedbackModalProps extends ModalProps {
    userFeedbackSurvey: UserFeedbackSurvey;
    onSubmit: (UserFeedbackAnswer?) => void;
    onSkip: (UserFeedbackAnswer?) => void;
    onDontAskAgain: (UserFeedbackAnswer?) => void;
}

export const UserFeedbackModal: FunctionComponent<UserFeedbackModalProps> = ({
    userFeedbackSurvey,
    onSubmit,
    onSkip,
    onDontAskAgain,
    ...others
}) => {
    const [answer, setAnswer] = useState<UserFeedbackAnswer>({
        userFeedbackSurveyId: userFeedbackSurvey.id,
        value: undefined,
        additionalInfo: undefined,
    });
    return (
        <Modal size="md" centered withCloseButton={false} {...others}>
            <Stack gap="0px" justify="center" pt="lg" pl="lg" pr="lg">
                <StarRatingSurveyComponent
                    surveyTitleContext={userFeedbackSurvey.surveyTitleContext}
                    onRatingChanged={(value) => setAnswer({...answer, value: value})}
                />
                <AdditionalInfoSurveyComponent
                    mounted={answer.value !== undefined}
                    title={userFeedbackSurvey?.additionalInfoTitle}
                    onTextChange={(value) => setAnswer({...answer, additionalInfo: value})}
                />
                <UserFeedbackModalFooter
                    canSubmit={answer.value !== undefined}
                    onSubmit={() => onSubmit(answer)}
                    onSkip={() => onSkip(answer)}
                    onDontAskAgain={() => onDontAskAgain(answer)}
                />
            </Stack>
        </Modal>
    );
};
