import {generatePath, Params} from 'react-router';
import {createSearchParams, URLSearchParamsInit} from 'react-router-dom';

export type PathGenerator = {
    /**
     * @param params Path params with their value to interpolate
     * @param searchParams Search params with their value to interpolate
     */
    (params?: Params, searchParams?: URLSearchParamsInit): string;
    /**
     * The original path without any parameters interpolated
     *
     * @example '/some/path/:param'
     */
    path: string;
    /**
     * The full path with the orgId param, without any parameters interpolated. Useful to register a route.
     *
     * @example '/:orgId/some/path/:param'
     */
    fullPath: string;
};
type PathObject = Record<string, string>;

interface GeneratePathsOptions {
    /**
     * The specified parentPath will be used a prefix for all the generated paths.
     *
     * @default ''
     */
    parentPath?: string;
}

/**
 * Makes paths a little more convenient to use by converting each path into a PathGenerator.
 *
 * @param paths The paths object to generate functions from
 * @returns An object with the same keys but whose values are PathGenerators.
 * @example
 *
 * const myPaths = {
 *     Fruits: '/fruits',
 *     Fruit: '/fruit/:id',
 * };
 * const MyRoutes = generatePaths(myPaths, {parentPath: '/veggies'});
 *
 * MyRoutes.Fruits()                // returns '/veggies/fruits'
 * MyRoutes.Fruit({id: 'banana'})   // returns '/veggies/fruit/banana'
 */
export const generatePaths = <T extends PathObject>(
    paths: T,
    {parentPath = ''}: GeneratePathsOptions = {},
): Record<keyof T, PathGenerator> =>
    Object.entries(paths).reduce<Record<any, PathGenerator>>((map, [key, path]) => {
        if (typeof path === 'string') {
            const fullPath = parentPath ? parentPath + path : path;
            const pathGenerator: PathGenerator = (params: Params, searchParams: URLSearchParamsInit): string => {
                const searchParamsString = '' + createSearchParams(searchParams);
                const search = searchParamsString ? '?' + searchParamsString : '';
                return generatePath(fullPath, params) + search;
            };
            pathGenerator.path = fullPath;
            pathGenerator.fullPath = '/:orgId' + fullPath;
            map[key] = pathGenerator;
        } else {
            map[key] = path;
        }
        return map;
    }, {});
