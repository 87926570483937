import {API, Resource} from '@coveo/platform-client';
import {ListingRulesListOptions, RulesListModel} from '../../interfaces';

export class ListingRules extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/configurations/listings/rules`;

    async list(options: ListingRulesListOptions): Promise<RulesListModel> {
        return this.api.get<RulesListModel>(this.buildPath(`${ListingRules.baseUrl}`, options));
    }
}
