import {Format, FormatFunction, FormatOrFunction} from '../../api';

/**
 * Used stand-alone to wrap a format function and create a "formatter" from it. Also used as a
 * base class to mimic Intl formatters' behavior, which allow calling `format` without context.
 * See the spec for more details: {@link https://tc39.es/ecma402/#sec-intl.numberformat.prototype.format}.
 */
export class BoundFormat<TValue> implements Format<TValue> {
    readonly format: FormatFunction<TValue>;

    constructor(format: FormatFunction<TValue>) {
        Object.defineProperty(this, 'format', {value: format});
    }
}

export const toFormat = <TValue>(format: FormatOrFunction<TValue>): Format<TValue> =>
    typeof format === 'function' ? new BoundFormat(format) : format;
