import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

type SurveyEvent = {
    item: string;
    type: 'ADD' | 'EDIT';
};

const hasCompletedSurvey = (event: SurveyEvent) =>
    localStorage.getItem(`completedSurvey_${event.type}_${event.item}`) === 'true';

const setSurveyCompleted = (event: SurveyEvent) => {
    localStorage.setItem(`completedSurvey_${event.type}_${event.item}`, 'true');
    localStorage.removeItem(`skippedSurvey_${event.type}_${event.item}`);
    localStorage.setItem('lastViewedSurvey', dayjs().toISOString());
};

const setSurveySkippedDate = (event: SurveyEvent) => {
    localStorage.setItem(`skippedSurvey_${event.type}_${event.item}`, dayjs().toISOString());
    localStorage.setItem('lastViewedSurvey', dayjs().toISOString());
};

const getShouldNotAskAgainSurvey = (event: SurveyEvent) =>
    localStorage.getItem(`dontAskAgainSurvey_${event.type}_${event.item}`) === 'true';

const setDontAskAgainSurvey = (event: SurveyEvent) => {
    localStorage.setItem(`dontAskAgainSurvey_${event.type}_${event.item}`, 'true');
    localStorage.removeItem(`skippedSurvey_${event.type}_${event.item}`);
    localStorage.setItem('lastViewedSurvey', dayjs().toISOString());
};

const getSurveySkippedDate = (event: SurveyEvent) => localStorage.getItem(`skippedSurvey_${event.type}_${event.item}`);

const getLastViewedSurvey = () => dayjs(localStorage.getItem('lastViewedSurvey'));

const isReadyForASurvey = () => {
    if (localStorage.getItem('lastViewedSurvey')) {
        const dateSinceLastSurvey = dayjs(getLastViewedSurvey());
        const daysSinceSurveySkipped = dayjs.duration(dayjs().diff(dateSinceLastSurvey)).asDays();
        return daysSinceSurveySkipped > 1;
    }
    return true;
};

export const UserFeedbackModalUtils = {
    hasCompletedSurvey,
    setSurveyCompleted,
    setSurveySkippedDate,
    setDontAskAgainSurvey,
    getSurveySkippedDate,
    getShouldNotAskAgainSurvey,
    isReadyForASurvey,
};
