import {track} from '@core/tracking';

const trackOpenWidget = (): void => {
    track({action: 'opened', subject: 'IPX helper'});
};

const trackCloseWidget = (): void => {
    track({action: 'closed', subject: 'IPX helper'});
};

export const HelpWidgetTracker = {trackOpenWidget, trackCloseWidget};
