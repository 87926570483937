import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const authenticationFlowRoutes: RouteObject[] = [
    {
        path: PlatformPaths.AuthenticationFlowSource.fullPath,
        lazy: async () => {
            const {AuthenticationFlowSourcePage} = await import('../pages/AuthenticationFlowSourcePage');
            return {Component: AuthenticationFlowSourcePage};
        },
    },
    {
        path: PlatformPaths.ResumeAuthenticationFlowSource.fullPath,
        lazy: async () => {
            const {AuthenticationFlowSourcePage} = await import('../pages/AuthenticationFlowSourcePage');
            return {Component: AuthenticationFlowSourcePage};
        },
    },
];
