import {API, Resource} from '@coveo/platform-client';
import {
    TrackingIdLocalesOptions,
    TrackingIdLocalesListResponse,
    TrackingIdLocalesModel,
    TrackingIdCatalogCreateBody,
    TrackingIdCatalogUpdateBody,
    TrackingIdToCatalogMappingModel,
    TrackingIdLocalesFlatListResponse,
    TrackingIdLocalesFlatOptions,
} from '../..';
const toLocaleString = ({language, country, currency}: TrackingIdToCatalogMappingModel) =>
    `${language}-${country}-${currency}`;

/**
 * The TrackingIdLocales is part of the Catalog service
 */
export class TrackingIdLocales extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/trackingidcatalogmappings`;

    /**
     * List all trackingIdCatalogMappings
     * @param params TrackingIdLocalesOptions
     * @returns Promise<TrackingIdLocalesListResponse>
     */
    async list(options?: TrackingIdLocalesOptions): Promise<TrackingIdLocalesListResponse> {
        // return this.api.get<TrackingIdLocalesListResponse>(this.buildPath(`${TrackingIdLocales.baseUrl}`, options));

        const response = await this.api.get<TrackingIdLocalesListResponse>(
            this.buildPath(`${TrackingIdLocales.baseUrl}`, options),
        );

        /*
         * Temporary override for the backend response.
         * current: items: [{trackingId: 'a', trackingIdToCatalogMapping: [first]}, {trackingId: 'a', trackingIdToCatalogMapping: [second]}]
         * desired: items: [{trackingId: 'a', trackingIdToCatalogMapping: [first, second]}]
         */

        response.items = response.items
            .reduce<TrackingIdLocalesModel[]>((acc, model) => {
                const existingItem = acc.find((item) => item.trackingId === model.trackingId);
                if (existingItem) {
                    existingItem.trackingIdToCatalogMapping = existingItem.trackingIdToCatalogMapping.concat(
                        model.trackingIdToCatalogMapping,
                    );
                } else {
                    acc.push(model);
                }
                return acc;
            }, [])
            .reduce<TrackingIdLocalesModel[]>((acc, model) => {
                model.trackingIdToCatalogMapping.sort((a, b) =>
                    a.primary ? -1 : b.primary ? 1 : toLocaleString(a) > toLocaleString(b) ? 1 : -1,
                );
                acc.push(model);
                return acc;
            }, []);
        return response;
    }

    /**
     * List all trackingIdCatalogMappings as a flat list.
     * @param params TrackingIdLocalesFlatOptions
     * @returns Promise<TrackingIdLocalesListResponse>
     */
    async flatList(options?: TrackingIdLocalesFlatOptions): Promise<TrackingIdLocalesFlatListResponse> {
        return this.api.get<TrackingIdLocalesFlatListResponse>(
            this.buildPath(`${TrackingIdLocales.baseUrl}/flat`, options),
        );
    }

    /**
     * Create the mapping(s) for a tracking ID
     * @param body TrackingIdCatalogCreateBody
     * @returns Promise<TrackingIdLocalesModel>
     */
    create(body: TrackingIdCatalogCreateBody): Promise<TrackingIdLocalesModel> {
        return this.api.post<TrackingIdLocalesModel>(TrackingIdLocales.baseUrl, body);
    }

    /**
     * Update the mapping(s) for a tracking ID
     * @param body TrackingIdCatalogUpdateBody
     * @returns Promise<TrackingIdLocalesModel>
     */
    update(body: TrackingIdCatalogUpdateBody): Promise<TrackingIdLocalesModel> {
        return this.api.put<TrackingIdLocalesModel>(TrackingIdLocales.baseUrl, body);
    }

    /**
     * Delete the mapping(s) for a tracking ID
     * @param trackingId: string
     * @returns Promise<void>
     */
    delete(trackingId: string): Promise<void> {
        return this.api.delete<void>(this.buildPath(`${TrackingIdLocales.baseUrl}`, {trackingId}));
    }
}
