/**
 * AutoScale extended simple units that auto-scale is supported for.
 */
type NumberAutoScaleCustomUnit = 'bibyte' | 'kibibyte' | 'mebibyte' | 'gibibyte' | 'tebibyte' | 'pebibyte';

/**
 * Intl.NumberFormat sanctioned simple unit identifiers that auto-scale is supported for.
 */
type IntlNumberSimpleAutoScaleUnit =
    | 'bit'
    | 'byte'
    | 'centimeter'
    | 'day'
    | 'foot'
    | 'gigabit'
    | 'gigabyte'
    | 'gram'
    | 'hour'
    | 'inch'
    | 'kilobit'
    | 'kilobyte'
    | 'kilogram'
    | 'kilometer'
    | 'liter'
    | 'megabit'
    | 'megabyte'
    | 'meter'
    | 'mile'
    | 'milliliter'
    | 'millimeter'
    | 'millisecond'
    | 'minute'
    | 'ounce'
    | 'petabyte'
    | 'pound'
    | 'second'
    | 'stone'
    | 'terabit'
    | 'terabyte'
    | 'week'
    | 'yard';

/**
 * Auto scale supporting "simple unit identifiers", may be used as `auto-<auto_unit>` or `auto-<auto_unit>-per-<any_unit>`.
 */
export type NumberAutoScaleSimpleUnit = IntlNumberSimpleAutoScaleUnit | NumberAutoScaleCustomUnit;

/**
 * {@link https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier Intl.NumberFormat sanctioned simple unit identifiers}.
 */
export type IntlNumberSimpleUnit =
    | IntlNumberSimpleAutoScaleUnit
    | 'acre'
    | 'celsius'
    | 'degree'
    | 'fahrenheit'
    | 'fluid-ounce'
    | 'gallon'
    | 'hectare'
    | 'mile-scandinavian'
    | 'month'
    | 'percent'
    | 'year';

/**
 * Signature for all possible auto-scale combinations. Note that not all combinations make sense.
 */
export type NumberAutoScaleFormatAutoUnit =
    | `auto-${NumberAutoScaleSimpleUnit}`
    | `auto-${NumberAutoScaleSimpleUnit}-per-${IntlNumberSimpleUnit}`;
const reAutoUnit =
    /^auto-((?:kilo|mega|giga|tera)?(?:bit|byte)|(?:(?:[kg]i|[mtp]e)?bi|peta)byte|(?:kilo)?gram|(?:ounce|pound|stone)|(?:centi|milli|kilo)?meter|(?:inch|foot|yard|mile)|(?:milli)?liter|(?:(?:milli)?second|minute|hour|day|week|month|year))(-per-[a-z]+(?:-[a-z]+)?|)$/;
export const isNumberAutoScaleFormatAutoUnit = (unit: unknown): unit is NumberAutoScaleFormatAutoUnit =>
    typeof unit === 'string' && reAutoUnit.test(unit);
export const parseNumberAutoScaleFormatAutoUnit: {
    (unit: NumberAutoScaleFormatAutoUnit): [NumberAutoScaleFormatAutoUnit, NumberAutoScaleSimpleUnit, string];
    (
        unit: NumberAutoScaleFormatAutoUnit | undefined,
    ): [NumberAutoScaleFormatAutoUnit, NumberAutoScaleSimpleUnit, string] | null;
} = reAutoUnit.exec.bind(reAutoUnit);

export interface NumberAutoScaleFormatOptions extends Intl.NumberFormatOptions {
    style: 'unit';
    unit: NumberAutoScaleFormatAutoUnit;
}
