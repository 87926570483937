import {ActionIcon, Menu, Tooltip, useClipboard} from '@components/mantine';
import {CheckSize16Px, CopySize16Px} from '@coveord/plasma-react-icons';
import {FunctionComponent, useState} from 'react';

import {LocalesKeys} from '../../../generated/LocalesKeys';
import {Locales} from '../../../strings';
import {useSourceIdentityContext} from '../SourceIdentityContext';

export interface SourceIdentityCopyInfoButtonProps {
    trackCopyInfoButton?: (...args: unknown[]) => void;
}

// @todo: [CTCORE-10031] Enhance PLasma's CopyToClipboard component with an optional drop-down menu
export const SourceIdentityCopyInfoButton: FunctionComponent<SourceIdentityCopyInfoButtonProps> = ({
    trackCopyInfoButton,
}) => {
    const {id, name} = useSourceIdentityContext();
    const [opened, setOpened] = useState(false);
    const [lastCopiedValue, setLastCopiedValue] = useState<'name' | 'id' | null>(null);
    const clipboard = useClipboard({timeout: 2000});

    if (!id || !name) {
        return null;
    }

    return (
        <Menu opened={opened} onChange={setOpened} withinPortal>
            <Menu.Target>
                {clipboard.copied ? (
                    <Tooltip
                        label={Locales.format(
                            `SourceIdentity.CopyInfoButton.tooltip.copied.${lastCopiedValue}` as LocalesKeys,
                        )}
                        opened={clipboard.copied}
                        key="copied-tooltip"
                    >
                        <ActionIcon mih={0} h={16} variant="transparent" color="success">
                            <CheckSize16Px height={16} />
                        </ActionIcon>
                    </Tooltip>
                ) : (
                    <Tooltip label={Locales.format('SourceIdentity.CopyInfoButton.tooltip')} key="hover-tooltip">
                        <ActionIcon
                            mih={0}
                            h={16}
                            variant="transparent"
                            color="action"
                            className="copy-button"
                            data-opened-menu={opened}
                            onClick={(event) => {
                                event.stopPropagation();
                                setOpened(true);
                            }}
                        >
                            <CopySize16Px height={16} />
                        </ActionIcon>
                    </Tooltip>
                )}
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    onClick={(event) => {
                        event.stopPropagation();
                        clipboard.copy(name);
                        setLastCopiedValue('name');
                        trackCopyInfoButton?.('name to clipboard', id);
                    }}
                    p="sm"
                >
                    {Locales.format('SourceIdentity.CopyInfoButton.label.name')}
                </Menu.Item>
                <Menu.Item
                    onClick={(event) => {
                        event.stopPropagation();
                        clipboard.copy(id);
                        setLastCopiedValue('id');
                        trackCopyInfoButton?.('source id to clipboard', id);
                    }}
                    p="sm"
                >
                    {Locales.format('SourceIdentity.CopyInfoButton.label.id')}
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
