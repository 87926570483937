import {QueryClient, QueryClientProvider} from '@core/api';
import {OwnershipProvider} from '@core/debug';
import '@coveord/plasma-style/dist/style.css';
import '@coveord/jsadmin-usage-analytics/stylesheets/style.scss';
import '@coveord/jsadmin-v2/stylesheets/dependencies.scss';
import '@coveord/jsadmin-v2/stylesheets/style.scss';
import '../stylesheets/global.css';
import {FunctionComponent, lazy, PropsWithChildren, StrictMode, Suspense} from 'react';

const ConditionalStrictMode: FunctionComponent<PropsWithChildren> = ({children}) => {
    const strictModeDisabled = import.meta.env.VITE_DANGEROUSLY_DISABLE_STRICT_MODE?.toLowerCase() === 'true';

    return strictModeDisabled ? children : <StrictMode>{children}</StrictMode>;
};

const LazyV2ApplicationStarter = lazy(async () => {
    const module = await import('./V2ApplicationStarter');
    return {default: module.V2ApplicationStarter};
});

const DebugBar = lazy(async () => {
    if (import.meta.env.VITE_USE_DEBUG_BAR?.toLowerCase() !== 'true') {
        return Promise.resolve({default: () => null});
    }

    const module = await import('@components/debug-bar');
    return {default: () => <module.DebugBar withRightOffset />};
});

export const MainApplication: FunctionComponent = () => {
    const queryClient = new QueryClient();

    return (
        <ConditionalStrictMode>
            <QueryClientProvider client={queryClient}>
                <OwnershipProvider defaultOwner="frontend-foundation">
                    <Suspense>
                        <LazyV2ApplicationStarter />
                        <DebugBar />
                    </Suspense>
                </OwnershipProvider>
            </QueryClientProvider>
        </ConditionalStrictMode>
    );
};
