import {Textarea, Title, Transition} from '@components/mantine';
import {FunctionComponent} from 'react';
import {Locales} from '../../strings/Locales';

interface AdditionalInfoSurveyComponentProps {
    mounted: boolean;
    title?: string;
    onTextChange: (value: string) => void;
}

export const AdditionalInfoSurveyComponent: FunctionComponent<AdditionalInfoSurveyComponentProps> = ({
    mounted,
    title,
    onTextChange,
}) => (
    <Transition mounted={mounted} transition="pop" duration={200} timingFunction="ease-in-out">
        {(style) => (
            <Textarea
                pt="xl"
                pb="xs"
                style={style}
                autosize
                size="sm"
                label={
                    <Title order={4} pb="0px">
                        {title ?? Locales.format('UserFeedbackModal.addDetails.title')}
                    </Title>
                }
                onChange={(event) => onTextChange(event.target.value)}
                styles={{input: {minHeight: 100}}}
            />
        )}
    </Transition>
);
