import {Dispatch, SetStateAction} from 'react';
import {createSafeContext, type GetStylesApi} from '@components/mantine';
import {type PageFactory, type PageVariant} from '../components/Page';

export interface PageContextValue {
    variant: PageVariant;
    getStyles: GetStylesApi<PageFactory>;
    pageTitle: string;
    setPageTitle: Dispatch<SetStateAction<string>>;
}

export const [PageProvider, usePageContext] = createSafeContext<PageContextValue>(
    'Page component was not found in the tree',
);
