import {API, Resource} from '@coveo/platform-client';

import {LogicalIndexModel} from '../interfaces';

/**
 * This API is used to manage Coveo's logical indexes.
 *
 * The API is publicly available, but intended for Coveo use (it's not in the public Swagger API).
 *
 * @private
 */
export class LogicalIndexesResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/logicalindexes`;

    list() {
        return this.api.get<LogicalIndexModel[]>(LogicalIndexesResource.baseUrl);
    }
}
