import {Dictionnary, Locales as LocalesGenerator} from '@core/locales';

import {LocalesKeys} from '../generated/LocalesKeys';
import StatusToken from './StatusToken.json';

const req: Record<string, Record<string, any>> = {
    'strings/StatusToken': StatusToken,
};

let translations = {};

Object.keys(req).forEach((key) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(0, parts.length - 2);

    let map: any = translations;

    roots.forEach((root: string) => {
        if (!map[root]) {
            map = {...map, [root]: {}};
        }
        map = map[root];
    });

    translations = {...map, ...req[key]};
});

const enDictionnary: Dictionnary = {
    locale: 'en',
    translations,
};

export const Locales: LocalesGenerator<LocalesKeys> = new LocalesGenerator<LocalesKeys>({
    dictionnaries: [enDictionnary],
    defaultLocale: 'en',
});
