import {SourceCategory, SourceModel, SourceType} from '@core/api';
import {factory, useProps, useStyles, type Factory, type StylesApiProps} from '@components/mantine';
import {ForwardRefExoticComponent, PropsWithChildren, RefAttributes} from 'react';

import {SourceIdentityCategoryIcon, SourceIdentityCategoryIconStylesNames} from './source-identity-category-icon';
import {SourceIdentityCopyInfoButtonProps} from './source-identity-copy-info-button';
import {SourceIdentityName, SourceIdentityNameStylesNames} from './source-identity-name';
import {SourceIdentityTypeImage, SourceIdentityTypeImageStylesNames} from './source-identity-type-image';
import {SourceIdentityTypeLabel, SourceIdentityTypeLabelStylesNames} from './source-identity-type-label';
import {SourceIdentityVisibilityIcon, SourceIdentityVisibilityIconStylesNames} from './source-identity-visibility-icon';
import SourceIdentityClasses from './SourceIdentity.module.css';
import {SourceIdentityBadgeType, SourceIdentityCardVariant} from './SourceIdentityCardVariant';
import {SourceIdentityProvider} from './SourceIdentityContext';
import {SourceIdentityCustomVariant} from './SourceIdentityCustomVariant';
import {SourceIdentityListVariant} from './SourceIdentityListVariant';

export type SourceIdentityStylesNames =
    | 'root'
    | SourceIdentityCategoryIconStylesNames
    | SourceIdentityNameStylesNames
    | SourceIdentityTypeImageStylesNames
    | SourceIdentityTypeLabelStylesNames
    | SourceIdentityVisibilityIconStylesNames;

export type SourceIdentityVariant = 'card' | 'custom' | 'list';

export type SourceIdentityFactory = Factory<{
    props: SourceIdentityProps;
    ref: HTMLDivElement;
    stylesNames: SourceIdentityStylesNames;
    variant: SourceIdentityVariant;
    staticComponents: {
        CategoryIcon: typeof SourceIdentityCategoryIcon;
        Name: typeof SourceIdentityName;
        TypeImage: typeof SourceIdentityTypeImage;
        TypeLabel: typeof SourceIdentityTypeLabel;
        VisibilityIcon: typeof SourceIdentityVisibilityIcon;
        getSourceTypeLabel: (sourceType: SourceType) => string;
    };
}>;

export interface SourceIdentityProps extends StylesApiProps<SourceIdentityFactory>, PropsWithChildren {
    /**
     * The badge to display on the source identity only on card variant.
     */
    badge?: SourceIdentityBadgeType;
    /**
     * The tracking handler for the source identity.
     * Only available for the copyInfoButton in the list variant.
     */
    trackingHandler?: {
        trackCopyInfoButton?: SourceIdentityCopyInfoButtonProps['trackCopyInfoButton'];
    };
    /**
     * The source to display.
     */
    source?: Partial<Pick<SourceModel, 'id' | 'name' | 'sourceType' | 'sourceVisibility'>> & {
        category?: SourceCategory;
    };
    /**
     * The layout of the source identity component.
     *
     * @default 'custom'
     */
    variant?: SourceIdentityVariant;
}

const defaultProps: Partial<SourceIdentityProps> = {
    variant: 'custom',
};

const sourceIdentityVariants: Record<
    SourceIdentityVariant,
    ForwardRefExoticComponent<SourceIdentityProps & RefAttributes<HTMLDivElement>>
> = {
    card: SourceIdentityCardVariant,
    custom: SourceIdentityCustomVariant,
    list: SourceIdentityListVariant,
};

/**
 * The SourceIdentity component is used to display the identity of a source and its various subcomponents.
 *
 * @see the storybook examples for more details.
 */
export const SourceIdentity = factory<SourceIdentityFactory>((props, ref) => {
    const {badge, classNames, source, styles, unstyled, variant, vars, ...others} = useProps(
        'SourceIdentity',
        defaultProps,
        props,
    );
    const getStyles = useStyles<SourceIdentityFactory>({
        name: 'SourceIdentity',
        classes: SourceIdentityClasses,
        props,
        classNames,
        styles,
        unstyled,
        vars,
    });

    const currentVariant = variant ?? 'custom';
    const SourceIdentityComponent = sourceIdentityVariants[currentVariant] ?? null;

    return (
        <SourceIdentityProvider
            value={{
                badge,
                variant: currentVariant,
                sourceType: SourceType.CUSTOM,
                ...source,
                getStyles,
            }}
        >
            <SourceIdentityComponent ref={ref} data-variant={variant} {...others} />
        </SourceIdentityProvider>
    );
});
SourceIdentity.displayName = '@resourceIdentity/source/SourceIdentity';
SourceIdentity.CategoryIcon = SourceIdentityCategoryIcon;
SourceIdentity.Name = SourceIdentityName;
SourceIdentity.TypeImage = SourceIdentityTypeImage;
SourceIdentity.TypeLabel = SourceIdentityTypeLabel;
SourceIdentity.VisibilityIcon = SourceIdentityVisibilityIcon;
