import {Box, Button, Header, Modal, StickyFooter} from '@components/mantine';
import {Config} from '@core/configuration';
import {FunctionComponent} from 'react';
import {Locales} from '../../../strings';

interface WhatsNewModalProps {
    url?: string;
    isOpen: boolean;
    onClose: () => void;
}

export const WhatsNewModal: FunctionComponent<WhatsNewModalProps> = ({url, isOpen, onClose}) => (
    <Modal.Root owner="frontend-foundation" framework="Mantine" opened={isOpen} onClose={onClose}>
        <Modal.Overlay />
        <Modal.Content h="100%">
            <Modal.Header>
                <Modal.Title>
                    <Header variant="secondary">{Locales.format('NotificationsMenu.whatsNewQuestion')}</Header>
                </Modal.Title>
                <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body h="100%">
                <Box h="inherit">
                    {/* embedded=1 removes unnecessary style */}
                    <iframe
                        aria-label="whats-new-iframe"
                        className="full-content"
                        src={`${url || Config.WhatsNew.url}?embedded=1`}
                    />
                </Box>
            </Modal.Body>
            <StickyFooter borderTop>
                <Button variant="outline" onClick={onClose}>
                    {Locales.format('NotificationsMenu.closeModal')}
                </Button>
            </StickyFooter>
        </Modal.Content>
    </Modal.Root>
);
