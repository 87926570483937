import {Anchor, Box, Code, Header, Stack, Text, Title} from '@components/mantine';
import {isDevOrLocalEnvironment} from '@core/configuration';
import {Translation} from '@core/locales';
import {useRouteError} from '@core/routes';
import {FunctionComponent} from 'react';

import {Locales} from '../../strings';

interface ErrorContentProps {
    variant?: 'internal-page' | 'external-page';
}

export const ErrorContent: FunctionComponent<ErrorContentProps> = ({variant}) => {
    const error = useRouteError() as Error;

    if (variant === 'external-page') {
        return (
            <Box>
                <Title order={1}>{Locales.format('error.header')}</Title>
                <Stack px="xl">
                    {isDevOrLocalEnvironment() ? (
                        <>
                            <Title order={3}>{error.message || Locales.format('error.dev.title')}</Title>
                            <Text size="sm">{Locales.format('error.dev.message')}</Text>
                            <Code>{error.stack}</Code>
                        </>
                    ) : (
                        <>
                            <Title order={3}>{Locales.format('error.prod.title')}</Title>
                            <Text size="sm">
                                <Translation t={Locales} i18nKey="error.prod.message">
                                    <Anchor href="https://support.coveo.com/s/" />
                                </Translation>
                            </Text>
                        </>
                    )}
                </Stack>
            </Box>
        );
    }

    return (
        <>
            <Header>{Locales.format('error.header')}</Header>
            <Stack px="xl">
                {isDevOrLocalEnvironment() ? (
                    <>
                        <Title order={3}>{error.message || Locales.format('error.dev.title')}</Title>
                        <Text size="sm">{Locales.format('error.dev.message')}</Text>
                        <Code>{error.stack}</Code>
                    </>
                ) : (
                    <>
                        <Title order={3}>{Locales.format('error.prod.title')}</Title>
                        <Text size="sm">
                            <Translation t={Locales} i18nKey="error.prod.message">
                                <Anchor href="https://support.coveo.com/s/" />
                            </Translation>
                        </Text>
                    </>
                )}
            </Stack>
        </>
    );
};
