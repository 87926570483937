import {
    Box,
    BoxProps,
    MantineSize,
    Text,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
} from '@components/mantine';

import {Locales} from '../../../strings';
import {useSourceIdentityContext} from '../SourceIdentityContext';

export type SourceIdentityTypeLabelSize = MantineSize;
export type SourceIdentityTypeLabelStylesNames = 'typeLabel';
export type SourceIdentityTypeLabelFactory = Factory<{
    props: SourceIdentityTypeLabelProps;
    ref: HTMLDivElement;
    stylesNames: SourceIdentityTypeLabelStylesNames;
    compound: true;
}>;

export interface SourceIdentityTypeLabelProps extends BoxProps, CompoundStylesApiProps<SourceIdentityTypeLabelFactory> {
    /**
     * The text size of the type to display.
     *
     * @default: 'md'
     */
    size?: SourceIdentityTypeLabelSize;
}

const defaultProps: Partial<SourceIdentityTypeLabelProps> = {
    size: 'md',
};

export const SourceIdentityTypeLabel = factory<SourceIdentityTypeLabelFactory>((props, ref) => {
    const {size, ...others} = useProps('SourceIdentityTypeLabel', defaultProps, props);
    const {getStyles, sourceType} = useSourceIdentityContext();

    return (
        <Box ref={ref} {...getStyles('typeLabel')} {...others} flex={1}>
            <Text size={size}>{Locales.format(`SourceIdentity.sourceType.${sourceType}.label`)}</Text>
        </Box>
    );
});
SourceIdentityTypeLabel.displayName = '@resourceIdentity/source/SourceIdentity/TypeLabel';
