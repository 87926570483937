import {track as amplitudeTrack} from '@amplitude/analytics-browser';
import {Scope, captureMessage} from '@sentry/react';
import {identify} from './identify';

const availableAction = [
    'accepted',
    'added',
    'applied',
    'cancelled',
    'checked',
    'cleared',
    'clicked',
    'closed',
    'completed',
    'copied',
    'deleted',
    'disabled',
    'enabled',
    'expanded',
    'loaded',
    'logout',
    'modified',
    'opened',
    'performed',
    'played',
    'received',
    'removed',
    'saved',
    'selected',
    'shared',
    'submitted',
    'switched',
    'unchecked',
    'viewed',
] as const;
export type ActionType = (typeof availableAction)[number];
type TrackData = Record<string, any>;
type TrackEvent = {
    action: ActionType;
    subject: string;
};

/**
 * Track customer actions.
 *
 * @param {TrackEvent|string} event
 * @param {TrackData} [data]
 *
 * @example ```typescript
 *   import {track} from '@core/tracking';
 *
 *   const trackComponent = (facetFields: ContentBrowserFacetFields) => {
 *     track('deleted content browser facet badge', {facet: facetFields});
 *   };
 *
 * @example ```typescript
 *   import {track} from '@core/tracking';
 *
 *   const trackComponent = (facetFields: ContentBrowserFacetFields) => {
 *     track({action: 'deleted', subject: 'content browser facet badge'}, {facet: facetFields});
 *   };
 * ```
 */
export const track = (event: TrackEvent | string, data: TrackData = {}): void => {
    identify();

    const eventInput = typeof event === 'object' ? `${event.action} ${event.subject}` : event;

    let alertSentry = false;
    let actionInput;
    if (typeof event === 'object' && !availableAction.includes(event.action)) {
        alertSentry = true;
        actionInput = event.action;
    }
    if (typeof event === 'string') {
        const [action] = event.split(' ');
        if (!availableAction.includes(action as ActionType)) {
            alertSentry = true;
            actionInput = action;
        }
    }

    if (alertSentry) {
        const scope = new Scope();
        scope.setTags({
            package: '@core/tracking',
            method: 'track',
        });
        scope.setLevel('warning');
        scope.setContext('tracking', {
            event: eventInput,
            data,
        });
        captureMessage(
            `[@core/tracking] The "${actionInput}" action is not available in the list of usable actions "${availableAction.join(
                ',',
            )}".`,
            scope,
        );
    }

    amplitudeTrack(eventInput, data);
};
