import {Locales} from '../strings/Locales';

// Use the language as default format locale; this is one of the possible choices,
// other options are using undefined (user default) or introducing a user setting.
export const formatLocale: Intl.LocalesArgument = Locales.locale;

export const missingCompact = Locales.format('notApplicableCompact');
export const missingFull = Locales.format('notApplicableFull');

export const resolveMissing = (compact: boolean): string => (compact ? missingCompact : missingFull);
