import {Prompt, Text} from '@components/mantine';
import {FunctionComponent} from 'react';

import {Locales} from '../strings';

interface UnsavedChangesPromptProps {
    isOpened: boolean;
    allowNavigation: () => void;
    cancelNavigation: () => void;
}

export const UnsavedChangesPrompt: FunctionComponent<UnsavedChangesPromptProps> = ({
    isOpened,
    allowNavigation,
    cancelNavigation,
}) => (
    <Prompt
        zIndex={1000}
        opened={isOpened}
        onClose={cancelNavigation}
        title={Locales.format('UnsavedChangesPrompt.title')}
        centered
        variant="warning"
        size="sm"
    >
        <Text>{Locales.format('UnsavedChangesPrompt.description')}</Text>
        <Prompt.Footer>
            <Prompt.CancelButton onClick={cancelNavigation} data-testid="unsaved-changes-modal-cancel-button">
                {Locales.format('UnsavedChangesPrompt.footer.cancelButton')}
            </Prompt.CancelButton>
            <Prompt.ConfirmButton onClick={allowNavigation} data-testid="unsaved-changes-modal-quit-button">
                {Locales.format('UnsavedChangesPrompt.footer.quitButton')}
            </Prompt.ConfirmButton>
        </Prompt.Footer>
    </Prompt>
);
