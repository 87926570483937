import {LicenseModel, ProductName, ProductType} from '@core/api';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

const isTrial = ({productName, productType}: LicenseModel): boolean =>
    productName === ProductName.COVEO_CLOUD && productType === ProductType.TRIAL;

const isTrialExpired = (license: LicenseModel) => {
    const today = dayjs();
    return LicenseHelper.isTrial(license) && dayjs(license.expirationDate).isSameOrBefore(today);
};

export const LicenseHelper = {
    /**
     * Determines whether the given license model is a trial.
     * @param license the LicenseModel
     * @returns `true` if the license is a trial, `false` otherwise.
     */
    isTrial,
    /**
     * Determines whether the giver license model is an expired trial.
     * @param license the LicenseModel
     * @returns `true` if the license is an expired trial, `false` otherwise.
     */
    isTrialExpired,
};
