import {BoxProps, Factory, factory, Input, StylesApiProps, Text, useProps, useStyles} from '@coveord/plasma-mantine';
import {ReactNode} from 'react';

export type ViewFieldWrapperStylesNames = 'root';
export type ViewFieldWrapperFactory = Factory<{
    props: ViewFieldWrapperProps;
    ref: HTMLDivElement;
    stylesNames: ViewFieldWrapperStylesNames;
}>;

export type ViewFieldWrapperProps = BoxProps &
    StylesApiProps<ViewFieldWrapperFactory> & {
        /**
         * The label of the field.
         */
        label: ReactNode;
        /**
         * The value to render
         */
        value: unknown;
        /**
         * Custom renderer for field in readOnly mode
         */
        readOnlyFormatter?: <T extends any>(value: T) => ReactNode;
    };

const renderValue = (value: unknown) => {
    if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
        return <Text c="dimmed">N/A</Text>;
    } else if (typeof value === 'string') {
        return <Text c="gray.7">{value}</Text>;
    } else if (Array.isArray(value) && value.every((v) => typeof v === 'string')) {
        return (
            <>
                {value.map((val) => (
                    <Text key={val} c="gray.7">
                        {val}
                    </Text>
                ))}
            </>
        );
    } else {
        throw 'Not implemented';
    }
};

const defaultProps: Partial<ViewFieldWrapperProps> = {
    readOnlyFormatter: renderValue,
};

export const ValueWithLabel = factory<ViewFieldWrapperFactory>((_props, ref) => {
    const {classNames, styles, style, className, vars, readOnlyFormatter, value, label, ...others} = useProps(
        'ViewFieldWrapper',
        defaultProps,
        _props,
    );
    const getStyles = useStyles<ViewFieldWrapperFactory>({
        name: 'ViewFieldWrapper',
        classes: {},
        vars,
        classNames,
        className,
        style,
        props: _props,
        styles,
    });

    return (
        <Input.Wrapper
            ref={ref}
            label={label}
            {...others}
            {...getStyles('root', {style, styles, className, classNames})}
        >
            {readOnlyFormatter(value)}
        </Input.Wrapper>
    );
});
