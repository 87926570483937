import {CurrentOrganization} from '@core/organization';

const lastSeenProject = 'lastSeenProject';

const getProjectId = (): string | null => {
    const orgId = CurrentOrganization.getId();
    return localStorage.getItem(`${lastSeenProject}_${orgId}`) ?? null;
};

const setProjectId = (projectId: string): void => {
    const orgId = CurrentOrganization.getId();
    localStorage.setItem(`${lastSeenProject}_${orgId}`, projectId);
};

export const ProjectLocalStorage = {
    getProjectId,
    setProjectId,
};
