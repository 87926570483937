import {ContextType, useContext} from 'react';
import {NavigationContext} from '../context/NavigationContext';

/**
 * Provides information about the collapsed state of Navigation.SideBar and allows to toggle it.
 */
export const useNavigation = (): ContextType<typeof NavigationContext> => {
    const context = useContext(NavigationContext);
    if (context == null) {
        throw new Error('useNavigation can only be used within the Navigation component');
    }
    return context;
};
