import {BlockerFunction, matchPath, useBlocker} from '@core/routes';
import {
    Dispatch,
    FunctionComponent,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react';
import {flushSync} from 'react-dom';

import {UnsavedChangesPrompt} from '../components/UnsavedChangesPrompt';

type SetShouldDisplayBlockingPromptMethod = (
    newState: boolean,
    options?: {
        flushSync?: boolean;
    },
) => void;

type NavigationBlockerContextType = {
    setShouldDisplayBlockingPrompt: SetShouldDisplayBlockingPromptMethod;
    setAllowedNavigationRoutes: Dispatch<SetStateAction<string[]>>;
    setOnConfirm?: Dispatch<SetStateAction<() => () => void>>;
};

const NavigationBlockerContext = createContext<NavigationBlockerContextType | null>(null);

interface NavigationBlockerProviderProps {
    initialAllowedNavigationRoutes?: string[];
}

export const NavigationBlockerProvider: FunctionComponent<PropsWithChildren<NavigationBlockerProviderProps>> = ({
    children,
    initialAllowedNavigationRoutes = [],
}) => {
    const [shouldDisplayBlockingPrompt, _setShouldDisplayBlockingPrompt] = useState(false);
    const setShouldDisplayBlockingPrompt = useCallback<SetShouldDisplayBlockingPromptMethod>(
        (newState: boolean, options) => {
            if (!!options?.flushSync) {
                flushSync(() => {
                    _setShouldDisplayBlockingPrompt(newState);
                });
            } else {
                _setShouldDisplayBlockingPrompt(newState);
            }
        },
        [],
    );
    const [allowedNavigationRoutes, setAllowedNavigationRoutes] = useState(initialAllowedNavigationRoutes);
    const [onConfirm, setOnConfirm] = useState<() => () => void | null>(null);

    const shouldBlock = useCallback<BlockerFunction>(
        ({currentLocation, nextLocation}) => {
            if (currentLocation.pathname === nextLocation.pathname) {
                return false;
            }

            const isMatch = allowedNavigationRoutes?.some((path: string) => matchPath(path, nextLocation.pathname));

            return shouldDisplayBlockingPrompt && !isMatch;
        },
        [shouldDisplayBlockingPrompt],
    );
    const blocker = useBlocker(shouldBlock);

    const allowNavigation = useCallback(() => {
        if (blocker.state === 'blocked') {
            onConfirm?.();
            setShouldDisplayBlockingPrompt(false);
            blocker.proceed();
        }
    }, [onConfirm, blocker.state]);
    const cancelNavigation = useCallback(() => {
        if (blocker.state === 'blocked') {
            blocker.reset();
        }
    }, [blocker.state]);

    return (
        <NavigationBlockerContext.Provider
            value={{setShouldDisplayBlockingPrompt, setAllowedNavigationRoutes, setOnConfirm}}
        >
            <UnsavedChangesPrompt
                isOpened={blocker.state === 'blocked'}
                allowNavigation={allowNavigation}
                cancelNavigation={cancelNavigation}
            />
            {children}
        </NavigationBlockerContext.Provider>
    );
};

export const useBlockingPrompt = () => useContext<NavigationBlockerContextType>(NavigationBlockerContext);
