import {GenericError} from '@core/api';
import {track} from '@core/tracking';
import {NotificationData} from '@coveord/plasma-mantine';

const errorShown = (data: NotificationData & GenericError): void => {
    track('received error', data);
};

export const NotificationTracking = {
    errorShown,
};
