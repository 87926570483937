import {createSafeContext} from '@components/mantine';
import {ListSourcesParams} from '@core/api';
import {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';

export interface SourcesListParamsContextType {
    listParams: ListSourcesParams | null;
    clearFilters: () => void;
    setListParams: Dispatch<SetStateAction<ListSourcesParams>>;
}

const [Provider, useSourcesListParams] = createSafeContext<SourcesListParamsContextType>(
    'SourcesListParamsProvider component not found in tree',
);

const SourcesListParamsProvider = ({children}: {children: ReactNode}) => {
    const [listParams, setListParams] = useState<ListSourcesParams | null>(null);
    const clearFilters = () => setListParams(null);

    useEffect(() => {
        document.addEventListener('CLEAR_SOURCES_LIST_FILTERS', clearFilters);

        return () => {
            document.removeEventListener('CLEAR_SOURCES_LIST_FILTERS', clearFilters);
        };
    }, []);

    return <Provider value={{listParams, clearFilters, setListParams}}>{children}</Provider>;
};

export {SourcesListParamsProvider, useSourcesListParams};
