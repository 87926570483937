const propertyLimits = {
    sources: 'numberOfSourcesLimit',
    groups: 'numberOfGroupsLimit',
    models: 'numberOfModelsLimit',
    apiKeys: 'numberOfApiKeysLimit',
    queryPipelines: 'maxPipelinesLimit',
    fields: 'numberOfFieldsLimit',
    extensions: 'licenseExtensionsLimit',
    customDimensions: 'customDimensionLimit',
    catalogs: 'catalogLimit',
    projects: 'numberOfProjectsLimit',
};

const ITDEnable = 'intelligentTermDetectionEnabled';
const SITECORE_LICENSES = ['SitecoreCloudStandardEdition', 'SitecoreCloudStandardEditionTrial'];
const isIpxEnabled = 'ipxEnabled';

export const LicenseConstants = {
    propertyLimits,
    ITDEnable,
    SITECORE_LICENSES,
    isIpxEnabled,
};
