import {Group, Input, Kbd, spotlight, Text} from '@components/mantine';
import {SearchSize16Px} from '@coveord/plasma-react-icons';
import {os} from 'platform';
import {KeyboardEventHandler} from 'react';

import {Locales} from '../../strings/Locales';
import {SearchTracking} from '../../tracking';
import classes from './NavigationSearchControl.module.css';

export const NavigationSearchControl = () => {
    const isUsingAppleProduct = os.family === 'OS X' || os.family === 'iOS';

    const openSearch = () => {
        spotlight.open();
        SearchTracking.trackView();
    };

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        switch (event.key) {
            case 'Enter':
                openSearch();
                break;
            default:
                break;
        }
    };

    return (
        <Input
            placeholder={Locales.format('Search.label')}
            type="search"
            aria-label={Locales.format('Search.label')}
            aria-keyshortcuts={isUsingAppleProduct ? 'Meta+K' : 'Control+K'}
            leftSection={<SearchSize16Px height={16} />}
            tabIndex={-1}
            classNames={classes}
            onFocus={(event) => event.currentTarget.blur()}
            rightSection={
                <Group gap={4} mr="xs">
                    <Kbd size="xs" c="gray.5">
                        {isUsingAppleProduct ? '⌘' : 'Ctrl'}
                    </Kbd>
                    <Text span>+</Text>
                    <Kbd size="xs" c="gray.5">
                        K
                    </Kbd>
                </Group>
            }
            rightSectionWidth={isUsingAppleProduct ? 58 : 76}
            onKeyDown={onKeyDown}
            onClick={openSearch}
        />
    );
};
