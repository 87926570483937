import {Prompt, Text} from '@components/mantine';
import {FunctionComponent} from 'react';
import {Locales} from '../../../strings/Locales';
import {useAccessTableContext} from '../AccessTable.context';

export interface LoseAccessPromptProps {
    /**
     * Whether the prompt is opened
     */
    opened: boolean;
    /**
     * Called when the user wants to cancel the last modification that caused the prompt to open
     */
    onCancellation: () => void;
    /**
     * Called when the user confirms the loss of edit access
     */
    onLoseAccessConfirmation: () => void;
}

export const LoseAccessPrompt: FunctionComponent<LoseAccessPromptProps> = ({
    onCancellation,
    opened,
    onLoseAccessConfirmation,
}) => {
    const {targetDomain} = useAccessTableContext();

    return (
        <Prompt
            variant="warning"
            opened={opened}
            title={Locales.format('GroupsAccessTable.LoseAccessPrompt.title')}
            onClose={onCancellation}
            centered
        >
            <Text size="sm" pb="sm">
                {Locales.format('GroupsAccessTable.LoseAccessPrompt.description', {
                    resourceNameSingular: Locales.formatOrDefault(`PrivilegesRows.${targetDomain}.granular`, {
                        defaultTranslation: targetDomain,
                    }).toLowerCase(),
                })}
            </Text>

            <Prompt.Footer>
                <Prompt.CancelButton onClick={onCancellation}>
                    {Locales.format('GroupsAccessTable.LoseAccessPrompt.UndoChange')}
                </Prompt.CancelButton>
                <Prompt.ConfirmButton onClick={onLoseAccessConfirmation}>
                    {Locales.format('GroupsAccessTable.LoseAccessPrompt.LoseEditorAccess')}
                </Prompt.ConfirmButton>
            </Prompt.Footer>
        </Prompt>
    );
};
