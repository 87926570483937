import {Badge, Box, Group, MantineColor, Stack} from '@components/mantine';
import {forwardRef} from 'react';

import {Locales} from '../../strings';
import {SourceIdentityCategoryIcon} from './source-identity-category-icon';
import {SourceIdentityTypeImage} from './source-identity-type-image';
import {SourceIdentityTypeLabel} from './source-identity-type-label';
import {SourceIdentityProps} from './SourceIdentity';
import {useSourceIdentityContext} from './SourceIdentityContext';

export type SourceIdentityBadgeType = 'BETA' | 'DEPRECATED' | 'NEW' | 'RECOMMENDED';

const BADGE_COLOR_MAPPINGS: Record<SourceIdentityBadgeType, MantineColor> = {
    BETA: 'critical.3',
    DEPRECATED: 'warning',
    NEW: 'new',
    RECOMMENDED: 'green',
};

export const SourceIdentityCardVariant = forwardRef<HTMLDivElement, SourceIdentityProps>(
    ({children: _children, trackingHandler: _trackingHandler, ...props}, ref) => {
        const {badge, getStyles} = useSourceIdentityContext();

        return (
            <Box ref={ref} flex={1} {...getStyles('root')} {...props}>
                <Group justify="space-between" wrap="nowrap" h={40}>
                    <Group gap="sm" wrap="nowrap">
                        <SourceIdentityTypeImage size="xxl" />

                        <Stack gap={2}>
                            {badge && (
                                <Badge
                                    color={BADGE_COLOR_MAPPINGS[badge]}
                                    variant="light"
                                    tt="none"
                                    size="lg"
                                    radius="md"
                                >
                                    {Locales.format(`SourceIdentity.Badge.${badge}`)}
                                </Badge>
                            )}

                            <SourceIdentityTypeLabel size="lg" />
                        </Stack>
                    </Group>

                    <SourceIdentityCategoryIcon size="lg" />
                </Group>
            </Box>
        );
    },
);
SourceIdentityCardVariant.displayName = '@resourceIdentity/source/SourceIdentity/CardVariant';
