import {track} from '@core/tracking';
import {SurveyEventType} from 'src/interfaces';

const trackView = (surveyId: string, surveyType: SurveyEventType, surveyTitle: string): void => {
    track(
        {action: 'viewed', subject: 'user feedback'},
        {
            surveyId,
            surveyType,
            surveyTitle,
        },
    );
};

interface TrackClickSubmitParams {
    numberOfStars: number;
    surveyId: string;
    surveyType: string;
    surveyTitle: string;
    additionalInfo?: string;
    email: string | null;
}

const trackClickSubmit = (params: TrackClickSubmitParams): void => {
    track({action: 'clicked', subject: 'user feedback submit'}, params);
};

const trackClickSkip = (surveyId: string, surveyType: SurveyEventType, surveyTitle: string): void => {
    track(
        {action: 'clicked', subject: 'user feedback skip'},
        {
            surveyId,
            surveyType,
            surveyTitle,
        },
    );
};

const trackClickDontAskAgain = (surveyId: string, surveyType: SurveyEventType, surveyTitle: string): void => {
    track(
        {action: 'clicked', subject: "user feedback don't ask again"},
        {
            surveyId,
            surveyType,
            surveyTitle,
        },
    );
};

export const UserFeedbackTracking = {
    trackView,
    trackClickSubmit,
    trackClickSkip,
    trackClickDontAskAgain,
};
