import {Config} from '@core/configuration';
import {
    AnchorFactory,
    AnchorProps,
    Anchor as MantineAnchor,
    PolymorphicComponentProps,
    polymorphicFactory,
} from '@coveord/plasma-mantine';
import {useMemo} from 'react';

export const Anchor: ReturnType<typeof polymorphicFactory<AnchorFactory>> = polymorphicFactory<AnchorFactory>(
    (props, ref) => {
        const rest = props as PolymorphicComponentProps<AnchorFactory['defaultComponent'], AnchorProps>;
        const externalLink = useMemo(() => {
            if (!rest?.href) {
                return false;
            }
            let url: URL;
            try {
                url = new URL(rest.href);
            } catch {
                return false;
            }
            return `${url.protocol}//${url.hostname}` !== Config.CloudPlatform.url;
        }, [rest?.href]);

        const anchorProps = externalLink ? {...rest, target: '_blank', rel: 'noopener noreferrer'} : rest;

        return <MantineAnchor ref={ref} {...anchorProps} />;
    },
);
