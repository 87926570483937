import {API, Resource} from '@coveo/platform-client';
import {
    PreviewResultModel,
    ListingRulePreviewRequestModel,
    RulePreviewRequestOptions,
    RulePreviewRequestOptionsWithComparePreviousState,
    RulePreviewRequestModelWithoutRule,
} from '../../interfaces';

export class ListingRulePreview extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/listing/preview/rules`;

    /**
     * Preview the impact of creating a listing rule on a config.
     * @param rulePreviewRequest
     * @param options
     * @returns The products that would be returned.
     */
    create(rulePreviewRequest: ListingRulePreviewRequestModel, options: RulePreviewRequestOptions = {}) {
        return this.api.post<PreviewResultModel>(
            this.buildPath(ListingRulePreview.baseUrl, options),
            rulePreviewRequest,
        );
    }

    /**
     * Preview the impact of an updated listing rule vs not having the rule,
     * or if `compareWithPreviousState` is true, the impact of a rule update
     * vs the previous state of the rule.
     * @param ruleId
     * @param rulePreviewRequest
     * @param options
     * @returns The products that would be returned.
     */
    update(
        ruleId: string,
        rulePreviewRequest: ListingRulePreviewRequestModel,
        options: RulePreviewRequestOptionsWithComparePreviousState = {},
    ) {
        return this.api.put<PreviewResultModel>(
            this.buildPath(`${ListingRulePreview.baseUrl}/${ruleId}`, options),
            rulePreviewRequest,
        );
    }

    /**
     * Preview the impact of a listing rule.
     * @param ruleId
     * @param rulePreviewRequest
     * @param options
     * @returns The products that would be returned.
     */
    get(
        ruleId: string,
        rulePreviewRequest: RulePreviewRequestModelWithoutRule<ListingRulePreviewRequestModel>,
        options: RulePreviewRequestOptions = {},
    ) {
        return this.api.get<PreviewResultModel>(
            this.buildPath(`${ListingRulePreview.baseUrl}/${ruleId}`, {...rulePreviewRequest, ...options}),
        );
    }
}
