import {FormatValueType, MissingFormatOptions, MissingValue, NumberAutoScaleFormatOptions} from '../api';
import {MissingFormatRange} from './internal/MissingFormat';
import {createNumberFormat, NumberAutoScaleFormat} from './internal/NumberAutoScaleFormat';

/**
 * Proxy for {@link Intl.NumberFormat} that formats `null` and `undefined` as missing values.
 */
export class NumberMissingFormat extends MissingFormatRange<
    Exclude<FormatValueType<Intl.NumberFormat>, MissingValue>,
    Intl.ResolvedNumberFormatOptions,
    Intl.NumberFormatPart,
    Intl.NumberRangeFormatPart
> {
    /**
     * Create a new `NumberMissingFormat` from an existing `Intl.NumberFormat` instance.
     *
     * @param formatter The existing `Intl.NumberFormat` instance.
     * @param options Optional {@link MissingFormatOptions}.
     */
    constructor(formatter: Intl.NumberFormat, options?: MissingFormatOptions);
    /**
     * Create a special `NumberMissingFormat` that handles "auto scale".
     *
     * @param locales Optional locales argument, use `undefined` to select the user's current locale.
     * @param options Optional options, which are combined {@link Intl.NumberFormatOptions} and {@link MissingFormatOptions}.
     */
    constructor(
        locales: Intl.LocalesArgument | undefined,
        options: NumberAutoScaleFormatOptions & MissingFormatOptions,
    );
    /**
     * Create a new `NumberMissingFormat` wrapping a new `Intl.NumberFormat` instance.
     *
     * @param locales Optional locales argument, use `undefined` to select the user's current locale.
     * @param options Optional options, which are combined {@link Intl.NumberFormatOptions} and {@link MissingFormatOptions}.
     */
    constructor(locales?: Intl.LocalesArgument, options?: Intl.NumberFormatOptions & MissingFormatOptions);
    constructor(
        localesOrFormat?: Intl.LocalesArgument | Intl.NumberFormat,
        options?: Intl.NumberFormatOptions & MissingFormatOptions,
    ) {
        super(
            localesOrFormat instanceof Intl.NumberFormat || localesOrFormat instanceof NumberAutoScaleFormat
                ? localesOrFormat
                : createNumberFormat(localesOrFormat, options),
            options,
        );
    }
}
