import {API, PrivilegeModel, Resource} from '@coveo/platform-client';

export class PrivilegesResource extends Resource {
    static baseUrl = '/rest/organizations';

    getGlobalGroupPrivileges() {
        return this.api.get<PrivilegeModel[]>('/rest/globalprivileges');
    }

    getUserPrivileges() {
        return this.api.get<PrivilegeModel[]>(`${PrivilegesResource.baseUrl}/${API.orgPlaceholder}/members/privileges`);
    }
}
