import {API, Resource} from '@coveo/platform-client';
import {RulesListModel, SearchRulesListOptions} from '../../interfaces';

export class SearchRules extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/configurations/search/rules`;

    async list(options: SearchRulesListOptions): Promise<RulesListModel> {
        return this.api.get<RulesListModel>(this.buildPath(`${SearchRules.baseUrl}`, options));
    }
}
