/**
 * Missing values are `null` or `undefined`
 */
export type MissingValue = null | undefined;

/**
 * Check if a value is considered to represent a missing value, which means that it is `null` or `undefined`.
 *
 * @param value The value to check.
 * @returns Whether the value is `null` or `undefined`.
 */
export const isMissingValue = (value: unknown): value is MissingValue => value === null || value === undefined;

/**
 * The options of the "missing" format wrapper.
 */
export interface MissingFormatOptions {
    /**
     * The string to return when formatting a missing (`null` || `undefined`) value.
     * Defaults to an empty string (`''`).
     */
    missing?: string;
}

/**
 * The resolved options of the "missing" format wrapper.
 */
export interface ResolvedMissingFormatOptions {
    /**
     * The string used to represent missing values.
     */
    missing: string;
}

/**
 * The parts a missing formatter can return.
 */
export interface MissingFormatPart {
    /** The only supported type is 'missing', for the part that encodes the missing value. */
    type: 'missing';
    /** The textual representation of the part. */
    value: string;
}
