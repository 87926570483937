import {
    Box,
    BoxProps,
    Group,
    Text,
    Tooltip,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
    type MantineSize,
} from '@components/mantine';
import {SourceVisibility} from '@core/api';
import {Translation} from '@core/locales';
import {
    AccessPrivateSize16Px,
    AccessPrivateSize24Px,
    AccessPrivateSize32Px,
    UnlockSize16Px,
    UnlockSize24Px,
    UnlockSize32Px,
    UserSize16Px,
    UserSize24Px,
    UserSize32Px,
    type Icon,
} from '@coveord/plasma-react-icons';
import {PropsWithChildren} from 'react';

import {LocalesKeys} from '../../../generated/LocalesKeys';
import {Locales} from '../../../strings';
import {useSourceIdentityContext} from '../SourceIdentityContext';

type SourceIdentityVisibilityIconSize = Extract<MantineSize, 'sm' | 'md' | 'lg'>;
const SOURCE_VISIBILITY_IMAGE_MAPPINGS: Record<SourceIdentityVisibilityIconSize, Record<SourceVisibility, Icon>> = {
    sm: {
        [SourceVisibility.PRIVATE]: UserSize16Px,
        [SourceVisibility.SECURED]: AccessPrivateSize16Px,
        [SourceVisibility.SHARED]: UnlockSize16Px,
    },
    md: {
        [SourceVisibility.PRIVATE]: UserSize24Px,
        [SourceVisibility.SECURED]: AccessPrivateSize24Px,
        [SourceVisibility.SHARED]: UnlockSize24Px,
    },
    lg: {
        [SourceVisibility.PRIVATE]: UserSize32Px,
        [SourceVisibility.SECURED]: AccessPrivateSize32Px,
        [SourceVisibility.SHARED]: UnlockSize32Px,
    },
};

export type SourceIdentityVisibilityIconStylesNames = 'visibilityIcon';
export type SourceIdentityVisibilityIconFactory = Factory<{
    props: SourceIdentityVisibilityIconProps;
    ref: HTMLDivElement;
    stylesNames: SourceIdentityVisibilityIconStylesNames;
    compound: true;
}>;

export interface SourceIdentityVisibilityIconProps
    extends BoxProps,
        PropsWithChildren,
        CompoundStylesApiProps<SourceIdentityVisibilityIconFactory> {
    /**
     * If true, the visibility icon will be disabled.
     *
     * @default: false
     */
    disabled?: boolean;
    /**
     * Whether to show a tooltip with the visibility icon's description.
     *
     * @default: false
     */
    showTooltip?: boolean;
    /**
     * The icon size of the visibility to display.
     *
     * @default: 'md'
     */
    size: SourceIdentityVisibilityIconSize;
}

const defaultProps: Partial<SourceIdentityVisibilityIconProps> = {
    disabled: false,
    showTooltip: false,
    size: 'md',
};

export const SourceIdentityVisibilityIcon = factory<SourceIdentityVisibilityIconFactory>((props, ref) => {
    const {children, disabled, showTooltip, size, ...others} = useProps(
        'SourceIdentityVisibilityIcon',
        defaultProps,
        props,
    );
    const {getStyles, sourceVisibility} = useSourceIdentityContext();

    if (!sourceVisibility) {
        return null;
    }

    const IconComponent = SOURCE_VISIBILITY_IMAGE_MAPPINGS[size][sourceVisibility];
    const renderedIconComponent = <IconComponent data-disabled={disabled} />;
    const renderedIconComponentWithChildren = (
        <Group align="self-start" gap="xs">
            {children}
            {renderedIconComponent}
        </Group>
    );

    return (
        <Tooltip
            label={
                <Translation
                    t={Locales}
                    i18nKey={`SourceIdentity.VisibilityIcon.${sourceVisibility}.tooltip` as LocalesKeys}
                >
                    <div />
                    <Text fw={500} />
                </Translation>
            }
            disabled={!showTooltip}
        >
            <Box ref={ref} data-size={size} {...getStyles('visibilityIcon')} {...others}>
                {children ? renderedIconComponentWithChildren : renderedIconComponent}
            </Box>
        </Tooltip>
    );
});
SourceIdentityVisibilityIcon.displayName = '@resourceIdentity/source/SourceIdentity/VisibilityIcon';
