import {Box, StatusToken, StatusTokenVariant, UnstyledButton} from '@components/mantine';
import {BellSize24Px} from '@coveord/plasma-react-icons';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import classes from './NotificationsMenu.module.css';

interface NotificationsButtonProps extends ComponentPropsWithoutRef<'button'> {
    statusVariant?: StatusTokenVariant;
}

export const NotificationsButton = forwardRef<HTMLButtonElement, NotificationsButtonProps>(
    ({statusVariant, ...others}: NotificationsButtonProps, ref) => (
        <UnstyledButton w={72} ref={ref} {...others}>
            <Box pos="relative" display="inline-block">
                <BellSize24Px height={24} />
                {statusVariant && <StatusToken variant={statusVariant} size="lg" className={classes.statusToken} />}
            </Box>
        </UnstyledButton>
    ),
);
