import {
    Box,
    type ColumnDef,
    createColumnHelper,
    type Factory,
    factory,
    type StylesApiProps,
    Table,
    type TableProps,
    Tooltip,
    useDisclosure,
    useProps,
    useStyles,
    useTable,
    useUncontrolled,
} from '@components/mantine';
import {AccessLevel, GroupAccessModel, IdAndDisplayNameModel, Platform, useQuery} from '@core/api';
import {CheckSize16Px, CrossSize16Px} from '@coveord/plasma-react-icons';
import {FunctionComponent, useMemo, useState} from 'react';
import {Locales} from '../../../strings/Locales';
import {AccessTableContextProvider, useAccessTableContext} from '../AccessTable.context';
import {AccessTableProps, AccessTableStylesNames} from '../AccessTable.types';
import {AccessTableAccessLevelCell} from '../AccessTableAccessLevelCell';
import {AccessTableUtils} from '../AccessTableUtils';
import classes from './GroupsAccessTable.module.css';
import {GroupsAccessTableUtils} from './GroupsAccessTableUtils';
import {LoseAccessPrompt} from './LoseAccessPrompt';
import {AccessTableConstants} from '../AccessTableConstants';

export type GroupsAccessTableStylesNames =
    | AccessTableStylesNames
    | 'membershipTooltip'
    | 'iconMember'
    | 'iconNotMember';

export interface GroupsAccessTableProps
    extends AccessTableProps<GroupAccessModel>,
        Omit<TableProps<GroupAccessModel>, 'data' | 'columns' | 'store' | 'classNames' | 'styles' | 'vars'>,
        StylesApiProps<GroupsAccessTableFactory> {
    /**
     * Whether the user can edit all resources of type `resource`
     */
    canEditAll: boolean;
}

export type GroupsAccessTableFactory = Factory<{
    props: GroupsAccessTableProps;
    ref: HTMLDivElement;
    stylesNames: GroupsAccessTableStylesNames;
    compound: true;
}>;

const defaultProps: Partial<GroupsAccessTableProps> = {};

export const GroupsAccessTable = factory<GroupsAccessTableFactory>((_props, ref) => {
    const {
        resourceId,
        privilegeTargetDomain: targetDomain,
        privilegeOwner: owner,
        onChange,
        onInitialization,
        data,
        value,
        defaultValue,
        canEditAll,
        loading,
        classNames,
        styles,
        vars,
        className,
        style,
        readOnly,
        ...others
    } = useProps('GroupsAccessTable', defaultProps, _props);

    const [_value, handleChange, isControlled] = useUncontrolled({
        value,
        defaultValue,
        finalValue: null,
        onChange,
    });

    const [previousSelection, setPreviousSelection] = useState<IdAndDisplayNameModel[]>(_value);
    const [isLoseAccessAcknowledged, setIsLoseAccessAcknowledged] = useState(false);
    const [isLoseAccessPromptOpened, {open: openLoseAccessPrompt, close: closeLoseAccessPrompt}] = useDisclosure(false);

    const table = useTable<GroupAccessModel>({
        initialState: {
            totalEntries: isControlled && data ? data.length : undefined,
        },
    });

    const query = useQuery({
        enabled: !isControlled,
        queryKey: ['access', 'groups', {targetDomain, owner}],
        queryFn: async () => {
            try {
                const accessModels = (await Platform.organizationAccess.getGroups({
                    accessLevel: [AccessLevel.CUSTOM, AccessLevel.VIEW_ALL, AccessLevel.NONE, AccessLevel.EDIT_ALL],
                    privilegeOwner: owner,
                    privilegeTargetDomain: targetDomain,
                })) as GroupAccessModel[];
                table.setTotalEntries(accessModels.length);

                const accessModelsUpdatedByValue = AccessTableUtils.overrideAccessModelsWithValue(
                    accessModels,
                    _value,
                    resourceId,
                );
                const initialAccessModels = GroupsAccessTableUtils.preventGroupLockout(
                    accessModelsUpdatedByValue,
                    resourceId,
                );
                const initialValue = AccessTableUtils.serializeAccessModels(initialAccessModels, resourceId);
                internalOnChange(initialValue, initialAccessModels);
                onInitialization?.(initialValue);
                return accessModels;
            } catch (error) {
                console.error(error);
                return [];
            }
        },
    });

    const rows = useMemo(() => {
        const accessModels = isControlled ? data : query.data;
        return accessModels ? AccessTableUtils.sortModels(accessModels) : undefined;
    }, [isControlled, data, query.data]);

    const internalOnChange = (newValue: IdAndDisplayNameModel[], accessModels = rows) => {
        const wasPartOfGroupThatCanEdit = GroupsAccessTableUtils.isPartOfGroupThatCanEdit(accessModels, resourceId);
        const currentData = AccessTableUtils.overrideAccessModelsWithValue(accessModels, newValue, resourceId);
        const isPartOfGroupThatCanEdit = GroupsAccessTableUtils.isPartOfGroupThatCanEdit(currentData, resourceId);
        if (isPartOfGroupThatCanEdit) {
            // If the user is part of a group that can edit, we don't need to show the prompt
            // We can assume the current state as a safe for the user, and make sure he can come back to it
            setPreviousSelection(newValue);
            setIsLoseAccessAcknowledged(false);
        }
        if (
            !readOnly &&
            !canEditAll &&
            !isPartOfGroupThatCanEdit &&
            !isLoseAccessAcknowledged &&
            wasPartOfGroupThatCanEdit
        ) {
            openLoseAccessPrompt();
        }
        if (newValue?.length > 0) {
            handleChange(newValue.sort(AccessTableUtils.sortIdNameModels));
        } else {
            handleChange(AccessTableConstants.noAccess);
        }
    };

    const getStyles = useStyles<GroupsAccessTableFactory>({
        name: 'GroupsAccessTable',
        classes,
        vars,
        classNames,
        className,
        style,
        props: _props,
        styles,
    });

    return (
        <AccessTableContextProvider
            value={{getStyles, targetDomain, value: _value, onChange: internalOnChange, readOnly}}
        >
            <Table<GroupAccessModel>
                ref={ref}
                store={table}
                data={rows}
                loading={isControlled ? loading : query.isLoading || _value === null}
                getRowId={(row) => row.id}
                columns={columns}
                {...others}
            />
            <LoseAccessPrompt
                opened={isLoseAccessPromptOpened}
                onCancellation={() => {
                    internalOnChange(previousSelection);
                    closeLoseAccessPrompt();
                }}
                onLoseAccessConfirmation={() => {
                    setIsLoseAccessAcknowledged(true);
                    closeLoseAccessPrompt();
                }}
            />
        </AccessTableContextProvider>
    );
});

const columnHelper = createColumnHelper<GroupAccessModel>();
const columns: Array<ColumnDef<GroupAccessModel>> = [
    columnHelper.accessor('displayName', {
        header: Locales.format('GroupsAccessTable.headerLabel.group'),
        enableSorting: false,
    }),
    columnHelper.accessor('callerPartOf', {
        header: Locales.format('GroupsAccessTable.headerLabel.membership'),
        cell: (info) => <MembershipCell callerPartOf={info.getValue()} />,
        enableSorting: false,
    }),
    columnHelper.accessor('accessLevel', {
        header: Locales.format('AccessTable.headerLabel.accessLevel'),
        enableSorting: false,
        cell: (info) => <AccessTableAccessLevelCell {...info.row.original} />,
    }),
];

const MembershipCell: FunctionComponent<Pick<GroupAccessModel, 'callerPartOf'>> = ({callerPartOf}) => {
    const {getStyles} = useAccessTableContext();
    return (
        <Tooltip
            label={Locales.format(
                callerPartOf
                    ? 'GroupsAccessTable.tooltip.callerPartOf_yes'
                    : 'GroupsAccessTable.tooltip.callerPartOf_no',
            )}
            position="top-start"
            {...getStyles('membershipTooltip')}
        >
            <Box>
                {callerPartOf ? (
                    <CheckSize16Px height={16} className={classes.iconMember} {...getStyles('iconMember')} />
                ) : (
                    <CrossSize16Px height={16} className={classes.iconNotMember} {...getStyles('iconNotMember')} />
                )}
            </Box>
        </Tooltip>
    );
};
