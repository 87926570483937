import {
    Box,
    BoxProps,
    Container,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
} from '@components/mantine';
import {forwardRef, type ForwardRefExoticComponent, type ReactNode, type RefAttributes} from 'react';
import {usePageContext} from '../contexts';
import {type PageVariant} from './Page';

export type PageBodyStylesNames = 'body' | 'bodyContainer';

export interface PageBodyProps extends BoxProps, CompoundStylesApiProps<PageBodyFactory> {
    children: ReactNode;
}

export type PageBodyFactory = Factory<{
    props: PageBodyProps;
    ref: HTMLDivElement;
    stylesNames: PageBodyStylesNames;
    compound: true;
}>;

const defaultProps: Partial<PageBodyProps> = {};

const FullPageBody = forwardRef<HTMLDivElement, PageBodyProps>((props, ref) => <Box ref={ref} flex={1} {...props} />);

const CenteredPageBody = forwardRef<HTMLDivElement, PageBodyProps>(({children, ...others}, ref) => {
    const {getStyles} = usePageContext();
    return (
        <Box ref={ref} flex={1} {...others}>
            <Container w="100%" {...getStyles('bodyContainer')}>
                {children}
            </Container>
        </Box>
    );
});

const pageBodyVariants: Record<
    PageVariant,
    ForwardRefExoticComponent<PageBodyProps & RefAttributes<HTMLDivElement>>
> = {
    full: FullPageBody,
    centered: CenteredPageBody,
};

export const PageBody = factory<PageBodyFactory>((_props, ref) => {
    const {variant, getStyles} = usePageContext();
    const {className, style, classNames, styles, ...others} = useProps('PageBody', defaultProps, _props);
    const PageBodyComponent = pageBodyVariants[variant] ?? FullPageBody;

    return <PageBodyComponent ref={ref} {...others} {...getStyles('body', {className, style, classNames, styles})} />;
});
