import {
    factory,
    Factory,
    Popover,
    PopoverProps,
    PopoverStylesNames,
    StylesApiProps,
    useProps,
} from '@components/mantine';
import {HelpWidgetTracker} from '../../tracking';

export type HelpWidgetStylesNames = PopoverStylesNames;

export interface HelpWidgetProps
    extends Omit<PopoverProps, 'styles' | 'classNames' | 'vars'>,
        StylesApiProps<HelpWidgetFactory> {}

export type HelpWidgetFactory = Factory<{
    props: HelpWidgetProps;
    ref: HTMLDivElement;
    stylesNames: HelpWidgetStylesNames;
}>;

export const HelpWidget = factory<HelpWidgetFactory>((_props, ref) => {
    const {classNames, styles, vars, children, ...others} = useProps('HelpWidget', {}, _props);

    return (
        <Popover {...others} onOpen={HelpWidgetTracker.trackOpenWidget} onClose={HelpWidgetTracker.trackCloseWidget}>
            <Popover.Target>{children}</Popover.Target>
            <Popover.Dropdown p={0}>
                <div id="SearchDocumentationWidget" />
            </Popover.Dropdown>
        </Popover>
    );
});
