import {ComboboxStore, createSafeContext, GetStylesApi} from '@components/mantine';
import {Region} from '@core/api';
import {Dispatch, SetStateAction} from 'react';

import {OrganizationPickerFactory} from './OrganizationPicker';

export interface OrganizationPickerContextType {
    getStyles: GetStylesApi<OrganizationPickerFactory>;
    filter: string;
    setFilter: Dispatch<SetStateAction<string>>;
    region: Region;
    setRegion: Dispatch<SetStateAction<Region>>;
    combobox: ComboboxStore;
}

export const [OrganizationPickerProvider, useOrganizationPicker] = createSafeContext<OrganizationPickerContextType>(
    'OrganizationPicker component was not found in the tree',
);
