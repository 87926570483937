import {PageModel, Resource} from '@coveo/platform-client';
import {
    AvailablePipelineSetting,
    BulkGetPipelineSettingsParams,
    CopyPipelineSettingsRequest,
    CopyPipelineSettingsResponse,
    ListAvailablePipelineSettingsParams,
    ListPipelineSettingsParams,
    MoveSettingRequest,
    PipelineSettingCreateRequest,
    PipelineSettingResponse,
    PipelineSettingUpdateRequest,
} from '../interfaces';

/**
 * API used to manager pipeline settings
 *
 * This API is documented in the internal Swagger documentation: https://platformdev.cloud.coveo.com/docs/internal?urls.primaryName=Search%20API#/Settings
 *
 * @private
 */
export class PipelineSettingsResource extends Resource {
    static baseUrl = `/rest/search/v2/admin/pipelines`;

    /**
     * List available query pipeline settings.
     *
     * @returns {Promise<PageModel<AvailablePipelineSettings>>} The list of available query pipeline settings.
     */
    listAvailableSettings(params?: ListAvailablePipelineSettingsParams): Promise<PageModel<AvailablePipelineSetting>> {
        return this.api.get<PageModel<AvailablePipelineSetting>>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/settings/metadata`, {
                ...params,
                organizationId: this.api.organizationId,
            }),
        );
    }

    /**
     * List settings for a specific pipeline.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {ListPipelineSettingsParams} params A set of parameters to customize the results.
     *
     * @returns {Promise<PageModel<PipelineSettingResponse>>} The list of query pipeline settings.
     */
    list(pipelineId: string, params?: ListPipelineSettingsParams): Promise<PageModel<PipelineSettingResponse>> {
        return this.api.get<PageModel<PipelineSettingResponse>>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings`, {
                ...params,
                organizationId: this.api.organizationId,
            }),
        );
    }

    /**
     * Read a setting for a specific pipeline.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {string} settingsId The unique identifier of the query pipeline setting.
     *
     * @returns {Promise<PipelineSettingResponse>} The query pipeline setting.
     */
    get(pipelineId: string, settingsId: string): Promise<PipelineSettingResponse> {
        return this.api.get<PipelineSettingResponse>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/${settingsId}`, {
                organizationId: this.api.organizationId,
            }),
        );
    }

    /**
     * Create a setting for a specific pipeline.
     *
     * @param {string}pipelineId The unique identifier of the target query pipeline.
     *
     * @returns {Promise<PipelineSettingResponse>} The created query pipeline setting.
     */
    create(pipelineId: string, request: PipelineSettingCreateRequest): Promise<PipelineSettingResponse> {
        return this.api.post<PipelineSettingResponse>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings`, {
                organizationId: this.api.organizationId,
            }),
            request,
        );
    }

    /**
     * Update a setting for a specific pipeline.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {string} settingsId The unique identifier of the query pipeline setting.
     *
     * @returns {Promise<PipelineSettingResponse>} The updated query pipeline setting.
     */
    update(
        pipelineId: string,
        settingsId: string,
        request: PipelineSettingUpdateRequest,
    ): Promise<PipelineSettingResponse> {
        return this.api.put<PipelineSettingResponse>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/${settingsId}`, {
                organizationId: this.api.organizationId,
            }),
            request,
        );
    }

    /**
     * Delete a setting.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {string} settingsId The unique identifier of the query pipeline setting.
     *
     * @returns {Promise<void>}
     */
    delete(pipelineId: string, settingsId: string): Promise<void> {
        return this.api.delete(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/${settingsId}`, {
                organizationId: this.api.organizationId,
            }),
        );
    }

    /**
     * Bulk delete settings.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {string[]} ids The query pipeline settings to delete.
     */
    bulkDelete(pipelineId: string, ids: string[]): Promise<void> {
        return this.api.post(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/bulkDelete`, {
                organizationId: this.api.organizationId,
            }),
            {ids},
        );
    }

    /**
     * Bulk get settings for a specific pipeline.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {BulkGetPipelineSettingsParams} params A set of parameters to customize the results.
     *
     * @returns The list of query pipeline settings.
     */
    bulkGet(
        pipelineId: string,
        {ids, ...queryStringOptions}: BulkGetPipelineSettingsParams,
    ): Promise<PageModel<PipelineSettingResponse>> {
        return this.api.post<PageModel<PipelineSettingResponse>>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/bulkGet`, {
                organizationId: this.api.organizationId,
                ...queryStringOptions,
            }),
            {ids},
        );
    }

    /**
     * Copies specific settings from an origin to a target query pipeline. Using the same pipeline as origin and target will duplicate the specified settings in that pipeline.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {CopyPipelineSettingsRequest} request The information about the settings to copy.
     *
     * @returns {Promise<CopyPipelineSettingsResponse>} The copied query pipeline settings.
     */
    copy(pipelineId: string, request: CopyPipelineSettingsRequest): Promise<CopyPipelineSettingsResponse> {
        return this.api.post<CopyPipelineSettingsResponse>(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/copy`, {
                organizationId: this.api.organizationId,
            }),
            request,
        );
    }

    /**
     * Move a setting.
     *
     * @param {string} pipelineId The unique identifier of the target query pipeline.
     * @param {string} settingId The unique identifier of the query pipeline setting.
     * @param {MoveSettingRequest} request The information about the setting to move.
     *
     * @returns {Promise<void>}
     */
    move(pipelineId: string, settingId: string, request: MoveSettingRequest): Promise<void> {
        return this.api.put(
            this.buildPath(`${PipelineSettingsResource.baseUrl}/${pipelineId}/settings/${settingId}/move`, {
                organizationId: this.api.organizationId,
            }),
            request,
        );
    }
}
