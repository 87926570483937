import {SourceStatusTypeWithTransition} from '@core/api';
import {StatusConstants} from './status';

const getOrgStatusInfo = (status: Record<string, any>) => {
    if (!status?.provisioningStatus?.initialProvisioningDone && status?.provisioningStatus?.ongoing) {
        return StatusConstants.OrgStatusInfoType.CREATION;
    } else if (status?.pauseState === SourceStatusTypeWithTransition.RESUMING) {
        return StatusConstants.OrgStatusInfoType.RESUMING;
    } else if (status?.paused) {
        return StatusConstants.OrgStatusInfoType.PAUSED;
    } else if (status?.readOnly) {
        return StatusConstants.OrgStatusInfoType.READONLY;
    } else if (status?.status === StatusConstants.ClusterStatuses.Error) {
        return StatusConstants.OrgStatusInfoType[status?.errorCodes?.[0]] ?? StatusConstants.OrgStatusInfoType.ERROR;
    }
    return StatusConstants.OrgStatusInfoType.HEALTHY;
};

export const NotificationsMenuUtils = {
    getOrgStatusInfo,
};
