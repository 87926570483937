import {RouteObject} from '@core/routes';
import {sourceAuthenticationFlowRoutes} from '@pages/source';

import {ExternalLayout} from '../components';
import {ExternalErrorPage, ExternalNotFoundPage} from '../pages';

export const authenticationFlowRoutes: RouteObject[] = [
    {
        path: '/',
        element: <ExternalLayout />,
        errorElement: <ExternalErrorPage />,
        children: [
            ...sourceAuthenticationFlowRoutes,
            {path: '*', element: <ExternalNotFoundPage />},
            {path: '', element: <ExternalNotFoundPage />},
        ],
    },
];
