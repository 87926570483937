import {Box, Tooltip} from '@components/mantine';
import {PrivilegeUtils} from '@core/user';
import {AttentionSize16Px, CircleArrowBottomSize16Px, Icon} from '@coveord/plasma-react-icons';
import {FunctionComponent} from 'react';
import {Locales} from '../../strings/Locales';
import {PrivilegeRow, PrivilegesTableWarningProps, PrivilegeWarningType} from './PrivilegesTable.types';
import {PrivilegesTableUtils} from './PrivilegesTableUtils';
import {usePrivilegeRow} from './PrivilegeTableContext';

const privilegeWarningIconMapping: Record<PrivilegeWarningType, Icon> = {
    REDUCTION: CircleArrowBottomSize16Px,
    LOCKOUT: AttentionSize16Px,
    POTENT: AttentionSize16Px,
};

const PrivilegesTableWarning: FunctionComponent<PrivilegesTableWarningProps> = ({type, affectedPrivileges}) => (
    <Tooltip
        label={Locales.format(`PrivilegesTable.warning.tooltip.${type}`, {
            formattedPrivilegesPassive: PrivilegeUtils.toSentence(affectedPrivileges),
            formattedPrivilegesActive: PrivilegeUtils.toSentence(affectedPrivileges, true),
            smart_count: affectedPrivileges?.length ?? 0,
        })}
    >
        <Box ml="xs" c="warning" component={privilegeWarningIconMapping[type]} height={16} />
    </Tooltip>
);

export const PrivilegesTableWarnings: FunctionComponent<PrivilegeRow> = (row) => {
    const {selectedPrivileges, readOnly} = usePrivilegeRow(row.id);
    if (readOnly) {
        return null;
    }
    const warnings = PrivilegesTableUtils.getWarnings({
        selectedPrivileges,
        initialPrivileges: row.initialPrivileges,
        availablePrivileges: row.availablePrivileges,
        exclusivePrivileges: row.exclusivePrivileges,
    });
    return warnings.map((warning) => <PrivilegesTableWarning key={warning.type} {...warning} />);
};
