import {ComboboxData, Select} from '@components/mantine';
import {FunctionComponent} from 'react';
import {Locales} from '../../../strings/Locales';
import {usePrivilegeRow} from '../PrivilegeTableContext';
import {PrivilegesTableUtils} from '../PrivilegesTableUtils';

export interface GranularAccessLevelPickerProps {
    owner: string;
    targetDomain: string;
    targetId: string;
}

const options: ComboboxData = [
    {value: 'EDIT', label: Locales.format('AccessLevelPicker.option.EDIT')},
    {value: 'VIEW', label: Locales.format('AccessLevelPicker.option.VIEW')},
];

export const GranularAccessLevelPicker: FunctionComponent<GranularAccessLevelPickerProps> = ({
    owner,
    targetDomain,
    targetId,
}) => {
    const id = PrivilegesTableUtils.getRowId({owner, targetDomain, targetId});
    const {onChange, selectedPrivileges, readOnly} = usePrivilegeRow(id);
    const value = selectedPrivileges.some((privilege) => privilege.type === 'EDIT' && privilege.targetId === targetId)
        ? 'EDIT'
        : 'VIEW';

    return (
        <Select
            data={options}
            value={value}
            w={148}
            readOnly={readOnly}
            onChange={(newValue) => {
                if (newValue === 'VIEW') {
                    onChange(selectedPrivileges.filter((privilege) => privilege.targetId !== targetId));
                } else {
                    onChange([...selectedPrivileges, {type: 'EDIT', targetId, owner, targetDomain}]);
                }
            }}
        />
    );
};
