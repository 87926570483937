import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {FormatWithResolvedOptions, MissingFormatOptions} from '../api';
import {MissingFormatWithOptions} from './internal';

// required for `fromNow` to work
dayjs.extend(relativeTime);

export interface RelativeTimeResolvedFormatOptions {
    locale: string;
}

export class DayjsRelativeTimeFormat
    implements FormatWithResolvedOptions<dayjs.ConfigType, RelativeTimeResolvedFormatOptions>
{
    format(value: dayjs.ConfigType): string {
        return dayjs(value).fromNow();
    }

    resolvedOptions(): RelativeTimeResolvedFormatOptions {
        return {locale: dayjs.locale()};
    }
}

export class DayjsRelativeTimeMissingFormat extends MissingFormatWithOptions<
    dayjs.ConfigType,
    RelativeTimeResolvedFormatOptions
> {
    constructor(options: MissingFormatOptions) {
        super(new DayjsRelativeTimeFormat(), options);
    }
}
