import {API, Resource} from '@coveo/platform-client';
import {CatalogSearchRequestModel, CatalogSearchResponseModel} from '../../interfaces/commerce/CatalogSearchInterface';

export class CatalogSearch extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/catalogSearch`;

    /**
     * Returns products linked to a user query on the defined catalog.
     */
    query(options: CatalogSearchRequestModel) {
        return this.api.post<CatalogSearchResponseModel>(this.buildPath(CatalogSearch.baseUrl), options);
    }
}
