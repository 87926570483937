import {useContext, useEffect, useState} from 'react';

import {Framework, Ownership, OwnershipContext} from '../contexts';
import {Team} from '../data';

export const useOwnership = (owner?: Team, framework?: Framework | Framework[], invisible?: boolean): Ownership => {
    const context = useContext(OwnershipContext);
    if (!context) {
        throw new Error('useOwnership must be used within an OwnershipProvider');
    }

    const [prev] = useState<Ownership>({
        framework: context?.framework ?? null,
        owner: context?.owner ?? null,
    });

    useEffect(() => {
        if (!invisible && (framework || owner)) {
            context?.update({framework: framework ?? null, owner: owner ?? null});
        }
    }, [framework, owner, invisible]);

    // When the component unmounts, restore the previous page
    useEffect(() => () => context?.update(prev), []);

    return {framework: context?.framework ?? null, owner: context?.owner ?? null};
};
