import {GlobalPrivilegeModel, PrivilegeModel} from '@core/api';
import _ from 'underscore';
import {slugify} from 'underscore.string';

import {Locales} from '../../strings/Locales';
import {
    PrivilegeLevels,
    PrivilegesConstants,
    PrivilegesListMap,
    PrivilegeTypes,
} from '../constants/PrivilegesConstants';
import {Arrays, ArraysOfObjects} from './ArraysUtils';

const formatPassive = (privileges: PrivilegeModel[]): string => {
    const targetDomain = privileges[0].targetDomain;
    const formattedPrivilegeNoun = Locales.format('privilege', {smart_count: privileges.length});
    const formattedTargetDomain = Locales.formatOrDefault(`${targetDomain}_DisplayName`, {
        defaultTranslation: targetDomain,
        reportMissingKey: true,
    });
    const privilegeOnDomain = `${formattedPrivilegeNoun} ${Locales.format('on')} ${formattedTargetDomain}`;

    if (privileges.length === 1) {
        return privileges[0].type === PrivilegeTypes.Allow
            ? `${formattedTargetDomain} ${formattedPrivilegeNoun}`
            : `${Locales.formatOrHumanize(privileges[0].type ?? '')} ${privilegeOnDomain}`;
    } else {
        const types = _.chain(privileges)
            .pluck('type')
            .map((type: string) => Locales.formatOrHumanize(type))
            .value();

        return `${Arrays.enumerate(types)} ${privilegeOnDomain}`;
    }
};

const formatActive = (privileges: PrivilegeModel[]): string => {
    const targetDomain = privileges[0].targetDomain;
    const formattedTargetDomain = Locales.formatOrDefault(`${targetDomain}_DisplayName`, {
        defaultTranslation: targetDomain,
        reportMissingKey: true,
    }).toLowerCase();

    if (privileges.length === 1) {
        return privileges[0].type === PrivilegeTypes.Allow
            ? `${formattedTargetDomain}`
            : `${Locales.formatOrHumanize(privileges[0].type ?? '').toLowerCase()} ${formattedTargetDomain}`;
    } else {
        const types = _.chain(privileges)
            .pluck('type')
            .map((type: string) => Locales.formatOrHumanize(type).toLowerCase())
            .value();

        return `${Arrays.enumerate(types)} ${formattedTargetDomain}`;
    }
};

const isGlobalPrivilege = (privilege: PrivilegeModel | GlobalPrivilegeModel): privilege is GlobalPrivilegeModel =>
    privilege && Object.prototype.hasOwnProperty.call(privilege, 'level');

const parseBinaryPrivileges = (privilege: PrivilegeModel): PrivilegeModel =>
    _.isUndefined(privilege.type) ? {...privilege, type: PrivilegeTypes.Allow} : privilege;

const getPrivilegeDomain = (privilege: PrivilegeModel | GlobalPrivilegeModel): string => {
    const level = isGlobalPrivilege(privilege) ? privilege.level : PrivilegeLevels.Normal;
    return privilege ? `${privilege.targetDomain}_${privilege.owner}_${level}` : '';
};

const getPrivilegeService = (privilege: PrivilegeModel): string => slugify(getFormattedService(privilege));

const getFormattedService = (privilege: PrivilegeModel | GlobalPrivilegeModel): string => {
    if (privilege) {
        let global = '';

        if (isGlobalPrivilege(privilege)) {
            global = privilege.level !== PrivilegeLevels.Normal ? '_GLOBAL' : '';
        }
        const ownerKey: string = `${privilege.owner}${global}_Service`;

        return Locales.formatOrDefault(`${privilege.targetDomain}_${ownerKey}`, {
            defaultTranslation: Locales.formatOrHumanize(ownerKey, {
                defaultTranslation: privilege.owner,
                reportMissingKey: true,
            }),
        });
    }
    return '';
};

/**
 * @param active Formats the privileges into an active formulated sentence rather than a passive formulation
 * Example of active formulation: "*create, edit, and view sources*"
 * Example of passive formulation: "*Create, Edit, View privileges on Sources*"
 */
const toSentence = (privileges: PrivilegeModel[], active?: boolean): string =>
    _.isEmpty(privileges)
        ? ''
        : _.compose(
              Arrays.enumerate,
              (privilegesByDomain: PrivilegesListMap) =>
                  _.map(privilegesByDomain, active ? formatActive : formatPassive),
              groupByDomain,
              (dirtyPrivileges: PrivilegeModel[]) => _.map(dirtyPrivileges, parseBinaryPrivileges),
          )(privileges);

const groupById = <T>(list: T[]): Record<string, T[]> => _.groupBy(list, 'id');

const groupByService = (privileges: PrivilegeModel[]): PrivilegesListMap => _.groupBy(privileges, getPrivilegeService);

const groupByDomain = (privileges: PrivilegeModel[]): PrivilegesListMap =>
    _.mapObject(_.groupBy(privileges, getPrivilegeDomain), (domainPrivileges: PrivilegeModel[]) =>
        _.sortBy(domainPrivileges, 'type'),
    );

const groupByTargetId = (privileges: PrivilegeModel[]): PrivilegesListMap =>
    _.groupBy(_.filter(privileges, isGranularEdit), 'targetId');

const hasCreatePrivilege = (privileges: PrivilegeModel[], basePrivilege: PrivilegeModel): boolean =>
    _.any(privileges, {...basePrivilege, type: PrivilegeTypes.Create, targetId: PrivilegesConstants.allTargetIds});

const hasValidGranularPrivileges = (privileges: PrivilegeModel[]): boolean => {
    const granularPrivilegesGroupedByDomain = _.groupBy(_.filter(privileges, isGranularPrivilege), 'targetDomain');
    return (
        !_.isEmpty(granularPrivilegesGroupedByDomain) &&
        _.every(Object.keys(granularPrivilegesGroupedByDomain), (domain: string): boolean => {
            const {owner, targetDomain} = granularPrivilegesGroupedByDomain[domain][0];
            return hasPrivilege(privileges, {
                targetDomain,
                owner,
                type: PrivilegeTypes.View,
                targetId: PrivilegesConstants.allTargetIds,
            });
        })
    );
};

const isGranularPrivilege = (privilege: PrivilegeModel): boolean =>
    isGranularEdit(privilege) && privilege.targetId !== PrivilegesConstants.allTargetIds;

const isGranularDomain = (domain: string): boolean => _.contains(_.values(PrivilegesConstants.granularDomains), domain);

const isGranularEdit = (privilege: PrivilegeModel): boolean =>
    !!privilege && privilege.type === PrivilegeTypes.Edit && !_.isEmpty(privilege.targetId);

const hasPrivilege = (
    listOfPrivileges: PrivilegeModel[] = [],
    privilegeToLookFor: PrivilegeModel | GlobalPrivilegeModel,
): boolean =>
    _.any(listOfPrivileges, {...privilegeToLookFor, targetId: PrivilegesConstants.allTargetIds}) ||
    _.any(listOfPrivileges, privilegeToLookFor);

/**
 * This privilege validation is referred as weak, because it doesn't check the targetId
 */
const hasPrivilege_weak = (listOfPrivileges: PrivilegeModel[] = [], privilegeToLookFor: PrivilegeModel): boolean =>
    !!_.findWhere(listOfPrivileges, _.pick(privilegeToLookFor, 'owner', 'targetDomain', 'type'));

const hasAllPrivileges = (
    listOfPrivileges: PrivilegeModel[] = [],
    privilegesToLookFor: PrivilegeModel[] = [],
): boolean =>
    _.isEmpty(privilegesToLookFor) ||
    _.every(privilegesToLookFor, (privilege: PrivilegeModel) => hasPrivilege(listOfPrivileges, privilege));

const hasAllPrivileges_weak = (
    listOfPrivileges: PrivilegeModel[] = [],
    privilegesToLookFor: PrivilegeModel[] = [],
): boolean =>
    _.isEmpty(privilegesToLookFor) ||
    _.every(privilegesToLookFor, (privilege: PrivilegeModel) =>
        privilege.type === PrivilegeTypes.Edit
            ? hasPrivilege_weak(listOfPrivileges, privilege)
            : hasPrivilege(listOfPrivileges, privilege),
    );

const hasSomePrivileges = (
    listOfPrivileges: PrivilegeModel[] = [],
    privilegesToLookFor: PrivilegeModel[] = [],
): boolean =>
    _.isEmpty(privilegesToLookFor) ||
    _.some(privilegesToLookFor, (privilege: PrivilegeModel) => hasPrivilege(listOfPrivileges, privilege));

const listsAreEqual = (list1: PrivilegeModel[], list2: PrivilegeModel[]): boolean =>
    _.isArray(list1) &&
    _.isArray(list2) &&
    list1.length === list2.length &&
    ArraysOfObjects.difference(list1, list2).length === 0 &&
    ArraysOfObjects.difference(list2, list1).length === 0;

export const PrivilegeUtils = {
    isGlobalPrivilege,
    isGranularPrivilege,
    parseBinaryPrivileges,
    getPrivilegeDomain,
    getPrivilegeService,
    toSentence,
    listsAreEqual,
    hasSomePrivileges,
    hasPrivilege_weak,
    hasAllPrivileges_weak,
    hasAllPrivileges,
    isGranularDomain,
    hasValidGranularPrivileges,
    hasCreatePrivilege,
    groupByService,
    groupById,
    groupByTargetId,
    groupByDomain,
    hasPrivilege,
    getFormattedService,
};
