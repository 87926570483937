import {Config} from '@core/configuration';
import Registry from '@core/registry';
import {PlatformClient, PlatformClientOptions, Resource} from '@coveo/platform-client';

import {
    ActivitiesInternal,
    Commerce,
    DataServiceResource,
    DocumentIdentityResource,
    IPXInterfaceExperimentalResource,
    LogicalIndexesResource,
    OrganizationDefinitionResource,
    OrganizationMonitoringResource,
    OrganizationSettingsResource,
    PipelineSettingsResource,
    PreviewResource,
    PrivilegesResource,
    ProjectInternal,
    SchemaServiceTemporaryResource,
    SourcesInternal,
} from './resources';
import Answer from './resources/answer/Answer';
import ChunkInspector from './resources/chunk-inspector/ChunkInspector';
import {ResponseHandlers} from './ResponseHandlers';

const experimentalResources: Array<{key: string; resource: typeof Resource}> = [
    {key: 'activitiesInternal', resource: ActivitiesInternal},
    {key: 'documentIdentity', resource: DocumentIdentityResource},
    {key: 'logicalIndexes', resource: LogicalIndexesResource},
    {key: 'organizationMonitoring', resource: OrganizationMonitoringResource},
    {key: 'pipelineSettings', resource: PipelineSettingsResource},
    {key: 'preview', resource: PreviewResource},
    {key: 'privileges', resource: PrivilegesResource},
    {key: 'schemaServiceTemporary', resource: SchemaServiceTemporaryResource},
    {key: 'organizationDefinition', resource: OrganizationDefinitionResource},
    {key: 'dataService', resource: DataServiceResource},
    {key: 'commerce', resource: Commerce},
    {key: 'ipxInterfaceExperimental', resource: IPXInterfaceExperimentalResource},
    {key: 'answer', resource: Answer},
    {key: 'organizationSettings', resource: OrganizationSettingsResource},
    {key: 'sourcesInternal', resource: SourcesInternal},
    {key: 'projectInternal', resource: ProjectInternal},
    {key: 'chunkInspector', resource: ChunkInspector},
];

class ExperimentalPlatformClient extends PlatformClient {
    activitiesInternal: ActivitiesInternal;
    documentIdentity: DocumentIdentityResource;
    logicalIndexes: LogicalIndexesResource;
    organizationMonitoring: OrganizationMonitoringResource;
    pipelineSettings: PipelineSettingsResource;
    preview: PreviewResource;
    privileges: PrivilegesResource;
    schemaServiceTemporary: SchemaServiceTemporaryResource;
    organizationDefinition: OrganizationDefinitionResource;
    dataService: DataServiceResource;
    commerce: Commerce;
    ipxInterfaceExperimental: IPXInterfaceExperimentalResource;
    answer: Answer;
    organizationSettings: OrganizationSettingsResource;
    sourcesInternal: SourcesInternal;
    projectInternal: ProjectInternal;
    chunkInspector: ChunkInspector;

    constructor(options: PlatformClientOptions) {
        super(options);

        experimentalResources.forEach(({key, resource}) => {
            this[key] = new resource(this.API, this.ServerlessAPI);
        });
    }
}

export const Platform = new ExperimentalPlatformClient({
    accessToken: () => Registry.get('access_token'),
    organizationId: () => Registry.get('organization_id'),
    host: Config.CloudPlatform.url,
    serverlessHost: Config.LogBrowser.url,
    responseHandlers: ResponseHandlers,
});

export const PlatformNoHandlers = new ExperimentalPlatformClient({
    accessToken: () => Registry.get('access_token'),
    organizationId: () => Registry.get('organization_id'),
    host: Config.CloudPlatform.url,
    serverlessHost: Config.LogBrowser.url,
});
