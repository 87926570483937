import {Config} from '@core/configuration';
import {CurrentOrganization} from '@core/organization';
import {Locales} from '../strings/Locales';

export type AppName = 'platform' | 'commerce-hub' | 'knowledge-hub';

interface Application {
    label: string;
    href: string;
}

const urlHash = '#/' + CurrentOrganization.getId();

const appsBaseUrl: Record<AppName, string> = {
    platform: Config.CloudPlatform.url,
    ['knowledge-hub']: Config.CloudPlatform.knowledgeHubUrl,
    ['commerce-hub']: Config.CloudPlatform.commerceHubUrl,
};

const getAppUrl = (app: AppName): string => {
    const url = new URL(appsBaseUrl[app]);
    if (app === 'platform') {
        url.pathname = '/admin' + url.pathname;
    }
    url.hash = urlHash;
    return url.toString();
};

export const applicationsMap: Record<AppName, Application> = {
    platform: {
        label: Locales.format('AppSwitcher.administrationConsole'),
        href: getAppUrl('platform'),
    },
    'commerce-hub': {
        label: Locales.format('AppSwitcher.merchandisingHub'),
        href: getAppUrl('commerce-hub'),
    },
    'knowledge-hub': {
        label: Locales.format('AppSwitcher.knowledgeHub'),
        href: getAppUrl('knowledge-hub'),
    },
};
