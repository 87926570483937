import Registry from '@core/registry';
import {PlatformClient, ResponseHandler} from '@coveo/platform-client';

import {HttpStatusCodes} from './constants';
import {GenericError} from './interfaces';

const VITAL_FETCH_URLS = new RegExp(
    '^.*/rest/((organizations/\\w*/license(\\?.*)?)|(organizations/\\w*)|(tokens/[\\w-]*/info))$',
);

const outOfService: ResponseHandler = {
    canProcess: ({status, url}: Response) => {
        const unknownNetworkError = status === 0 && navigator.onLine;
        const platformError = status === HttpStatusCodes.SERVICE_UNAVAILABLE;
        const platformUnreachable = status === HttpStatusCodes.GATEWAY_TIMEOUT;
        const platformVitalFetchError = status === HttpStatusCodes.INTERNAL_SERVER_ERROR && VITAL_FETCH_URLS.test(url);

        return unknownNetworkError || platformError || platformUnreachable || platformVitalFetchError;
    },
    process: async <T>(response: Response): Promise<T> => {
        const event = new Event('helpers:show-out-of-service', {bubbles: true});
        document.dispatchEvent(event);
        window['admin'].vent.trigger('helpers:show-out-of-service');
        throw await response.json();
    },
};

const offline: ResponseHandler = {
    canProcess: ({status}: Response) => status === 0 && !navigator.onLine,
    process: async <T>(response: Response): Promise<T> => {
        const event = new Event('connection:offline', {bubbles: true});
        document.dispatchEvent(event);
        window['admin'].vent.trigger('connection:offline');
        throw await response.json();
    },
};

const unauthorized: ResponseHandler = {
    canProcess: ({status}: Response) => status === HttpStatusCodes.UNAUTHORIZED,
    process: async <T>(response: Response): Promise<T> => {
        const event = new Event('helper:authorize', {bubbles: true});
        document.dispatchEvent(event);
        window['admin'].vent.trigger('helper:authorize');
        throw await response.json();
    },
};

const allOtherErrors: ResponseHandler = {
    canProcess: (): boolean => true,
    process: async <T>(response: Response): Promise<T> => {
        const error: GenericError = await response.json();
        const hasNoToken = error?.errorCode === 'TOKEN_NOT_FOUND';
        const tokenIsInvalid = ['InvalidToken', 'ExpiredToken'].includes(error?.type ?? null);

        if (hasNoToken || tokenIsInvalid) {
            const event = new Event('helper:authorize', {bubbles: true});
            document.dispatchEvent(event);
            window['admin'].vent.trigger('helper:authorize');
        } else {
            const handleError = Registry.get<(error: GenericError) => void>('error_handler');
            handleError(error);
        }

        throw error;
    },
};

export const ResponseHandlers = [
    PlatformClient.Handlers.noContent,
    PlatformClient.Handlers.success,
    offline,
    outOfService,
    unauthorized,
    allOtherErrors,
];
