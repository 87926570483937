import {
    Box,
    BoxProps,
    Image,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
    type MantineSize,
} from '@components/mantine';
import {SourceType} from '@core/api';

import {Locales} from '../../../strings';
import {useSourceIdentityContext} from '../SourceIdentityContext';
import AdobeExperienceManager from './images/adobe-experience-manager.svg';
import amazonS3 from './images/amazonS3.svg';
import box from './images/box.svg';
import catalog from './images/catalog.svg';
import confluence from './images/confluence.svg';
import custom from './images/custom.svg';
import database from './images/database.svg';
import dropbox from './images/dropbox.svg';
import file from './images/file.svg';
import genericRest from './images/generic-rest.svg';
import googleDrive from './images/google-drive.svg';
import graphQl from './images/graphql.svg';
import jira from './images/jira.svg';
import jive from './images/jive.svg';
import khoros from './images/khoros.svg';
import microsoftDynamics from './images/microsoft-dynamics.svg';
import push from './images/push.svg';
import rss from './images/rss.svg';
import salesforce from './images/salesforce.svg';
import SAP from './images/sap.svg';
import servicenow from './images/servicenow.svg';
import sharepointOnline from './images/sharepoint-online.svg';
import sitecore from './images/sitecore.svg';
import sitemap from './images/sitemap.svg';
import slack from './images/slack.svg';
import twitter from './images/twitter.svg';
import web from './images/web.svg';
import youtube from './images/youtube.svg';
import zendesk from './images/zendesk.svg';

const SOURCE_TYPE_IMAGE_MAPPINGS: Record<SourceType, SVGElement> = {
    [SourceType.ADOBE_EXPERIENCE_MANAGER]: AdobeExperienceManager,
    [SourceType.AMAZONS3]: amazonS3,
    [SourceType.BOX_ENTERPRISE]: box,
    [SourceType.BOX_ENTERPRISE2]: box,
    [SourceType.CATALOG]: catalog,
    [SourceType.CONFLUENCE]: confluence,
    [SourceType.CONFLUENCE2]: confluence,
    [SourceType.CONFLUENCE2_HOSTED]: confluence,
    [SourceType.CUSTOM]: custom,
    [SourceType.DATABASE]: database,
    [SourceType.DROPBOX_FOR_BUSINESS]: dropbox,
    [SourceType.FILE]: file,
    [SourceType.GENERIC_REST]: genericRest,
    [SourceType.GOOGLE_DRIVE_DOMAIN_WIDE]: googleDrive,
    [SourceType.GRAPHQL]: graphQl,
    [SourceType.JIRA2]: jira,
    [SourceType.JIRA2_HOSTED]: jira,
    [SourceType.JIVE]: jive,
    [SourceType.JIVE_HOSTED]: jive,
    [SourceType.KHOROS]: khoros,
    [SourceType.LITHIUM]: khoros,
    [SourceType.MICROSOFT_DYNAMICS]: microsoftDynamics,
    [SourceType.PUSH]: push,
    [SourceType.RSS]: rss,
    [SourceType.SALESFORCE]: salesforce,
    [SourceType.SALESFORCE_SITES]: salesforce,
    [SourceType.SAP]: SAP,
    [SourceType.SERVICENOW]: servicenow,
    [SourceType.SHAREPOINT]: sharepointOnline,
    [SourceType.SHAREPOINT_ONLINE]: sharepointOnline,
    [SourceType.SHAREPOINT_ONLINE2]: sharepointOnline,
    [SourceType.SITECORE]: sitecore,
    [SourceType.SITEMAP]: sitemap,
    [SourceType.SLACK]: slack,
    [SourceType.TWITTER2]: twitter,
    [SourceType.WEB2]: web,
    [SourceType.YOUTUBE]: youtube,
    [SourceType.ZENDESK]: zendesk,
};

type SourceIdentityTypeImageSize = MantineSize | 'xxl';
export type SourceIdentityTypeImageStylesNames = 'typeImage';
export type SourceIdentityTypeImageFactory = Factory<{
    props: SourceIdentityTypeImageProps;
    ref: HTMLDivElement;
    stylesNames: SourceIdentityTypeImageStylesNames;
    compound: true;
}>;

export interface SourceIdentityTypeImageProps extends BoxProps, CompoundStylesApiProps<SourceIdentityTypeImageFactory> {
    /**
     * The image size of the type to display.
     *
     * @default: 'lg'
     */
    size: SourceIdentityTypeImageSize;
}

const defaultProps: Partial<SourceIdentityTypeImageProps> = {
    size: 'lg',
};

export const SourceIdentityTypeImage = factory<SourceIdentityTypeImageFactory>((props, ref) => {
    const {size, ...others} = useProps('SourceIdentityTypeImage', defaultProps, props);
    const {getStyles, sourceType} = useSourceIdentityContext();

    return (
        <Box ref={ref} data-size={size} {...getStyles('typeImage')} {...others}>
            <Image
                src={SOURCE_TYPE_IMAGE_MAPPINGS[sourceType]}
                aria-label={Locales.format('SourceIdentity.TypeImage.ariaLabel', {
                    sourceType: Locales.format(`SourceIdentity.sourceType.${sourceType}.label`),
                })}
            />
        </Box>
    );
});
SourceIdentityTypeImage.displayName = '@resourceIdentity/source/SourceIdentity/TypeImage';
