import {
    ActivityFacetModel,
    ActivityListingFilters,
    ActivityModel as ActivityModelPlatform,
    API,
    ListActivitiesFacetsParams,
    ListActivitiesParams,
    PageModel,
    Resource,
} from '@coveo/platform-client';

export class ActivitiesInternal extends Resource {
    static getBaseUrl = () => `/rest/organizations/${API.orgPlaceholder}/activities`;
    static getBaseUrlAllOrgs = () => `/rest/activities`;

    listFacets(
        params?: ListActivitiesFacetsParams,
        activityFacet?: ActivityListingFilters,
        allOrgs = false,
        canViewInternal = false,
    ) {
        const baseUrl = allOrgs ? ActivitiesInternal.getBaseUrlAllOrgs() : ActivitiesInternal.getBaseUrl();
        const useInternal = allOrgs || canViewInternal || Boolean(activityFacet?.sections?.includes('INTERNAL'));
        return this.api.post<ActivityFacetModel>(
            this.buildPath(useInternal ? `${baseUrl}/facets` : `${baseUrl}/facets/public`, params),
            activityFacet,
        );
    }

    /**
     * Get a list of the latest activities.
     * @returns Promise<PageModel<ActivityModelPlatform>>
     */
    listLatest(
        params?: ListActivitiesParams,
        activityFacet?: ActivityListingFilters,
    ): Promise<PageModel<ActivityModelPlatform>> {
        return this.api.post<PageModel<ActivityModelPlatform>>(
            this.buildPath(`${ActivitiesInternal.getBaseUrl()}/latest`, params),
            activityFacet,
        );
    }
}
