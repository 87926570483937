import {ColumnDef, createColumnHelper, Table, useTable} from '@components/mantine';
import {keepPreviousData, PipelineModel, Platform, useQuery} from '@core/api';
import {FunctionComponent} from 'react';
import {PrivilegeRow} from '../../PrivilegesTable.types';
import {GenericGranularPrivilegesTable} from '../GenericGranularPrivilegesTable';
import {Locales} from '../../../../strings/Locales';

const columnHelper = createColumnHelper<PipelineModel>();

const columns: Array<ColumnDef<PipelineModel>> = [
    columnHelper.accessor('name', {
        header: Locales.format('PrivilegesTable.Pipelines.column.name.header'),
        enableSorting: false,
    }),
];

export const QueryPipelinesPrivilegesTable: FunctionComponent<PrivilegeRow> = (props) => {
    const store = useTable<PipelineModel>();
    const query = useQuery({
        queryKey: ['pipeline', 'list', store.state.pagination],
        queryFn: async () => {
            const response = await Platform.pipeline.list({
                enablePagination: true,
                page: store.state.pagination.pageIndex,
                perPage: store.state.pagination.pageSize,
            });
            store.setTotalEntries(response.totalEntries);
            return response.items;
        },
        placeholderData: keepPreviousData,
    });
    return (
        <GenericGranularPrivilegesTable<PipelineModel>
            store={store}
            columns={columns}
            query={query}
            resourceName={props.name.toLowerCase()}
            {...props}
        >
            <Table.Footer>
                <Table.PerPage />
                <Table.Pagination />
            </Table.Footer>
        </GenericGranularPrivilegesTable>
    );
};
