import {createSafeContext, type GetStylesApi} from '@coveord/plasma-mantine';
import type {EmptyStateFactory} from './EmptyState';

export type EmptyStateVariantType = 'first-use' | 'feedback' | 'small';

interface EmptyStateContextType {
    getStyles: GetStylesApi<EmptyStateFactory>;
    variant: EmptyStateVariantType;
}

export const [EmptyStateProvider, useEmptyStateContext] = createSafeContext<EmptyStateContextType>(
    'EmptyState component was not found in tree',
);
