import {API, Resource, PageModel, New} from '@coveo/platform-client';
import {SearchConfiguration} from '../../interfaces';

export class SearchConfigurations extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/configurations/search`;

    create(model: New<SearchConfiguration>) {
        return this.api.post<SearchConfiguration>(SearchConfigurations.baseUrl, model);
    }

    get(configurationId: string) {
        return this.api.get<SearchConfiguration>(`${SearchConfigurations.baseUrl}/${configurationId}`);
    }

    getByQuery(query: string, trackingId: string) {
        return this.api.get<PageModel<SearchConfiguration>>(
            this.buildPath(`${SearchConfigurations.baseUrl}/condition`, {condition: query, trackingId}),
        );
    }

    getGlobal(trackingId: string) {
        return this.api.get<SearchConfiguration>(
            this.buildPath(`${SearchConfigurations.baseUrl}/global`, {trackingId}),
        );
    }

    updateGlobal(model: New<SearchConfiguration>) {
        return this.api.put<SearchConfiguration>(this.buildPath(`${SearchConfigurations.baseUrl}/global`), model);
    }

    delete(configurationId: string) {
        return this.api.delete(`${SearchConfigurations.baseUrl}/${configurationId}`);
    }
}
