import {isRouteErrorResponse, useRouteError} from '@core/routes';
import {ErrorContent, NotFoundContent} from '@pages/boundaries';
import {FunctionComponent} from 'react';

import {ExternalLayout} from '../components';

export const ExternalErrorPage: FunctionComponent = () => {
    const error = useRouteError();

    return (
        <ExternalLayout>
            {isRouteErrorResponse(error) && error.status === 404 ? (
                <NotFoundContent variant="external-page" />
            ) : (
                <ErrorContent variant="external-page" />
            )}
        </ExternalLayout>
    );
};
