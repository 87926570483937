export interface UserFeedbackSurvey {
    id: string;
    type: SurveyEventType;
    surveyTitleContext: string;
    additionalInfoTitle?: string;
}

export interface UserFeedbackAnswer {
    userFeedbackSurveyId: string;
    value: number;
    additionalInfo?: string;
}

export enum SurveyEventType {
    Add = 'ADD',
    Edit = 'EDIT',
}
