import {
    AnchorCssVariables,
    AnchorProps,
    AnchorVariant,
    PolymorphicFactory,
    polymorphicFactory,
    useProps,
} from '@coveord/plasma-mantine';
import {ExternalSize16Px, Icon} from '@coveord/plasma-react-icons';
import {ReactNode} from 'react';
import {Anchor} from '../anchor';
import {useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateLinkStylesNames = 'link';

type EmptyStateLinkProps = AnchorProps & {
    children: ReactNode;
    /**
     * The icon to display next to the link.
     *
     * @default ExternalSize16Px
     */
    icon?: Icon;
};

export type EmptyStateLinkFactory = PolymorphicFactory<{
    props: EmptyStateLinkProps;
    defaultComponent: 'a';
    stylesNames: EmptyStateLinkStylesNames;
    vars: AnchorCssVariables;
    defaultRef: HTMLAnchorElement;
    variant: AnchorVariant;
    compound: true;
}>;

const defaultProps: Partial<EmptyStateLinkProps> = {
    icon: ExternalSize16Px,
};

export const EmptyStateLink: ReturnType<typeof polymorphicFactory<EmptyStateLinkFactory>> =
    polymorphicFactory<EmptyStateLinkFactory>((props, ref) => {
        const ctx = useEmptyStateContext();
        const {
            icon: IconName,
            children,
            classNames,
            className,
            styles,
            style,
            ...others
        } = useProps('EmptyStateLink', defaultProps, props);

        return (
            <Anchor ref={ref} {...ctx.getStyles('link', {classNames, className, styles, style})} {...others}>
                <IconName height={16} />
                {children}
            </Anchor>
        );
    });
