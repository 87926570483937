import {createSafeContext, CSSProperties, GetStylesApiOptions} from '@components/mantine';
import {IdAndDisplayNameModel} from '@core/api';
import {AccessTableStylesNames} from './AccessTable.types';

interface AccessTableContext {
    targetDomain: string;
    value: IdAndDisplayNameModel[];
    readOnly: boolean;
    onChange: (value: IdAndDisplayNameModel[]) => void;
    getStyles: (
        selector: AccessTableStylesNames,
        options?: GetStylesApiOptions,
    ) => {
        className: string;
        style: CSSProperties;
    };
}

export const [AccessTableContextProvider, useAccessTableContext] = createSafeContext<AccessTableContext>(
    'AccessTableContextProvider component was not found in the tree',
);
