import {Box, Checkbox, MantineColor, Tooltip} from '@components/mantine';
import {CheckSize16Px, CrossSize16Px, Icon, MinusSize16Px} from '@coveord/plasma-react-icons';
import {FunctionComponent} from 'react';
import {Locales} from '../../strings/Locales';
import {PrivilegeRow} from './PrivilegesTable.types';
import {PrivilegesTableUtils} from './PrivilegesTableUtils';
import {usePrivilegeRow} from './PrivilegeTableContext';

const getIcon = (checked?: boolean, isNoAccess?: boolean): Icon => {
    if (checked) {
        return CheckSize16Px;
    }
    if (isNoAccess) {
        return MinusSize16Px;
    }
    return CrossSize16Px;
};

const getIconColor = (checked?: boolean, isNoAccess?: boolean): MantineColor => {
    if (checked) {
        return 'success';
    }
    if (isNoAccess) {
        return 'info';
    }
    return 'critical';
};

export const CanCreateCheckbox: FunctionComponent<PrivilegeRow> = ({
    id,
    name,
    owner,
    targetDomain,
    level,
    systemPrivileges,
    availablePrivileges,
}) => {
    const createPrivilegesExists = systemPrivileges.some(PrivilegesTableUtils.isCreatePrivilege);
    const {selectedPrivileges, onChange, readOnly: wholeTableInReadOnly} = usePrivilegeRow(id);
    if (!createPrivilegesExists) {
        return null;
    }
    const isEditAll = selectedPrivileges?.some(({type, targetId}) => type === 'EDIT' && targetId === '*') ?? false;
    const isNoAccess = (selectedPrivileges ?? []).length === 0;
    const checked = selectedPrivileges ? selectedPrivileges.some(PrivilegesTableUtils.isCreatePrivilege) : false;
    if (PrivilegesTableUtils.isGranularPrivilegeId(id)) {
        const readOnly = wholeTableInReadOnly || isNoAccess || isEditAll;
        return (
            <Checkbox
                checked={checked}
                disabled={!availablePrivileges.some(PrivilegesTableUtils.isCreatePrivilege)}
                readOnly={readOnly}
                onChange={() => {
                    if (!readOnly) {
                        if (checked) {
                            onChange(
                                selectedPrivileges.filter(
                                    (privilege) => !PrivilegesTableUtils.isCreatePrivilege(privilege),
                                ),
                            );
                        } else {
                            onChange([
                                ...(selectedPrivileges ?? []),
                                {type: 'CREATE', targetId: '*', owner, targetDomain, level},
                            ]);
                        }
                    }
                }}
            />
        );
    }
    return (
        <Tooltip
            label={Locales.format(
                isEditAll ? 'PrivilegesTable.canCreate.tooltip' : 'PrivilegesTable.cannotCreate.tooltip',
                {name: name.toLowerCase()},
            )}
        >
            <Box c={getIconColor(checked, isNoAccess)} component={getIcon(checked, isNoAccess)} height={16} />
        </Tooltip>
    );
};
