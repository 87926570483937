import {Region} from '@core/api';
import {ENVIRONMENT} from '@core/configuration';

const devEndpoints = {
    [ENVIRONMENT.dev]: 'https://analyticsdev.cloud.coveo.com/amplitude2/2/httpapi',
    [ENVIRONMENT.stg]: 'https://analyticsstg.cloud.coveo.com/amplitude2/2/httpapi',
};
const productionEndpoints = {
    [Region.AU]: 'https://analytics-au.cloud.coveo.com/amplitude2/2/httpapi',
    [Region.CA]: 'https://analytics-ca.cloud.coveo.com/amplitude2/2/httpapi',
    [Region.EU]: 'https://analytics-eu.cloud.coveo.com/amplitude2/2/httpapi',
    [Region.US]: 'https://analytics.cloud.coveo.com/amplitude2/2/httpapi',
};

const PLATFORM_PRODUCTION_TRIALS_API_KEY = '342cfdda63cf76c0f5fa27b9f8ebe872';
const PLATFORM_PRODUCTION_API_KEY = 'ca3fa6dad2eedb6588d222ba9ac951ec';
const PLATFORM_DEV_API_KEY = '4da23bb5da35c67ae251db13bfa0ba44';

export const AmplitudeConstant = {
    devEndpoints,
    productionEndpoints,
    apiKey: {
        platformProductionTrials: PLATFORM_PRODUCTION_TRIALS_API_KEY,
        platformProduction: PLATFORM_PRODUCTION_API_KEY,
        platformDev: PLATFORM_DEV_API_KEY,
    },
};
