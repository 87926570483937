import {
    Box,
    BoxProps,
    CompoundStylesApiProps,
    polymorphicFactory,
    PolymorphicFactory,
    useProps,
} from '@coveord/plasma-mantine';
import {ReactNode} from 'react';
import {EmptyStateStylesNames} from './EmptyState';
import {EmptyStateVariantType, useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateBodyStylesNames = 'body';

interface EmptyStateBodyProps extends BoxProps, CompoundStylesApiProps<EmptyStateBodyFactory> {
    children: ReactNode;
}

export type EmptyStateBodyFactory = PolymorphicFactory<{
    props: EmptyStateBodyProps;
    defaultComponent: 'div';
    defaultRef: HTMLDivElement;
    stylesNames: EmptyStateStylesNames;
    variant: EmptyStateVariantType;
    compound: true;
}>;

const defaultProps: Partial<EmptyStateBodyProps> = {};

export const EmptyStateBody: ReturnType<typeof polymorphicFactory<EmptyStateBodyFactory>> =
    polymorphicFactory<EmptyStateBodyFactory>((_props, ref) => {
        const ctx = useEmptyStateContext();
        const {style, className, classNames, styles, children, ...others} = useProps(
            'EmptyStateBody',
            defaultProps,
            _props,
        );

        return (
            <Box ref={ref} {...ctx.getStyles('body', {classNames, className, styles, style})} {...others}>
                {children}
            </Box>
        );
    });
