import {Combobox, ComboboxSearchProps, factory, Factory} from '@components/mantine';
import {Locales} from '../../strings/Locales';
import {useOrganizationPicker} from './OrganizationPickerContext';

export type OrganizationPickerSearchFactory = Factory<{
    props: Omit<ComboboxSearchProps, 'onChange' | 'value'>;
    ref: HTMLInputElement;
    stylesNames: string;
    compound: true;
}>;

export const OrganizationPickerSearch = factory<OrganizationPickerSearchFactory>((props, ref) => {
    const {filter, setFilter, getStyles} = useOrganizationPicker();

    return (
        <Combobox.Search
            ref={ref}
            value={filter}
            onChange={(event) => setFilter(event.currentTarget.value)}
            placeholder={Locales.format('OrganizationPicker.searchPlaceholder')}
            onMouseDown={(event) => event.stopPropagation()}
            {...getStyles('search')}
            {...props}
        />
    );
});
