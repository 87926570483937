import {ColumnDef, createColumnHelper, useTable} from '@components/mantine';
import {ApiKeyModel, Platform, useQuery} from '@core/api';
import {FunctionComponent} from 'react';
import {PrivilegeRow} from '../../PrivilegesTable.types';
import {GenericGranularPrivilegesTable} from '../GenericGranularPrivilegesTable';
import {Locales} from '../../../../strings/Locales';

type ApiKeyModelWithId = ApiKeyModel & {id: string};

const columnHelper = createColumnHelper<ApiKeyModelWithId>();

const columns: Array<ColumnDef<ApiKeyModelWithId>> = [
    columnHelper.accessor('displayName', {
        header: Locales.format('PrivilegesTable.ApiKeys.column.name.header'),
        enableSorting: false,
    }),
];

export const ApiKeysPrivilegesTable: FunctionComponent<PrivilegeRow> = (props) => {
    const store = useTable<ApiKeyModelWithId>();
    const query = useQuery({
        queryKey: ['api-key', 'list'],
        queryFn: async () => {
            const response = await Platform.apiKey.list();
            store.setTotalEntries(response.length);
            return response as ApiKeyModelWithId[];
        },
    });
    return (
        <GenericGranularPrivilegesTable<ApiKeyModelWithId>
            store={store}
            columns={columns}
            query={query}
            resourceName={props.name}
            {...props}
        />
    );
};
