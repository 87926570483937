import {Identify, identify as amplitudeIdentify, init as amplitudeInit} from '@amplitude/analytics-browser';
import {Config, isClientEnvironment, isDevEnvironment, isStgEnvironment} from '@core/configuration';
import {LicenseSelectors} from '@core/license';
import {ProjectSelectors} from '@core/projects';
import Registry from '@core/registry';
import md5 from 'md5';

import {AmplitudeConstant} from '../constants';
import {Store} from '../store';

const chainProperties = (properties: Record<string, any>) => {
    let chain = new Identify();
    for (const key in properties) {
        if (chain) {
            chain = chain.set(key, properties[key]);
        }
    }

    return chain;
};

const isTrial = () => LicenseSelectors.isTrialLicense(Store.getState());
const getProductName = () => LicenseSelectors.getProductName(Store.getState());
const getProductType = () => LicenseSelectors.getProductType(Store.getState());
const getProductEdition = () => LicenseSelectors.getProductEdition(Store.getState());
const getSelectedProjectId = () => ProjectSelectors.getSelectedProjectId(Store.getState());

const getUserContext = (): Record<string, any> => {
    const user = Registry.get('user');
    return {
        userId: user.email,
        SSO: user.provider.toLowerCase(),
        isInternalUser: user.email.includes('@coveo.com'),
        platformRegion: Config.CloudPlatform.region,
        organizationId: Registry.get('organization_id'),
        productName: getProductName(),
        productType: getProductType(),
        productEdition: getProductEdition(),
        projectId: getSelectedProjectId(),
    };
};

/**
 * Identification to the tracking platform.
 *
 * @param {'commerce-hub' | 'knowledge-hub' | 'platform'} ['platform'] currentApp
 */
export const identify = (currentApp: 'commerce-hub' | 'knowledge-hub' | 'platform' = 'platform'): void => {
    let userContext: Record<string, any>;
    try {
        userContext = getUserContext();
    } catch {
        return;
    }

    let apiKey: string = AmplitudeConstant.apiKey.platformDev;
    if (isClientEnvironment()) {
        apiKey = isTrial()
            ? AmplitudeConstant.apiKey.platformProductionTrials
            : AmplitudeConstant.apiKey.platformProduction;
    }

    const hashedUserId: string = md5(userContext.userId);
    if (!isTrial() && !userContext.isInternalUser) {
        userContext.userId = hashedUserId;
    }

    userContext.viewportWidth = window.innerWidth;
    userContext.viewportHeight = window.innerHeight;
    userContext.screenResolutionWidth = screen.width;
    userContext.screenResolutionHeight = screen.height;
    userContext.coveoApp = currentApp;

    // @todo: [CTCORE-9515] should the `appVersion` attribute be filled in with the AdminUI project version.
    amplitudeInit(apiKey, hashedUserId, {
        autocapture: false,
        identityStorage: 'localStorage',
        serverUrl:
            isDevEnvironment() || isStgEnvironment()
                ? AmplitudeConstant.devEndpoints[Config.env]
                : AmplitudeConstant.productionEndpoints[Config.CloudPlatform.region],
        trackingOptions: {
            ipAddress: false,
        },
    });

    amplitudeIdentify(chainProperties(userContext));
};
