import {UnstyledButton} from '@components/mantine';
import {LicenseSelectors} from '@core/license';
import {MenuSize24Px} from '@coveord/plasma-react-icons';
import {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';
import {useNavigation} from '../hooks';
import classes from './NavigationToggle.module.css';

export const NavigationToggle: FunctionComponent = () => {
    const trialExpired = useSelector(LicenseSelectors.isTrialExpired);
    const {toggleCollapsed} = useNavigation();

    return (
        <UnstyledButton className={classes.toggle} m="xs" onClick={() => toggleCollapsed()} disabled={trialExpired}>
            <MenuSize24Px height={24} />
        </UnstyledButton>
    );
};
