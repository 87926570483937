import {Box, Select, Tooltip} from '@components/mantine';
import {AccessLevel, AccessModel, IdAndDisplayNameModel, PrivilegeHolderType} from '@core/api';
import {FunctionComponent} from 'react';
import {Locales} from '../../strings/Locales';
import {useAccessTableContext} from './AccessTable.context';

export const AccessTableAccessLevelCell: FunctionComponent<AccessModel> = ({
    id,
    accessLevel,
    privilegeHolderType,
    displayName,
}) => {
    const {getStyles, targetDomain, onChange, value, readOnly} = useAccessTableContext();
    const rootString = privilegeHolderType === PrivilegeHolderType.GROUP ? 'GroupsAccessTable' : 'APIKeysAccessTable';
    const resourceNamePlural = Locales.formatOrDefault(`PrivilegesRows.${targetDomain}`, {
        defaultTranslation: targetDomain,
    }).toLowerCase();

    if (accessLevel === AccessLevel.NONE) {
        return (
            <Tooltip label={Locales.format(`${rootString}.view.tooltip`, {resourceNamePlural})} position="top-start">
                <Box>{Locales.format('AccessLevelPicker.option.NONE')}</Box>
            </Tooltip>
        );
    }

    if (accessLevel === AccessLevel.EDIT_ALL) {
        return (
            <Tooltip
                label={Locales.format(`${rootString}.edit.tooltip`, {resourceNamePlural})}
                position="top-start"
                maw={220}
            >
                <Box>{Locales.format('AccessLevelPicker.option.EDIT')}</Box>
            </Tooltip>
        );
    }

    const editSelected = value?.some((privilegeHolderThatCanEdit) => privilegeHolderThatCanEdit.id === id) ?? false;

    return (
        <Select
            w={130}
            {...getStyles('accessLevelSelect')}
            data={[
                {value: 'view', label: Locales.format('AccessLevelPicker.option.VIEW')},
                {value: 'edit', label: Locales.format('AccessLevelPicker.option.EDIT')},
            ]}
            key={id}
            readOnly={readOnly}
            value={editSelected ? 'edit' : 'view'}
            onChange={(newSelectValue) => {
                const newValue: IdAndDisplayNameModel[] =
                    newSelectValue === 'edit'
                        ? [...(value ?? []), {id, displayName}]
                        : value.filter((v) => v.id !== id);
                onChange(newValue);
            }}
        />
    );
};
