import {LicenseModel} from '@core/api';
import {LicenseSelectors} from '@core/license';
import {UserPrivilegesValidator, UserSelectors} from '@core/user';
import {ContextType, FunctionComponent, PropsWithChildren, ReactNode, useContext} from 'react';
import {useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {GuardContext} from '../../contexts';

interface GuardParameters {
    user: UserPrivilegesValidator;
    license: LicenseModel;
}

export interface GuardProps {
    /**
     * Whether the guarded children are allowed to render.
     */
    canRender: boolean | ((params: GuardParameters) => boolean);
    /**
     * Whether the user can edit the content of the page. The resulting value of this prop will be stored in the guard's context and exposed by the `useGuard` hook.
     *
     * @default true
     */
    canEdit?:
        | boolean
        | ((params: GuardParameters) => boolean)
        | ((params: GuardParameters) => ContextType<typeof GuardContext>);
    /**
     * The content that will be displayed in the event that canRender prop is false.
     */
    fallback?: ReactNode;
}

export const Guard: FunctionComponent<PropsWithChildren<GuardProps>> = ({
    canRender,
    canEdit,
    fallback = null,
    children,
}) => {
    const parentGuardContextValue = useContext(GuardContext);
    const {user, license} = useSelector(
        createStructuredSelector({
            user: UserSelectors.getPrivilegesValidator,
            license: LicenseSelectors.getLicense,
        }),
    );
    const isAuthorized = typeof canRender === 'boolean' ? canRender : canRender({user, license});

    if (isAuthorized) {
        let contextValue: ContextType<typeof GuardContext>;

        if (canEdit === undefined && !parentGuardContextValue) {
            contextValue = {canEdit: true};
        } else if (canEdit === undefined) {
            contextValue = parentGuardContextValue;
        } else {
            const canEditResult = typeof canEdit === 'boolean' ? canEdit : canEdit({user, license});
            const currentGuardContextValue =
                typeof canEditResult === 'boolean' ? {canEdit: canEditResult} : canEditResult;

            contextValue = parentGuardContextValue
                ? {...parentGuardContextValue, ...currentGuardContextValue}
                : currentGuardContextValue;
        }

        return <GuardContext.Provider value={contextValue}>{children}</GuardContext.Provider>;
    }

    return <>{fallback}</>;
};
