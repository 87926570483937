import {compose} from 'redux';
import _ from 'underscore';
import {toSentenceSerial} from 'underscore.string';

import {Locales} from '../../strings/Locales';

export class Arrays {
    static sortByString(array: string[]) {
        return _.sortBy(array, (str) => str);
    }

    // Return 0 if arrays are equal, 1 if array1 is greater than array2, -1 otherwise.
    static compare(array1: number[], array2: number[]): number {
        let value = 0;
        let i = 0;
        while (array1[i] === array2[i] && i < array1.length) {
            i++;
        }
        if (array1[i] > array2[i]) {
            value = 1;
        } else if (array1[i] < array2[i]) {
            value = -1;
        }
        return value;
    }

    static swapIfEmpty = <T>(array1: T[], array2: T[]): T[] => (_.isEmpty(array1) ? array2 : array1);

    static enumerate(items: string[]): string {
        return _.isEmpty(items) ? '' : toSentenceSerial(items, ', ', ` ${Locales.format('and')} `);
    }
}

export class ArraysOfObjects {
    static sortObjectKeys = <T extends Record<string, unknown>>(obj): T =>
        _.isObject(obj)
            ? Object.keys(obj)
                  .sort()
                  .reduce((acc: any, key: string) => ({...acc, [key]: obj[key]}), {})
            : {};

    static toObject = <T>(list: T[]): Record<string, T> =>
        _.indexBy(list, (item: T) => hash(JSON.stringify(ArraysOfObjects.sortObjectKeys(item))));

    /**
     * Returns the objects in the first list that are not in the second list or any of the other lists
     */
    static difference = <T>(first: T[], second: T[], ...others: T[][]): T[] => {
        const difference_recursive = (list1: T[], list2: T[]): T[] => {
            const hashedList1 = ArraysOfObjects.toObject(list1);
            const hashedList2 = ArraysOfObjects.toObject(list2);

            return _.chain(hashedList1)
                .omit(..._.keys(hashedList2))
                .values()
                .value();
        };

        return _.reduce(others, (memo, list) => difference_recursive(memo, list), difference_recursive(first, second));
    };

    static intersection = <T>(first: T[], second: T[], ...others: T[][]): T[] => {
        const intersection_recursive = (list1: T[], list2: T[]): T[] => {
            const hashedList1 = ArraysOfObjects.toObject(list1);
            const hashedList2 = ArraysOfObjects.toObject(list2);

            return _.chain(hashedList1)
                .pick(..._.keys(hashedList2))
                .values()
                .value();
        };

        return _.reduce(
            others,
            (memo, list) => intersection_recursive(memo, list),
            intersection_recursive(first, second),
        );
    };

    static uniq = <T>(list: T[]): T[] => _.values(ArraysOfObjects.toObject(list));

    static union = <T>(...lists: T[][]): T[] => compose<T[]>(ArraysOfObjects.uniq, _.compact, _.flatten)(lists);
}

/**
 * Hash a given string to a unique hash value
 * Took from https://jsperf.com/hashing-strings
 */
const hash = (str: string) => {
    let res = 0;
    for (let i = 0; i < str.length; i++) {
        res = res * 31 + str.charCodeAt(i);
        /* eslint-disable-next-line no-bitwise */
        res = res & res;
    }
    return res;
};
