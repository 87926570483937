import {API, Resource} from '@coveo/platform-client';
import {BasicSourceModel} from '../interfaces';

export class SourcesInternal extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/sources`;

    /**
     * Lists all sources of an organization, but returns only basic information about each source.
     */
    listAll() {
        return this.api.get<BasicSourceModel[]>(`${SourcesInternal.baseUrl}/basic/all`);
    }
}
