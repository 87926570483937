import {Box} from '@components/mantine';
import {forwardRef} from 'react';

import {SourceIdentityProps} from './SourceIdentity';
import {useSourceIdentityContext} from './SourceIdentityContext';

export const SourceIdentityCustomVariant = forwardRef<HTMLDivElement, SourceIdentityProps>(
    ({children, trackingHandler: _trackingHandler, ...props}, ref) => {
        const {getStyles} = useSourceIdentityContext();

        return (
            <Box ref={ref} flex={1} {...getStyles('root')} {...props}>
                {children}
            </Box>
        );
    },
);
SourceIdentityCustomVariant.displayName = '@resourceIdentity/source/SourceIdentity/CustomVariant';
