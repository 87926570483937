import {Locales} from '../../../strings';

export enum SystemsStatusType {
    critical = 'critical',
    major = 'major',
    minor = 'minor',
    none = 'none',
}

const hasSeenBadServerStateKey = 'hasSeenBadStatusState';

const ClusterStatuses = {
    Healthy: 'HEALTHY',
    Error: 'ERROR',
};

const OrganizationStatusEvents = {
    PauseOrg: 'status:organization:pause',
    ResumeOrg: 'status:organization:resume',
};

const OrgStatusInfoType = {
    HEALTHY: {
        variant: 'success',
        message: Locales.format('NotificationsMenu.healthy'),
    },
    READONLY: {
        variant: 'caution',
        message: Locales.format('NotificationsMenu.underMaintenance'),
    },
    CREATION: {
        variant: 'caution',
        message: Locales.format('NotificationsMenu.creation'),
    },
    PAUSED: {
        variant: 'caution',
        message: Locales.format('NotificationsMenu.inactive'),
    },
    SEARCH_ERROR: {
        variant: 'caution',
        message: Locales.format('NotificationsMenu.searchError'),
    },
    PAUSING_FAILED: {
        variant: 'caution',
        message: Locales.format('NotificationsMenu.pausingFailed'),
    },
    RESUMING_FAILED: {
        variant: 'caution',
        message: Locales.format('NotificationsMenu.resumingFailed'),
    },
    RESUMING: {
        variant: 'caution',
        message: Locales.format('NotificationsMenu.resuming'),
    },
    ERROR: {
        variant: 'error',
        message: Locales.format('NotificationsMenu.noResponse'),
    },
};

export const StatusConstants = {
    hasSeenBadServerStateKey,
    OrganizationStatusEvents,
    ClusterStatuses,
    OrgStatusInfoType,
};
