import {Config} from '@core/configuration';
import {
    EnrichedRequestInit,
    Environment,
    Feature,
    ProjectModel,
    ProjectResourceType,
    Region,
    ResponseHandlers,
} from '@coveo/platform-client';

import {FeaturesHelpers} from './helpers';

const useRegion =
    (region: Region): Feature =>
    (currentOptions) => ({
        ...currentOptions,
        region,
        environment: Config.env as Environment,
        host: undefined,
    });

const useToken =
    (token: string): Feature =>
    (currentOptions) => ({
        ...currentOptions,
        accessToken: token,
    });

const useBigNumbersToStrings = (): Feature => (currentOptions) => ({
    ...currentOptions,
    responseHandlers: [
        {
            canProcess: (response) => response.ok,
            process: async (response) => {
                const result = await response.text();
                return JSON.parse(result.replace(/("[^"]*"\s*:\s*)(\d{16,})/g, '$1"$2"'));
            },
        },
        ...(currentOptions.responseHandlers || [ResponseHandlers.error]),
    ],
});

const useProjectFilter =
    (
        currentProject: ProjectModel | null,
        resourceType: ProjectResourceType,
        subStringToAppend = '',
        queryOptions?: Record<string, any>,
        idsIntersectionParamName?: string,
        payload: Record<string, any> = {},
    ): Feature =>
    (currentOptions) => ({
        ...currentOptions,
        requestHandlers: [
            {
                canProcess: () => !!currentProject && Object.keys(currentProject).length > 0,
                process: (request: EnrichedRequestInit) => {
                    const enrichedUrl = FeaturesHelpers.enrichRequestUrlFilteredByProject(
                        request.url,
                        subStringToAppend,
                        queryOptions,
                    );

                    const enrichedBody = FeaturesHelpers.createRequestBodyFilteredByProject(
                        currentProject!,
                        resourceType,
                        idsIntersectionParamName,
                    );

                    const requestBody = Array.isArray(enrichedBody) ? enrichedBody : {...enrichedBody, ...payload};

                    return {
                        ...request,
                        url: enrichedUrl,
                        method: 'POST',
                        body: JSON.stringify(requestBody),
                        headers: {
                            ...request.headers,
                            'Content-Type': 'application/json',
                        },
                    };
                },
            },
        ],
    });

export const PlatformClientFeatures = {
    /**
     * Creates a platform client feature that allows to perform a request in the specified region.
     * To be used in conjunction with [`withFeatures`](https://github.com/coveo/platform-client#ad-hoc-requests) method of the platform-client.
     *
     * @param region The region in which to perform the request
     * @example
     * ```
     * const europeOrgs = await Platform.withFeatures(
     *     PlatformClientFeatures.useRegion(Region.EU)
     * ).organization.list();
     * ```
     * @returns A platform client instance configured to perform a request in the specified region
     */
    useRegion,
    /**
     * Creates a platform client feature that allows to perform a request with a different access token
     * To be used in conjunction with [`withFeatures`](https://github.com/coveo/platform-client#ad-hoc-requests) method of the platform-client.
     *
     * @param token The access token to use to perform the request
     * @example
     * ```
     * const impersonatedSearchResults = await Platform.withFeatures(
     *     PlatformClientFeatures.useToken(impersonateToken)
     * ).search.query();
     * ```
     * @returns A platform client instance configured to perform a request in the specified region
     */
    useToken,
    /**
     * Creates a platform client that will parse big numbers (containing more then 16 digits) into strings.
     * To be used in conjunction with [`withFeatures`](https://github.com/coveo/platform-client#ad-hoc-requests) method of the platform-client.
     *
     * @example
     * ```
     * const parseBigNumbersResults = await Platform.withFeatures(
     *     PlatformClientFeatures.useToken(useBigNumbersToStrings)
     * ).search.query();
     * ```
     * @returns A platform client instance configured to parse big numbers to strings.
     */
    useBigNumbersToStrings,
    /**
     * Creates a platform client feature that modified a request's body to include a subset of resource IDs
     * This hook is used to take into account the selected project in the organization, and to only return the relevant
     * resources accordingly.
     *
     * To be used in conjunction with [`withFeatures`](https://github.com/coveo/platform-client#ad-hoc-requests) method of the platform-client.
     * @example
     * ```
     * const sourcesFilteredByProject = await Platform.withFeatures(
     *      PlatformClientFeatures.useProjectFilter(project, 'SOURCE'))
     *           .sourceExperimental.listOperationalStatusBySourceIds(params))
     * ```
     * @returns A platform client instance configured to handle requests according to selected project.
     */
    useProjectFilter,
};
