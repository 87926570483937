import {Anchor, Box, notifications} from '@components/mantine';
import {Config, STATUS_PAGE_API_URL} from '@core/configuration';
import {Translation} from '@core/locales';
import {Locales} from '../../../strings';
import {StatusConstants, SystemsStatusType} from './StatusConstants';

export interface SystemsStatusModel {
    description: string;
    indicator: SystemsStatusType;
}

export interface ScheduledMaintenanceModel {
    status: string;
    name: string;
    schedule_for: string;
    scheduled_until: string;
    created_at: string;
}

const getSystemsStatus = async (): Promise<SystemsStatusModel> => {
    try {
        const response = await fetch(`${STATUS_PAGE_API_URL[Config.env]}/api/v2/status.json`);
        const res = await response.json();
        const status: SystemsStatusModel = res?.status;
        const isNotHealthy = [SystemsStatusType.critical, SystemsStatusType.major, SystemsStatusType.minor].includes(
            status?.indicator,
        );

        if (isNotHealthy && !sessionStorage.getItem(StatusConstants.hasSeenBadServerStateKey)) {
            if (status?.indicator === SystemsStatusType.minor) {
                notifications.show({
                    title: Locales.format('NotificationsMenu.serverBadStatusStateTitle'),
                    message: (
                        <Box mt="sm">
                            <Translation t={Locales} i18nKey="NotificationsMenu.serverBadStatusStateDescription">
                                <Anchor href="http://status.cloud.coveo.com/" />
                            </Translation>
                        </Box>
                    ),
                });
            } else {
                notifications.showWarning({
                    title: Locales.format('NotificationsMenu.serverBadStatusStateTitle'),
                    message: (
                        <Box mt="sm">
                            <Translation t={Locales} i18nKey="NotificationsMenu.serverBadStatusStateDescription">
                                <Anchor href="http://status.cloud.coveo.com/" />
                            </Translation>
                        </Box>
                    ),
                });
            }

            sessionStorage.setItem(StatusConstants.hasSeenBadServerStateKey, 'true');
        }

        return status ?? ({} as SystemsStatusModel);
    } catch (error) {
        console.error('Internal error when fetching server status.');
    }
};

const getScheduledMaintenance = async (): Promise<ScheduledMaintenanceModel> => {
    try {
        const response = await fetch(`${STATUS_PAGE_API_URL[Config.env]}/api/v2/scheduled-maintenances/upcoming.json`);
        const res = await response.json();
        return (res?.scheduled_maintenances?.[0] ?? {}) as ScheduledMaintenanceModel;
    } catch (error) {
        console.error('Internal error when fetching schedule maintenance.');
    }
};

export const StatusActions = {
    getSystemsStatus,
    getScheduledMaintenance,
};
