import {NavigationSearchControl} from './NavigationSearchControl';
import {NavigationSearchProvider} from './NavigationSearchProvider';

interface NavigationSearchType {
    Control: typeof NavigationSearchControl;
    Provider: typeof NavigationSearchProvider;
}

export const NavigationSearch: NavigationSearchType = {
    Control: NavigationSearchControl,
    Provider: NavigationSearchProvider,
};
