import {ComboboxStore} from '@components/mantine';
import {OrganizationModel} from '@core/api';
import {ForwardedRef, forwardRef, FunctionComponent, ReactNode} from 'react';

interface OrganizationPickerOptionParams {
    combobox: ComboboxStore;
    organization: OrganizationModel;
    isActive: boolean;
    ref: ForwardedRef<HTMLDivElement>;
}

export interface OrganizationPickerOptionProps {
    children: (params: OrganizationPickerOptionParams) => ReactNode;
    combobox?: never;
    organization?: never;
    isActive?: never;
}

export const OrganizationPickerOption: FunctionComponent<OrganizationPickerOptionProps> = forwardRef<
    HTMLDivElement,
    OrganizationPickerOptionProps
>(({children, combobox, organization, isActive}, ref) => children({combobox, organization, ref, isActive}));
