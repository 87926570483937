import {Box, Center, Paper, Stack} from '@components/mantine';
import {FunctionComponent, PropsWithChildren} from 'react';
import CoveoLogo from '../../images/external-page/coveo-logo-new.svg';
import Diamonds from '../../images/external-page/diamonds.svg';
import Glow from '../../images/external-page/glow.svg';

export const ExternalPage: FunctionComponent<PropsWithChildren> = ({children}) => {
    const rand1 = Math.floor(Math.random() * 11).toString();
    const body = document.querySelector('.authentication-flow-body') as HTMLElement;
    body.style.setProperty('--delay', rand1);
    return (
        <Center mih="100vh" w="100%" px={{base: 40, xs: 'xs'}}>
            <Stack
                align="center"
                justify="center"
                mih="100vh"
                mah="auto"
                gap="xl"
                style={{
                    zIndex: 100,
                }}
            >
                <Box pt="md">
                    <img src={CoveoLogo} width="184px" alt="Coveo Logo" />
                </Box>
                <Paper
                    maw="528px"
                    w={{base: '100%', xs: '528px'}}
                    px={{base: 'md', xs: 56}}
                    py={{base: 'lg', xs: 48}}
                    bg="gray-0"
                    ta="center"
                    style={(theme) => ({
                        borderRadius: theme.radius.md,
                    })}
                >
                    {children}
                </Paper>
            </Stack>
            <img className="diamonds" src={Diamonds} alt="background image" />
            <img
                style={{
                    position: 'fixed',
                    pointerEvents: 'none',
                }}
                src={Glow}
                alt="background image"
            />
        </Center>
    );
};
