import {API, Resource} from '@coveo/platform-client';
import {
    MetricsOptions,
    MetricsOptionsWithPagination,
    RecommendationsMetricsModel,
    RecommendationsMetricsPageModel,
    RecommendationsMetricsProductPageModel,
} from '../../interfaces';

export class RecommendationsMetrics extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/reports/v1/recommendations/slots`;

    list(options?: MetricsOptionsWithPagination): Promise<RecommendationsMetricsPageModel> {
        return this.api.get<RecommendationsMetricsPageModel>(
            this.buildPath(`${RecommendationsMetrics.baseUrl}`, options),
        );
    }

    get(recommendationSlotId: string, options?: MetricsOptions): Promise<RecommendationsMetricsModel> {
        return this.api.get<RecommendationsMetricsModel>(
            this.buildPath(`${RecommendationsMetrics.baseUrl}/${recommendationSlotId}`, options),
        );
    }

    getProductsMetrics(
        recommendationSlotId: string,
        options?: MetricsOptionsWithPagination,
    ): Promise<RecommendationsMetricsProductPageModel> {
        return this.api.get<RecommendationsMetricsProductPageModel>(
            this.buildPath(`${RecommendationsMetrics.baseUrl}/${recommendationSlotId}/products`, options),
        );
    }
}
