import {To, useNavigate as useReactRouterNavigate} from 'react-router-dom';

import {CustomNavigateOptions} from '../types';
import {injectOrganizationId} from '../utilities/injectOrganizationId';

export const useNavigate: () => {
    (to: To, options?: CustomNavigateOptions): void;
    (delta: number): void;
} = () => {
    const originalNavigate = useReactRouterNavigate();

    return (to: To | number, options?: CustomNavigateOptions) => {
        if (typeof to === 'number') {
            originalNavigate(to);
            return;
        }

        const isInjectingOrg = options?.injectOrg === undefined || options?.injectOrg;
        delete options?.injectOrg;

        originalNavigate(isInjectingOrg ? injectOrganizationId(to) : to, options);
    };
};
