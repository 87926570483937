import {AccessLevel, AccessModel, IdAndDisplayNameModel, PrivilegeHolderType} from '@core/api';
import {AccessTableConstants} from './AccessTableConstants';

const sortByCallerPartOfAndByName = (a: AccessModel, b: AccessModel): number => {
    let featuredWeight = 0;
    if (a.privilegeHolderType === PrivilegeHolderType.GROUP && b.privilegeHolderType === PrivilegeHolderType.GROUP) {
        if (a.callerPartOf > b.callerPartOf) {
            featuredWeight = -10;
        }
        if (a.callerPartOf < b.callerPartOf) {
            featuredWeight = 10;
        }
    }
    const displayNameA = a.displayName?.toLowerCase() || '';
    const displayNameB = b.displayName?.toLowerCase() || '';
    if (displayNameA < displayNameB) {
        return -1 + featuredWeight;
    }
    if (displayNameA > displayNameB) {
        return 1 + featuredWeight;
    }

    return featuredWeight;
};

const isModifiableAccess = (accessModel: AccessModel) =>
    [AccessLevel.VIEW_ALL, AccessLevel.CUSTOM].includes(accessModel.accessLevel);

const sortModels = <T extends AccessModel = AccessModel>(accessModels: T[]): T[] => {
    const featuredAccessModel = accessModels.filter(isModifiableAccess).sort(sortByCallerPartOfAndByName);
    const restAccessModel = accessModels
        .filter((accessModel) => !isModifiableAccess(accessModel))
        .sort(sortByCallerPartOfAndByName);

    return [...featuredAccessModel, ...restAccessModel];
};

/**
 * Checks if the given access model has view access on the given resource.
 * @param accessModel The access model to check
 * @param resourceId The id of the resource to check for view access
 * @returns True if the access model has view access on the resource, false otherwise
 */
const hasViewAccessOnResource = (
    accessModel: AccessModel,
    resourceId: string = AccessTableConstants.newResourceTempId,
) =>
    accessModel.accessLevel === AccessLevel.VIEW_ALL ||
    (accessModel.accessLevel === AccessLevel.CUSTOM &&
        !(accessModel?.resourceIdsWithEditLevel ?? []).includes(resourceId));

/**
 * Checks if the given access model has edit access on the given resource.
 * @param accessModel The access model to check
 * @param resourceId The id of the resource to check for edit access
 * @returns True if the access model has edit access on the resource, false otherwise
 */
const hasEditAccessOnResource = (
    accessModel: AccessModel,
    resourceId: string = AccessTableConstants.newResourceTempId,
) =>
    accessModel.accessLevel === AccessLevel.EDIT_ALL ||
    (accessModel.accessLevel === AccessLevel.CUSTOM &&
        (accessModel?.resourceIdsWithEditLevel ?? []).includes(resourceId));

/**
 * Updates the supplied access model to have view access on the given resource.
 * @param accessModel The access model to update
 * @param resourceId The id of the resource to give view access to
 * @returns The updated access model with view access on the resource
 */
const setViewAccessOnResource = <T extends AccessModel = AccessModel>(
    accessModel: T,
    resourceId: string = AccessTableConstants.newResourceTempId,
): T => {
    if ([AccessLevel.EDIT_ALL, AccessLevel.NONE].includes(accessModel.accessLevel)) {
        throw new Error(`Cannot set view access on a model with access level ${accessModel.accessLevel}`);
    }

    if (hasViewAccessOnResource(accessModel, resourceId)) {
        return accessModel;
    }

    if (accessModel.accessLevel === AccessLevel.CUSTOM) {
        const newResourceIdsWithEditLevel = accessModel.resourceIdsWithEditLevel.filter((id) => id !== resourceId);
        return {
            ...accessModel,
            resourceIdsWithEditLevel: newResourceIdsWithEditLevel,
            accessLevel: newResourceIdsWithEditLevel.length > 0 ? AccessLevel.CUSTOM : AccessLevel.VIEW_ALL,
        };
    }

    throw new Error(`Unsupported access level: ${accessModel.accessLevel}`);
};

/**
 * Updates the supplied access model to have edit access on the given resource.
 * @param accessModel The access model to update
 * @param resourceId The id of the resource to give edit access to
 * @returns The updated access model with edit access on the resource
 */
const setEditAccessOnResource = <T extends AccessModel = AccessModel>(
    accessModel: T,
    resourceId: string = AccessTableConstants.newResourceTempId,
): T => {
    if ([AccessLevel.EDIT_ALL, AccessLevel.NONE].includes(accessModel.accessLevel)) {
        throw new Error(`Cannot set edit access on a model with access level ${accessModel.accessLevel}`);
    }

    if (hasEditAccessOnResource(accessModel, resourceId)) {
        return accessModel;
    }

    if ([AccessLevel.VIEW_ALL, AccessLevel.CUSTOM].includes(accessModel.accessLevel)) {
        return {
            ...accessModel,
            resourceIdsWithEditLevel: [...(accessModel.resourceIdsWithEditLevel || []), resourceId],
            accessLevel: AccessLevel.CUSTOM,
        };
    }

    throw new Error(`Unsupported access level: ${accessModel.accessLevel}`);
};

/**
 * Sorts IdAndDisplayNameModel by id.
 * Mostly used to make sure that the order of the access models is consistent when checking for form dirtiness.
 */
const sortIdNameModels = (a: IdAndDisplayNameModel, b: IdAndDisplayNameModel) => a.id.localeCompare(b.id);

/**
 * Converts AccessModel[] to IdAndDisplayNameModel[] and sorts them by id.
 * Only includes access models that are modifiable and have edit access on the given resource.
 * @param accessModels The list of access models to serialize
 * @param resourceId The id of the resource to check for edit access
 * @returns Serialized list access that can edit the resource
 */
const serializeAccessModels = <T extends AccessModel = AccessModel>(
    accessModels: T[],
    resourceId: string = AccessTableConstants.newResourceTempId,
): IdAndDisplayNameModel[] =>
    accessModels
        .filter((accessModel) => isModifiableAccess(accessModel) && hasEditAccessOnResource(accessModel, resourceId))
        .map(({id, displayName}) => ({id, displayName}))
        .sort(sortIdNameModels);

/**
 * Overrides the access models with the given value.
 * @param accessModels The base list of access models to override
 * @param value The list of access models that can edit to override with
 * @param resourceId The id of the resource to check for edit access
 * @returns The updated list of access models
 */
const overrideAccessModelsWithValue = <T extends AccessModel = AccessModel>(
    accessModels: T[],
    value: IdAndDisplayNameModel[] | null,
    resourceId: string = AccessTableConstants.newResourceTempId,
): T[] =>
    value == null
        ? accessModels
        : accessModels.map((accessModel) => {
              if (isModifiableAccess(accessModel)) {
                  const valueOverridesToEdit = value.find((access) => access.id === accessModel.id);
                  const valueOverridesToView = !valueOverridesToEdit;
                  if (valueOverridesToEdit && hasViewAccessOnResource(accessModel, resourceId)) {
                      return setEditAccessOnResource(accessModel, resourceId);
                  } else if (valueOverridesToView && hasEditAccessOnResource(accessModel, resourceId)) {
                      return setViewAccessOnResource(accessModel, resourceId);
                  }
              }

              return accessModel;
          });

/**
 * Checks whether the given resource is new or not.
 * @param resourceId the id of the resource to check
 * @returns true if the resource is new, false otherwise
 */
const isNewResource = (resourceId: string) => !resourceId || resourceId === AccessTableConstants.newResourceTempId;

export const AccessTableUtils = {
    sortModels,
    isModifiableAccess,
    sortIdNameModels,
    serializeAccessModels,
    hasEditAccessOnResource,
    setEditAccessOnResource,
    overrideAccessModelsWithValue,
    isNewResource,
};
