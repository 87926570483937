import {Combobox, InfiniteQueryScrollArea, Skeleton, useDebouncedValue} from '@components/mantine';
import {keepPreviousData, Platform, PlatformClientFeatures, useInfiniteQuery} from '@core/api';
import {CurrentOrganization} from '@core/organization';
import {cloneElement, FunctionComponent, ReactElement, useEffect} from 'react';
import {Locales} from '../../strings/Locales';
import {useOrganizationPicker} from './OrganizationPickerContext';

const PER_PAGE = 25;

export const OrganizationPickerOptions: FunctionComponent<{
    option: ReactElement;
}> = ({option}) => {
    const {filter, getStyles, region, combobox} = useOrganizationPicker();

    const [debouncedFilter] = useDebouncedValue(filter, 200);

    const organizationListQuery = useInfiniteQuery({
        queryKey: ['organizations', debouncedFilter, region],
        queryFn: ({pageParam}) =>
            // eslint-disable-next-line react-hooks/rules-of-hooks
            Platform.withFeatures(PlatformClientFeatures.useRegion(region)).organization.list({
                page: pageParam,
                perPage: PER_PAGE,
                sortBy: 'displayName',
                filter: debouncedFilter,
            }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) =>
            pages.flatMap((page) => page.items).length < lastPage?.totalEntries ? pages.length : undefined,
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    });

    const orgs = organizationListQuery.data?.pages.flatMap((page) => page.items).flat();
    const options =
        orgs?.map((item) => {
            const isActive = item.id === CurrentOrganization.getId();

            return (
                <Combobox.Option
                    value={item.id}
                    key={item.id}
                    active={isActive}
                    aria-selected={isActive}
                    {...getStyles('option')}
                >
                    {option ? cloneElement(option, {organization: item, combobox, isActive}) : item.displayName}
                </Combobox.Option>
            );
        }) ?? [];

    useEffect(() => {
        if (options.length <= PER_PAGE) {
            // When options change, we automatically select the first one if there are less than a page of options
            combobox.selectFirstOption();
        }
    }, [options.length]);

    return (
        <Combobox.Options {...getStyles('options')}>
            <InfiniteQueryScrollArea
                {...getStyles('scroll-area')}
                query={organizationListQuery}
                count={() => orgs?.length ?? 0}
            >
                {options.length > 0 ? (
                    options
                ) : (
                    <Skeleton visible={organizationListQuery.isLoading}>
                        <Combobox.Empty {...getStyles('empty')}>
                            {Locales.format('OrganizationPicker.nothingFound')}
                        </Combobox.Empty>
                    </Skeleton>
                )}
            </InfiniteQueryScrollArea>
        </Combobox.Options>
    );
};
