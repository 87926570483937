import {Format, FormatOrFunction, FormatToParts, FormatWithResolvedOptions, SelectFormatterA} from '../../api';
import {BoundFormat, toFormat} from './BoundFormat';

export class ABFormat<TValueA, TValueB> extends BoundFormat<TValueA | TValueB> {
    protected readonly selectA: (value: TValueA | TValueB) => value is TValueA;
    protected readonly a: Format<TValueA>;
    protected readonly b: Format<TValueB>;

    constructor(
        selectA: SelectFormatterA<TValueA | TValueB>,
        a: FormatOrFunction<TValueA>,
        b: FormatOrFunction<TValueB>,
    ) {
        super((value: TValueA | TValueB): string =>
            this.selectA(value) ? this.a.format(value) : this.b.format(value),
        );
        Object.defineProperties(this, {
            selectA: {value: selectA},
            a: {value: toFormat(a)},
            b: {value: toFormat(b)},
        });
    }
}

export class ABFormatWithOptions<TValueA, TOptionsA, TValueB, TOptionsB>
    extends ABFormat<TValueA, TValueB>
    implements FormatWithResolvedOptions<TValueA | TValueB, TOptionsA & TOptionsB>
{
    protected readonly a: Format<TValueA> & Partial<FormatWithResolvedOptions<TValueA, TOptionsA>>;
    protected readonly b: Format<TValueB> & Partial<FormatWithResolvedOptions<TValueB, TOptionsB>>;

    resolvedOptions() {
        // Note:
        //  - support either formatter not actually having resolvedOptions
        //  - ensure the resolved options of a take precedence over those of b.
        return {...this.b.resolvedOptions?.(), ...this.a.resolvedOptions?.()} as TOptionsA & TOptionsB;
    }
}

export class ABFormatToParts<TValueA, TOptionsA, TPartA, TValueB, TOptionsB, TPartB>
    extends ABFormatWithOptions<TValueA, TOptionsA, TValueB, TOptionsB>
    implements FormatToParts<TValueA | TValueB, TOptionsA & TOptionsB, TPartA | TPartB>
{
    protected readonly a: FormatToParts<TValueA, TOptionsA, TPartA>;
    protected readonly b: FormatToParts<TValueB, TOptionsB, TPartB>;

    formatToParts(value: TValueA | TValueB): Array<TPartA | TPartB> {
        return this.selectA(value) ? this.a.formatToParts(value) : this.b.formatToParts(value);
    }
}
