import {useToggle} from '@components/mantine';
import {ReactElement, ReactNode} from 'react';
import {NavigationContext} from '../context/NavigationContext';
import {NavigationSideBar} from './NavigationSideBar';
import {NavigationToggle} from './NavigationToggle';
import {NavigationSearch} from './search';

interface NavigationType {
    (props: NavigationProps): ReactElement;
    SideBar: typeof NavigationSideBar;
    Toggle: typeof NavigationToggle;
}

export interface NavigationProps {
    /**
     * Whether the navigation has a search feature (search box & Meta+K shortcut)
     */
    searchable?: boolean;
    children?: ReactNode;
}

export const Navigation: NavigationType = ({children, searchable}) => {
    const [collapsed, toggleCollapsed] = useToggle();
    return (
        <NavigationContext.Provider value={{collapsed, toggleCollapsed, searchable}}>
            {searchable ? <NavigationSearch.Provider>{children}</NavigationSearch.Provider> : children}
        </NavigationContext.Provider>
    );
};

Navigation.SideBar = NavigationSideBar;
Navigation.Toggle = NavigationToggle;
