import {
    Box,
    BoxProps,
    Tooltip,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
    type MantineSize,
} from '@components/mantine';
import {SourceCategory} from '@core/api';
import {
    CloudSize16Px,
    CloudSize24Px,
    CloudSize32Px,
    CrawlingBotSize16Px,
    CrawlingBotSize24Px,
    CrawlingBotSize32Px,
    DatabaseSize16Px,
    DatabaseSize24Px,
    DatabaseSize32Px,
    PushSize16Px,
    PushSize24Px,
    PushSize32Px,
    type Icon,
} from '@coveord/plasma-react-icons';

import {Locales} from '../../../strings';
import {useSourceIdentityContext} from '../SourceIdentityContext';

type SourceIdentityCategoryIconSize = Extract<MantineSize, 'sm' | 'md' | 'lg'>;
const CATEGORY_IMAGE_MAPPINGS: Record<SourceIdentityCategoryIconSize, Record<SourceCategory, Icon>> = {
    sm: {
        [SourceCategory.CLOUD]: CloudSize16Px,
        [SourceCategory.ON_PREM]: DatabaseSize16Px,
        [SourceCategory.CRAWLING_MODULE]: CrawlingBotSize16Px,
        [SourceCategory.PUSH]: PushSize16Px,
    },
    md: {
        [SourceCategory.CLOUD]: CloudSize24Px,
        [SourceCategory.ON_PREM]: DatabaseSize24Px,
        [SourceCategory.CRAWLING_MODULE]: CrawlingBotSize24Px,
        [SourceCategory.PUSH]: PushSize24Px,
    },
    lg: {
        [SourceCategory.CLOUD]: CloudSize32Px,
        [SourceCategory.ON_PREM]: DatabaseSize32Px,
        [SourceCategory.CRAWLING_MODULE]: CrawlingBotSize32Px,
        [SourceCategory.PUSH]: PushSize32Px,
    },
};

export type SourceIdentityCategoryIconStylesNames = 'categoryIcon';
export type SourceIdentityCategoryIconFactory = Factory<{
    props: SourceIdentityCategoryIconProps;
    ref: HTMLDivElement;
    stylesNames: SourceIdentityCategoryIconStylesNames;
    compound: true;
}>;

export interface SourceIdentityCategoryIconProps
    extends BoxProps,
        CompoundStylesApiProps<SourceIdentityCategoryIconFactory> {
    /**
     * If true, the category icon will be disabled.
     *
     * @default: false
     */
    disabled?: boolean;
    /**
     * Whether to show a tooltip with the category name.
     *
     * @default: false
     */
    showTooltip?: boolean;
    /**
     * The icon size of the category to display.
     *
     * @default: 'md'
     */
    size: SourceIdentityCategoryIconSize;
}

const defaultProps: Partial<SourceIdentityCategoryIconProps> = {
    disabled: false,
    showTooltip: false,
    size: 'md',
};

export const SourceIdentityCategoryIcon = factory<SourceIdentityCategoryIconFactory>((props, ref) => {
    const {disabled, showTooltip, size, ...others} = useProps('SourceIdentityCategoryIcon', defaultProps, props);
    const {category, getStyles} = useSourceIdentityContext();

    if (!category) {
        return null;
    }

    const IconComponent = CATEGORY_IMAGE_MAPPINGS[size][category];

    return (
        <Tooltip label={Locales.format(`SourceIdentity.CategoryIcon.${category}.tooltip`)} disabled={!showTooltip}>
            <Box ref={ref} data-size={size} {...getStyles('categoryIcon')} {...others}>
                <IconComponent data-disabled={disabled} />
            </Box>
        </Tooltip>
    );
});
SourceIdentityCategoryIcon.displayName = '@resourceIdentity/source/SourceIdentity/CategoryIcon';
