import dayjs, {Dayjs} from 'dayjs';
import {
    FormatRange,
    FormatValueType,
    MissingFormatOptions,
    MissingFormatPart,
    MissingValue,
    ResolvedMissingFormatOptions,
} from '../api';
import {MissingFormatRange} from './internal';

type IntlDateTimeFormatValue = Exclude<FormatValueType<Intl.DateTimeFormat>, MissingValue>;
type DateTimeMissingFormatValue = IntlDateTimeFormatValue | Dayjs | string;

interface NormalizeOverloads {
    (value: DateTimeMissingFormatValue): IntlDateTimeFormatValue;
    (value: DateTimeMissingFormatValue | MissingValue): IntlDateTimeFormatValue | MissingValue;
}

const normalize: NormalizeOverloads = (value: DateTimeMissingFormatValue): IntlDateTimeFormatValue =>
    typeof value === 'string' || dayjs.isDayjs(value) ? dayjs(value).toDate() : value;

/**
 * Proxy for {@link Intl.DateTimeFormat} that formats `null` and `undefined` as missing values.
 */
export class DateTimeMissingFormat
    extends MissingFormatRange<
        IntlDateTimeFormatValue,
        Intl.ResolvedDateTimeFormatOptions,
        Intl.DateTimeFormatPart,
        Intl.DateTimeRangeFormatPart
    >
    implements
        FormatRange<
            DateTimeMissingFormatValue | MissingValue,
            Intl.ResolvedDateTimeFormatOptions & ResolvedMissingFormatOptions,
            Intl.DateTimeFormatPart | MissingFormatPart,
            Intl.DateTimeRangeFormatPart
        >
{
    /**
     * Create a new `DateTimeMissingFormat` from an existing `Intl.DateTimeFormat` instance.
     *
     * @param formatter The existing `Intl.DateTimeFormat` instance.
     * @param options Optional {@link MissingFormatOptions}.
     */
    constructor(formatter: Intl.DateTimeFormat, options?: MissingFormatOptions);
    /**
     * Create a new `DateTimeMissingFormat` wrapping a new `Intl.DateTimeFormat` instance.
     *
     * @param locales Optional locales argument, use `undefined` to select the user's current locale.
     * @param options Optional options, which are combined {@link Intl.DateTimeFormatOptions} and {@link MissingFormatOptions}.
     */
    constructor(locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions & MissingFormatOptions);
    constructor(
        localesOrFormat?: Intl.LocalesArgument | Intl.DateTimeFormat,
        options?: Intl.DateTimeFormatOptions & MissingFormatOptions,
    ) {
        super(
            localesOrFormat instanceof Intl.DateTimeFormat
                ? localesOrFormat
                : new Intl.DateTimeFormat(localesOrFormat as string | string[] | undefined, options),
            options,
        );
    }

    format(value: DateTimeMissingFormatValue | MissingValue): string {
        return super.format(normalize(value));
    }

    formatToParts(value: DateTimeMissingFormatValue): Intl.DateTimeFormatPart[];
    formatToParts(value: MissingValue): [MissingFormatPart];
    formatToParts(value: DateTimeMissingFormatValue | MissingValue): Intl.DateTimeFormatPart[] | [MissingFormatPart];
    formatToParts(value: DateTimeMissingFormatValue | MissingValue): Intl.DateTimeFormatPart[] | [MissingFormatPart] {
        return super.formatToParts(normalize(value));
    }

    formatRange(
        start: Exclude<DateTimeMissingFormatValue, MissingValue>,
        end: Exclude<DateTimeMissingFormatValue, MissingValue>,
    ): string {
        return super.formatRange(normalize(start), normalize(end));
    }

    formatRangeToParts(
        start: Exclude<DateTimeMissingFormatValue, MissingValue>,
        end: Exclude<DateTimeMissingFormatValue, MissingValue>,
    ): Intl.DateTimeRangeFormatPart[] {
        return super.formatRangeToParts(normalize(start), normalize(end));
    }
}
