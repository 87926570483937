import {ScrollArea, ScrollAreaProps} from '@coveord/plasma-mantine';
import {forwardRef} from 'react';

export const FacetScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
    ({style, ...others}: ScrollAreaProps, ref) => (
        <ScrollArea {...others} style={{width: '100%', ...style}} viewportRef={ref}>
            {others.children}
        </ScrollArea>
    ),
);
