import {
    Box,
    BoxProps,
    Divider,
    Factory,
    factory,
    List,
    Stack,
    StylesApiProps,
    Title,
    useProps,
} from '@coveord/plasma-mantine';
import {Children, ComponentProps, ReactElement, ReactNode} from 'react';
import {useEmptyStateContext} from './EmptyStateContext';
import {EmptyStatePrerequisiteListItem} from './EmptyStatePrerequisiteListItem';

export type PrerequisiteListStylesName = 'prerequisiteList' | 'prerequisiteTitle' | 'prerequisiteListItemContainer';

export interface PrerequisiteListProps extends BoxProps, StylesApiProps<PrerequisiteListFactory> {
    /**
     * The prerequisite list title.
     */
    title: string;
    /**
     * The static items list title.
     */
    staticItemsTitle?: string;

    /**
     * The prerequisite items to display.
     */
    children: ReactNode;
}

export type PrerequisiteListFactory = Factory<{
    props: PrerequisiteListProps;
    ref: HTMLDivElement;
    stylesNames: PrerequisiteListStylesName;
    compound: true;
}>;

const defaultProps: Partial<PrerequisiteListProps> = {};

export const EmptyStatePrerequisiteList = factory<PrerequisiteListFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const props = useProps('EmptyStatePrerequisiteList', defaultProps, _props);
    const {title, staticItemsTitle, children, classNames, className, styles, style} = props;

    const items = (
        Children.toArray(children) as Array<ReactElement<ComponentProps<typeof EmptyStatePrerequisiteListItem>>>
    ).filter((child) => child.type === EmptyStatePrerequisiteListItem);

    const prerequisites = items.filter((item) => item.props.status !== 'static');
    const suggestions = items.filter((item) => item.props.status === 'static');

    const stylesApiProps = {classNames, styles};

    return (
        <Box>
            <Stack
                ref={ref}
                gap="sm"
                p="sm"
                pr="md"
                display="inline-flex"
                {...ctx.getStyles('prerequisiteList', {...stylesApiProps, className, style})}
            >
                <Title order={5} {...ctx.getStyles('prerequisiteTitle', stylesApiProps)}>
                    {title}
                </Title>
                <List spacing="xs" center {...ctx.getStyles('prerequisiteListItemContainer', stylesApiProps)}>
                    {prerequisites}
                </List>
                {suggestions.length > 0 && prerequisites.length > 0 && <Divider />}
                {suggestions.length > 0 && (
                    <>
                        <Title order={5} {...ctx.getStyles('prerequisiteTitle', stylesApiProps)}>
                            {staticItemsTitle}
                        </Title>
                        <List
                            spacing="xs"
                            center
                            {...ctx.getStyles('prerequisiteListItemContainer', stylesApiProps)}
                            listStyleType="disc"
                        >
                            {suggestions}
                        </List>
                    </>
                )}
            </Stack>
        </Box>
    );
});
