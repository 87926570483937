import {Box, Flex, Text, Title} from '@components/mantine';
import {FunctionComponent} from 'react';

import {Locales} from '../../strings';
import notFound from './not-found.png';

interface NotFoundContentProps {
    variant?: 'internal-page' | 'external-page';
}

export const NotFoundContent: FunctionComponent<NotFoundContentProps> = ({variant = 'internal-page'}) => {
    if (variant === 'external-page') {
        return (
            <Box>
                <Title order={1}>{Locales.format('notFound.title')}</Title>
                <Text size="sm">{Locales.format('notFound.description.external-page')}</Text>
            </Box>
        );
    }

    return (
        <Flex mt="xl" gap="xl">
            <img src={notFound} style={{width: '255px', height: '285px'}} alt="not found" />
            <Box pt="xl">
                <Title order={1}>{Locales.format('notFound.title')}</Title>
                <Text size="sm">{Locales.format('notFound.description.internal-page')}</Text>
            </Box>
        </Flex>
    );
};
