import {
    Image,
    ImageCssVariables,
    ImageProps,
    PolymorphicFactory,
    polymorphicFactory,
    useProps,
} from '@coveord/plasma-mantine';
import notFoundSvg from '../../images/not-found.svg';
import notAvailableSvg from '../../images/not-available.svg';
import {useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateImageStylesNames = 'image';

export type EmptyStateImageIllustration = 'not-available' | 'not-found';

export interface EmptyStateImageProps extends ImageProps {
    /**
     * Illustration to display in the empty state image.
     * The EmptyState comes with built-in illustrations, use this prop to select one.
     * Otherwise you can provide your own image via the `src` prop.
     */
    illustration?: EmptyStateImageIllustration;
}

export type EmptyStateImageFactory = PolymorphicFactory<{
    props: EmptyStateImageProps;
    defaultComponent: 'img';
    defaultRef: HTMLImageElement;
    stylesNames: EmptyStateImageStylesNames;
    vars: ImageCssVariables;
    compound: true;
}>;

const defaultProps: Partial<EmptyStateImageProps> = {
    illustration: 'not-found',
};

const emptyStateIllustrations: Record<EmptyStateImageIllustration, string> = {
    'not-available': notAvailableSvg,
    'not-found': notFoundSvg,
};

export const EmptyStateImage: ReturnType<typeof polymorphicFactory<EmptyStateImageFactory>> =
    polymorphicFactory<EmptyStateImageFactory>((_props, ref) => {
        const ctx = useEmptyStateContext();
        const {style, className, classNames, styles, unstyled, illustration, ...others} = useProps(
            'EmptyStateImage',
            defaultProps,
            _props,
        );

        return (
            <Image
                ref={ref}
                src={illustration ? emptyStateIllustrations[illustration] : undefined}
                fit="cover"
                {...ctx.getStyles('image', {classNames, className, styles, style})}
                {...others}
            />
        );
    });
