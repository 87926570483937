import {clsx} from 'clsx';
import InfiniteScrollWrapper, {Props as ReactInfiniteScrollProps} from 'react-infinite-scroll-component';
import {createContext, forwardRef, useContext, ReactNode, ForwardedRef} from 'react';
import {Box, BoxProps, Center, Loader, useId} from '@coveord/plasma-mantine';
import classes from './InfiniteScroll.module.css';

export type InfiniteScrollContextType = Omit<
    ReactInfiniteScrollProps,
    'children' | 'scrollableTarget' | 'loader'
> | null;
const InfiniteScrollContext = createContext<InfiniteScrollContextType>(null);
const InfiniteScrollProvider = ({children, ...props}: InfiniteScrollContextType & {children: ReactNode}) => (
    <InfiniteScrollContext.Provider value={props}>{children}</InfiniteScrollContext.Provider>
);
export interface InfiniteScrollProps extends BoxProps {
    children: ReactNode;
}

const _InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(
    ({children, ...props}: InfiniteScrollProps, ref: ForwardedRef<HTMLDivElement>) => {
        const id = useId();
        const infiniteScroll = useContext(InfiniteScrollContext);
        if (infiniteScroll === null) {
            throw new Error('InfiniteScroll must be wrapped by an InfiniteScroll.Provider');
        }

        return (
            <Box {...props} className={clsx(classes.root, props.className)} id={id} ref={ref}>
                <InfiniteScrollWrapper
                    loader={
                        <Center>
                            <Loader />
                        </Center>
                    }
                    scrollableTarget={id}
                    {...infiniteScroll}
                >
                    {children}
                </InfiniteScrollWrapper>
            </Box>
        );
    },
) as any;
_InfiniteScroll.Provider = InfiniteScrollProvider;
_InfiniteScroll.displayName = '@components/mantine/InfiniteScroll';

export const InfiniteScroll: React.ForwardRefExoticComponent<
    InfiniteScrollProps & React.RefAttributes<HTMLDivElement>
> & {Provider: typeof InfiniteScrollProvider} = _InfiniteScroll;
