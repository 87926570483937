import {LaunchDarklyFeatureFlags, LDProject} from '@core/feature-flags';
import {UserSelectors} from '@core/user';
import {createSelector} from 'reselect';

const userCanViewMerchHub = createSelector(
    UserSelectors.getPrivilegesValidator,
    (user) => user.canViewMerchandisingHub,
);

const userCanViewKnowledgeHub = createSelector(UserSelectors.getPrivilegesValidator, (user) => {
    const isEnabledAnswerAPI = LaunchDarklyFeatureFlags.isActive('enable-answer-api', LDProject.CUSTOMER_SERVICE);
    return isEnabledAnswerAPI && user.canViewKnowledgeHub;
});

const canViewPlatform = createSelector(UserSelectors.getPrivilegesValidator, (user) => user.canViewOrganization);

export const AppSwitcherSelectors = {
    canAccessAppSelectors: {
        platform: canViewPlatform,
        'commerce-hub': userCanViewMerchHub,
        'knowledge-hub': userCanViewKnowledgeHub,
    },
};
