import {setTag} from '@sentry/react';
import {createContext, Dispatch, FunctionComponent, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {Team} from '../data';

export type Framework = 'Mantine' | 'Backbone' | 'PlasmaReact';

export interface OwnedProps {
    /**
     * The owner of the component
     */
    owner: Team;
    /**
     * The framework of the component
     */
    framework: Framework | Framework[];
    /**
     * Whether the component is invisible to the user.
     * Components that are invisible to the user are not considered by the BugReporter button since the user realistically won't want to report a bug with something invisible.
     */
    invisible?: boolean;
}

/**
 * State of ownership of a component
 */
export interface Ownership {
    owner: Team | null;
    framework: Framework | Framework[] | null;
}

export interface OwnershipContextValue extends Ownership {
    update: Dispatch<SetStateAction<Ownership | null>>;
}

export const OwnershipContext = createContext<OwnershipContextValue | null>({
    framework: null,
    owner: null,
    update: () => undefined,
});

interface OwnershipProviderProps {
    children: ReactNode;
    defaultOwner?: Team;
    defaultFramework?: Framework | Framework[];
}

export const OwnershipProvider: FunctionComponent<OwnershipProviderProps> = ({
    children,
    defaultOwner,
    defaultFramework,
}) => {
    const [{owner, framework}, setOwnership] = useState<Ownership>({
        owner: defaultOwner ?? null,
        framework: defaultFramework ?? null,
    });

    useEffect(() => {
        if (owner) {
            setTag('owner', owner);
        }
    }, [owner]);

    return (
        <OwnershipContext.Provider value={{framework, owner, update: setOwnership}}>
            {children}
        </OwnershipContext.Provider>
    );
};
