import {IdAndDisplayNameModel} from '@core/api';

const noAccess: IdAndDisplayNameModel[] = [];

/**
 * A temporary id used to properly determine the groups that can edit a resource that is not yet created
 */
const newResourceTempId = '$new$';

export const AccessTableConstants = {
    noAccess,
    newResourceTempId,
};
