import {GenericError} from '@core/api';
import {NotificationData, notifications as mantineNotifications} from '@coveord/plasma-mantine';
import {CheckSize24Px, CriticalSize24Px, WarningSize24Px} from '@coveord/plasma-react-icons';

import {NotificationTracking} from '../tracking';

const errorRegistry = new Set<string>();

// An improved type of Omit https://github.com/microsoft/TypeScript/issues/54451#issue-1732749888
type MappedOmit<T, K extends keyof T> = {[P in keyof T as P extends K ? never : P]: T[P]};

const showErrorNotification = (
    notification: string | MappedOmit<NotificationData, 'color' | 'icon' | 'autoClose'>,
    error?: GenericError,
) => {
    let notificationProps: NotificationData;

    if (typeof notification === 'string') {
        notificationProps = {
            id: notification,
            message: notification,
            color: 'critical',
            icon: <CriticalSize24Px height={24} />,
            autoClose: false,
            onClose: (props) => {
                errorRegistry.delete(props.id);
            },
            'data-variant': 'error',
        };
    } else {
        notificationProps = {
            ...notification,
            color: 'critical',
            icon: <CriticalSize24Px height={24} />,
            autoClose: false,
            onClose: (props) => {
                if (props.id) {
                    errorRegistry.delete(props.id);
                }
                notification?.onClose?.(props);
            },
            'data-variant': 'error',
        };
    }

    // prevents from showing the same error notification twice when it can easily be uniquely distinguished
    if (!notificationProps.id || !errorRegistry.has(notificationProps.id)) {
        if (notificationProps.id) {
            errorRegistry.add(notificationProps.id);
        }
        mantineNotifications.show(notificationProps);
        NotificationTracking.errorShown({...notificationProps, ...error});
    }
};

const showSuccessNotification = (notification: string | MappedOmit<NotificationData, 'color' | 'icon'>) => {
    if (typeof notification === 'string') {
        mantineNotifications.show({
            message: notification,
            color: 'success',
            icon: <CheckSize24Px height={24} />,
            'data-variant': 'success',
        });
    } else {
        mantineNotifications.show({
            ...notification,
            color: 'success',
            icon: <CheckSize24Px height={24} />,
            'data-variant': 'success',
        });
    }
};

const showWarningNotification = (notification: string | MappedOmit<NotificationData, 'color' | 'icon'>) => {
    if (typeof notification === 'string') {
        mantineNotifications.show({
            message: notification,
            color: 'warning',
            icon: <WarningSize24Px height={24} />,
            'data-variant': 'warning',
        });
    } else {
        mantineNotifications.show({
            ...notification,
            color: 'warning',
            icon: <WarningSize24Px height={24} />,
            'data-variant': 'warning',
        });
    }
};

const cleanNotifications = () => {
    errorRegistry.clear();
    mantineNotifications.clean();
};

const cleanQueueNotifications = () => {
    errorRegistry.clear();
    mantineNotifications.cleanQueue();
};

export const notifications = {
    ...mantineNotifications,

    /**
     * Removes all notifications from the notifications state, queue and errorRegistry
     * @example ```ts
     * notifications.clean();
     * ```
     */
    clean: cleanNotifications,
    /**
     * Removes all notifications from the queue and errorRegistry
     * @example ```ts
     * notifications.cleanQueue();
     * ```
     */
    cleanQueue: cleanQueueNotifications,
    /**
     * Shows an error notification
     *
     * @param notification The error notification to display
     * @example ```ts
     * notifications.showError('Unexpected error');
     * notifications.showError({title: 'Unexpected error', message: 'Description of the error'});
     *
     * // when using an object, its best to set an id to avoid spamming the same error notification more than once
     * notifications.showError({id: 'error-xyz', title: 'Unexpected error', message: 'Description of the error'});
     * ```
     */
    showError: showErrorNotification,
    /**
     * Shows a success notification
     *
     * @param notification The success notification to display
     * @example ```ts
     * notifications.showSuccess('Successfully saved');
     * notifications.showSuccess({title: 'Success!', message: 'The resource was successfully saved.'});
     * ```
     */
    showSuccess: showSuccessNotification,
    /**
     * Shows a warning notification
     *
     * @param notification The warning notification to display
     * @example ```ts
     * notifications.showWarning('Watch out!');
     * notifications.showWarning({title: 'Watch out!', message: 'Your config might be wrong.'});
     * ```
     */
    showWarning: showWarningNotification,
};
