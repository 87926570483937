import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {UserFeedbackSurvey} from '../interfaces';
import {UserFeedbackModalUtils} from './UserFeedbackModalUtils';

dayjs.extend(duration);

const show = (feedback: UserFeedbackSurvey) => {
    const dateSkipped = dayjs(UserFeedbackModalUtils.getSurveySkippedDate({item: feedback.id, type: feedback.type}));
    const daysSinceSurveySkipped = dayjs.duration(dayjs().diff(dateSkipped)).asDays();
    if (
        UserFeedbackModalUtils.hasCompletedSurvey({item: feedback.id, type: feedback.type}) ||
        UserFeedbackModalUtils.getShouldNotAskAgainSurvey({item: feedback.id, type: feedback.type}) ||
        !UserFeedbackModalUtils.isReadyForASurvey()
    ) {
        return;
    }
    if (!dayjs(daysSinceSurveySkipped).isValid() || daysSinceSurveySkipped > 1) {
        const showSurveyEvent = new CustomEvent('show_survey', {detail: feedback});
        document.dispatchEvent(showSurveyEvent);
    }
};

const hide = () => {
    const hideSurveyEvent = new Event('hide_survey');
    document.dispatchEvent(hideSurveyEvent);
};

export const survey = {
    show,
    hide,
};
