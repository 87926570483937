import {ActionIcon, Tooltip} from '@components/mantine';
import {UndoSize16Px} from '@coveord/plasma-react-icons';
import {FunctionComponent} from 'react';
import {Locales} from '../../strings/Locales';
import {PrivilegeRow} from './PrivilegesTable.types';
import {PrivilegesTableUtils} from './PrivilegesTableUtils';
import {usePrivilegeRow} from './PrivilegeTableContext';

export const UndoButton: FunctionComponent<PrivilegeRow> = ({id, name, initialPrivileges}) => {
    const {onChange, selectedPrivileges, readOnly} = usePrivilegeRow(id);
    return !readOnly && PrivilegesTableUtils.hasChanged(initialPrivileges, selectedPrivileges) ? (
        <Tooltip label={Locales.format('PrivilegesTable.undoButton.tooltip', {name})}>
            <ActionIcon variant="subtle" color="gray" onClick={() => onChange?.(initialPrivileges)}>
                <UndoSize16Px height={16} />
            </ActionIcon>
        </Tooltip>
    ) : null;
};
