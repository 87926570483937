import {
    Box,
    BoxProps,
    Text,
    Tooltip,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
} from '@components/mantine';
import {useRef, useState} from 'react';

import {useSourceIdentityContext} from '../SourceIdentityContext';

export type SourceIdentityNameStylesNames = 'name';

export type SourceIdentityNameFactory = Factory<{
    props: SourceIdentityNameProps;
    ref: HTMLDivElement;
    stylesNames: SourceIdentityNameStylesNames;
    compound: true;
}>;

export interface SourceIdentityNameProps extends BoxProps, CompoundStylesApiProps<SourceIdentityNameFactory> {}

const defaultProps: Partial<SourceIdentityNameProps> = {};

// @todo: [CTCORE-10030] Creating a generic TextEllipsis component in Plasma
export const SourceIdentityName = factory<SourceIdentityNameFactory>((_props, ref) => {
    const {maw, ...props} = useProps('SourceIdentityName', defaultProps, _props);
    const {getStyles, name} = useSourceIdentityContext();
    const [showTooltip, setShowTooltip] = useState(false);
    const textRef = useRef<HTMLDivElement | null>(null);

    if (!name) {
        return null;
    }

    return (
        <Box
            ref={ref}
            {...props}
            maw={maw ?? 250}
            flex={1}
            onMouseEnter={() => {
                if (textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth) {
                    setShowTooltip(true);
                }
            }}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <Tooltip label={name} {...getStyles('name')} opened={showTooltip}>
                <Text ref={textRef} truncate="end">
                    {name}
                </Text>
            </Tooltip>
        </Box>
    );
});
SourceIdentityName.displayName = '@resourceIdentity/source/SourceIdentity/Name';
