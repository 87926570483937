import {Checkbox} from '@coveord/plasma-mantine';
import {ComponentProps} from 'react';
import {Controller} from 'react-hook-form';
import {BasicReactHookFormPropsWrappedComponents} from '../interfaces';

export interface RHFCheckboxProps extends ComponentProps<typeof Checkbox>, BasicReactHookFormPropsWrappedComponents {}

export const RHFCheckbox = ({
    control,
    onBlur: externalOnBlur,
    onChange: externalOnChange,
    ...restProps
}: RHFCheckboxProps) => (
    <Controller
        control={control}
        name={restProps.name}
        render={({field: {value, onBlur, onChange, ...restField}, fieldState}) => (
            <Checkbox
                checked={value}
                {...restField}
                error={fieldState.error?.message}
                onBlur={(event) => {
                    externalOnBlur?.(event);
                    onBlur();
                }}
                onChange={(event) => {
                    externalOnChange?.(event);
                    onChange(event);
                }}
                {...restProps}
            />
        )}
    />
);

RHFCheckbox.Group = Checkbox.Group;
