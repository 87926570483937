import {API, Resource} from '@coveo/platform-client';
import {
    ChunkInspectorAnswerParams,
    ChunkInspectorStartJobResponse,
    ChunkInspectorStatusResponse,
    QueryChunkResponse,
} from '../../../interfaces/knowledge/ChunkInspectorTypes';

export class ChunkInspectorAnswerResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/chunkinspector/answerquery/v1/chunkretrievaljobs`;

    startJob(params: ChunkInspectorAnswerParams): Promise<ChunkInspectorStartJobResponse> {
        return this.api.post<ChunkInspectorStartJobResponse>(`${ChunkInspectorAnswerResource.baseUrl}`, params);
    }

    getJobStatus(jobId: string): Promise<ChunkInspectorStatusResponse> {
        return this.api.get<ChunkInspectorStatusResponse>(`${ChunkInspectorAnswerResource.baseUrl}/${jobId}/status`);
    }

    getChunks(jobId: string): Promise<QueryChunkResponse> {
        return this.api.get<QueryChunkResponse>(`${ChunkInspectorAnswerResource.baseUrl}/${jobId}/result`);
    }
}
