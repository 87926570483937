import {API, Paginated, Resource} from '@coveo/platform-client';
import {
    DataServiceColumnType,
    DataServiceListTablesResponseModel,
    DataServiceExportQueryModel,
    DataServiceExportResponseModel,
    DataServiceQueryModel,
    DataServiceQueryResponseModel,
    DataServiceQueriesErrorResponseListItem,
    DataServiceQueriesResponseListItem,
    DataServiceQueriesResponseModelWithErrors,
    DataServiceQueriesResponseModel,
    DataServiceTrend,
} from '../interfaces/dataservice';

export type DataServiceMapQueryResponseModel<
    CT extends readonly DataServiceColumnType[],
    TR extends readonly DataServiceTrend[],
> = DataServiceQueryResponseModel<[...CT, ...{[I in keyof TR]: 'FLOAT'}]>;

type DataServiceQueriesBodyBound = ReadonlyArray<
    Readonly<DataServiceQueryModel<readonly DataServiceColumnType[], readonly DataServiceTrend[]>>
>;

type DataServiceMapQueriesResponseModel<T extends DataServiceQueriesBodyBound> =
    DataServiceQueriesResponseModelWithErrors<{
        [I in keyof T]: T[I] extends DataServiceQueryModel<infer CT, infer TR>
            ? DataServiceMapQueryResponseModel<CT, TR>
            : DataServiceQueryModel;
    }>;

const isErrorResponseListItem = (
    subject: DataServiceQueriesResponseListItem,
): subject is DataServiceQueriesErrorResponseListItem => 'errorInfo' in subject;

const isSuccessResponse = <T extends readonly DataServiceQueryResponseModel[]>(
    response: DataServiceQueriesResponseModelWithErrors<T>,
): response is DataServiceQueriesResponseModel<T> => !response.queryResponses.some(isErrorResponseListItem);

/**
 * The queries endpoint may return errors among the responses, but dealing with that in the "success path" is akward.
 * This method turns any errors into a promise rejection instead.
 *
 * @param apiPromise
 * @returns
 */
const processQueriesResponse = async <T extends DataServiceQueriesBodyBound>(
    apiPromise: Promise<DataServiceMapQueriesResponseModel<T>>,
) => {
    const response = await apiPromise;

    if (isSuccessResponse(response)) {
        return response;
    }
    // Throw an Error, but also forward the queryResponses property on it.
    throw Object.defineProperty(
        new Error(
            response.queryResponses.reduce((message, listItem, index) => {
                if (isErrorResponseListItem(listItem)) {
                    return message + `\n [${index}] ${listItem.errorInfo.errorCode}`;
                }
                return message;
            }, 'Queries encountered one or more errors:'),
        ),
        'queryResponses',
        {enumerable: true, value: response.queryResponses},
    );
};

/**
 * Data Service is a Coveo internal endpoint that allows querying data for reporting purposes.
 *
 * The Swagger definition (available only in dev): {@link https://platformdev.cloud.coveo.com/docs/?urls.primaryName=Data%20Service#/Data}.
 * More detailed Confluence documentation: {@link https://coveord.atlassian.net/wiki/spaces/DATA/pages/3720937473/Data+Service+documentation}.
 */
export class DataServiceResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/data/v1`;

    /**
     * List the tables available to the current user and organization.
     *
     * @param pagination Optional pagination query parameters.
     * @param args Optional RequestInit args, most often used to pass an abort signal.
     * @returns The response{@link DataServiceListTablesResponseModel}.
     */
    listTables(pagination?: Paginated, args?: RequestInit) {
        return this.api.get<DataServiceListTablesResponseModel>(
            super.buildPath(`${DataServiceResource.baseUrl}/tables`, pagination),
            args,
        );
    }

    query<CT extends readonly DataServiceColumnType[], TR extends readonly DataServiceTrend[]>(
        body: Readonly<DataServiceQueryModel<CT, TR>>,
        args?: RequestInit,
    ) {
        return this.api.post<DataServiceMapQueryResponseModel<CT, TR>>(
            super.buildPath(`${DataServiceResource.baseUrl}/query`),
            body,
            args,
        );
    }

    queries<T extends DataServiceQueriesBodyBound>(body: [...T], args?: RequestInit) {
        return processQueriesResponse(
            this.api.post<DataServiceMapQueriesResponseModel<T>>(
                super.buildPath(`${DataServiceResource.baseUrl}/queries`),
                body,
                args,
            ),
        );
    }

    export<CT extends readonly DataServiceColumnType[], TR extends readonly DataServiceTrend[]>(
        body: Readonly<DataServiceExportQueryModel<CT, TR>>,
        args?: RequestInit,
    ) {
        return this.api.post<DataServiceExportResponseModel>(
            super.buildPath(`${DataServiceResource.baseUrl}/export`),
            body,
            args,
        );
    }
}
