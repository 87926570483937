import {
    FormatRange,
    FormatValueType,
    isNumberAutoScaleFormatAutoUnit,
    MissingValue,
    NumberAutoScaleFormatOptions,
    NumberAutoScaleSimpleUnit,
    parseNumberAutoScaleFormatAutoUnit,
} from '../../api';

type IntlNumberFormatValue = Exclude<FormatValueType<Intl.NumberFormat>, MissingValue>;

interface RelativeUnitDefinition {
    /** The name assigned to the scale, which most of the time aligns with the unit. */
    readonly name: string;
    /** The simple unit. */
    readonly unit: NumberAutoScaleSimpleUnit;
    /** The relative scale from to the previous unit, use `1` for the first unit in the list. */
    readonly deltaFactor: number;
}

const UNIT_GROUPS = new Map<string, readonly RelativeUnitDefinition[]>();
const UNIT_TO_GROUP_NAME = new Map<string, string>();

const isNumberAutoScaleFormatOptions = (
    options: Intl.NumberFormatOptions | undefined,
): options is NumberAutoScaleFormatOptions =>
    !!options && options.style === 'unit' && isNumberAutoScaleFormatAutoUnit(options.unit);

export const createNumberFormat = (
    locales?: Intl.LocalesArgument,
    options?: Intl.NumberFormatOptions | NumberAutoScaleFormatOptions,
): Intl.NumberFormat | NumberAutoScaleFormat =>
    isNumberAutoScaleFormatOptions(options)
        ? new NumberAutoScaleFormat(locales, options)
        : new Intl.NumberFormat(locales, options);

interface SelectFunction {
    (value: IntlNumberFormatValue): [Intl.NumberFormat, number | bigint];
    (
        startValue: IntlNumberFormatValue,
        endValue: IntlNumberFormatValue,
    ): [Intl.NumberFormat, number | bigint, number | bigint];
    (
        startValue: IntlNumberFormatValue,
        endValue?: IntlNumberFormatValue,
    ): [Intl.NumberFormat, number | bigint] | [Intl.NumberFormat, number | bigint, number | bigint];
}

export class NumberAutoScaleFormat
    implements
        FormatRange<
            IntlNumberFormatValue,
            Intl.ResolvedNumberFormatOptions,
            Intl.NumberFormatPart,
            Intl.NumberRangeFormatPart
        >
{
    protected readonly select: SelectFunction;
    protected readonly unit: string;

    constructor(locales: Intl.LocalesArgument | undefined, options: NumberAutoScaleFormatOptions) {
        // Even though the TypeScript signature forces passing the correct options, validate.
        if (!isNumberAutoScaleFormatOptions(options)) {
            throw new TypeError(
                'NumberAutoScaleFormat requires options that have style: "unit" and a valid auto-scale unit in the form of "auto-<auto_unit>(-per-<unit>)"',
            );
        }
        const match = parseNumberAutoScaleFormatAutoUnit(options.unit);
        if (!match) {
            throw new RangeError(`Unit ${options.unit} is not supported for auto-scale`);
        }
        let scales = UNIT_GROUPS.get(UNIT_TO_GROUP_NAME.get(match[1]) ?? '');
        // Note: the else branch throws, but using the if branch to scope the i variable too.
        if (scales) {
            const i = scales.findIndex((scale) => scale.name === match[1]);
            if (i > 0) {
                scales = Object.freeze(scales.slice(i));
            }
        } else {
            throw new RangeError(`Unit ${match[1]}} is not supported for auto-scale`);
        }
        const intlOptions = {...options, unit: scales[0].unit + match[2]};
        const cache = new Array(Math.max(scales.length, 1)).fill(null) as [
            Intl.NumberFormat,
            ...Array<Intl.NumberFormat | null>,
        ];
        cache[0] = new Intl.NumberFormat(locales, intlOptions);

        const select = ((startValue, endValue?) => {
            /*
             * Have to lie about the type since TypeScript doesn't understand that it's truly EITHER bigint or number,
             * with all values being of the same (single) type. And thus it will complain that we can't multiply
             * `bigint | number` with `bigint | number` on any operation. To work around this, coerce the type to "bigint",
             * because it is more restricted. This way, TypeScript checks no operations are used that do not work with
             * bigint. (For example, none of the Math methods, such as Math.abs, accept bigint values).
             */
            const normalize = typeof startValue === 'bigint' ? BigInt : (Number as unknown as BigIntConstructor);
            // Always use start value. For ranges, startValue must be <= endValue, so no need to search the minimum.
            // Also, always use the same scale for both values, since comparing two different scales is confusing.
            const value = normalize(startValue);
            let i = 0;
            // Note: this purposefully scales factor, which can only be integer multiplication, and then does a single division.
            // This lowers the effect of float rounding errors compared to repeated divisions of startValue and endValue.
            let factor = normalize(1);
            if (typeof value === 'bigint' || (!Number.isNaN(value) && Number.isFinite(value))) {
                const valueAbs = value < normalize(0) ? -value : value;
                while (i + 1 < scales.length && valueAbs >= factor * normalize(scales[i + 1].deltaFactor)) {
                    ++i;
                    factor *= normalize(scales[i].deltaFactor);
                }
            }
            let fmt = cache[i];
            if (!fmt) {
                intlOptions.unit = scales[i].unit + match[2];
                fmt = new Intl.NumberFormat(locales, intlOptions);
                cache[i] = fmt;
            }
            return endValue === undefined ? [fmt, value / factor] : [fmt, value / factor, normalize(endValue) / factor];
        }) as SelectFunction;

        Object.defineProperties(this, {
            select: {value: select},
            unit: {value: match[0].toLowerCase()},
        });
    }

    resolvedOptions(): Intl.ResolvedNumberFormatOptions {
        return {...this.select(NaN)[0].resolvedOptions(), unit: this.unit};
    }

    format(value: IntlNumberFormatValue): string {
        const [fmt, v] = this.select(value);
        return fmt.format(v);
    }

    formatToParts(value: IntlNumberFormatValue): Intl.NumberFormatPart[] {
        const [fmt, v] = this.select(value);
        return fmt.formatToParts(v);
    }

    formatRange(start: IntlNumberFormatValue, end: IntlNumberFormatValue): string {
        const [fmt, s, e] = this.select(start, end);
        return fmt.formatRange(s, e);
    }

    formatRangeToParts(start: IntlNumberFormatValue, end: IntlNumberFormatValue): Intl.NumberRangeFormatPart[] {
        const [fmt, s, e] = this.select(start, end);
        return fmt.formatRangeToParts(s, e);
    }
}

// Unit group definitions

const define: {
    (name: NumberAutoScaleSimpleUnit, deltaFactor: number): RelativeUnitDefinition;
    (name: string, deltaFactor: number, unit: NumberAutoScaleSimpleUnit): RelativeUnitDefinition;
} = (
    name: string,
    deltaFactor: number,
    unit: NumberAutoScaleSimpleUnit = name as NumberAutoScaleSimpleUnit,
): RelativeUnitDefinition => {
    if (!Number.isInteger(deltaFactor)) {
        throw new RangeError('Only integer factors are supported.');
    }
    return Object.freeze({name, unit, deltaFactor});
};

const add = <T>(map: Map<string, T>, key: string, value: T): void => {
    if (map.has(key)) {
        throw new Error(`Duplicate key ${key}`);
    }
    map.set(key, value);
};

add(
    UNIT_GROUPS,
    'bit',
    Object.freeze<RelativeUnitDefinition[]>([
        define('bit', 1),
        define('kilobit', 1000),
        define('megabit', 1000),
        define('gigabit', 1000),
        define('terabit', 1000),
    ]),
);
add(
    UNIT_GROUPS,
    'byte',
    Object.freeze<RelativeUnitDefinition[]>([
        define('byte', 1),
        define('kilobyte', 1000),
        define('megabyte', 1000),
        define('gigabyte', 1000),
        define('terabyte', 1000),
        define('petabyte', 1000),
    ]),
);
/*
 * This is using the powers of 1024 scale, while still using the SI prefixes.
 * Intl doesn't (yet) support Ki/Mi/Gi/Ti/Pi units, and it's even still common practice to use
 * the k/M/G/T/P prefixes with power of 1024 scale in certain contexts (RAM memory size).
 * Windows 11 also still displays file sizes using powers of 1024 but with the "wrong" prefixes.
 */
add(
    UNIT_GROUPS,
    'binary-byte',
    Object.freeze<RelativeUnitDefinition[]>([
        define('bibyte', 1, 'byte'),
        define('kibibyte', 1024, 'kilobyte'),
        define('mebibyte', 1024, 'megabyte'),
        define('gibibyte', 1024, 'gigabyte'),
        define('tebibyte', 1024, 'terabyte'),
        define('pebibyte', 1024, 'petabyte'),
    ]),
);
add(
    UNIT_GROUPS,
    'weight-metric',
    Object.freeze<RelativeUnitDefinition[]>([define('gram', 1), define('kilogram', 1000)]),
);
add(
    UNIT_GROUPS,
    'weight-imperial',
    Object.freeze<RelativeUnitDefinition[]>([define('ounce', 1), define('pound', 16), define('stone', 14)]),
);
add(
    UNIT_GROUPS,
    'volume-metric',
    Object.freeze<RelativeUnitDefinition[]>([define('milliliter', 1), define('liter', 1000)]),
);
add(
    UNIT_GROUPS,
    'length-metric',
    Object.freeze<RelativeUnitDefinition[]>([
        define('millimeter', 1),
        define('centimeter', 10),
        define('meter', 100),
        define('kilometer', 1000),
    ]),
);
add(
    UNIT_GROUPS,
    'length-imperial',
    Object.freeze<RelativeUnitDefinition[]>([
        define('inch', 1),
        define('foot', 12),
        define('yard', 3),
        define('mile', 1760),
    ]),
);
add(
    UNIT_GROUPS,
    'time',
    Object.freeze<RelativeUnitDefinition[]>([
        define('millisecond', 1),
        define('second', 1000),
        define('minute', 60),
        define('hour', 60),
        define('day', 24),
        define('week', 7),
    ]),
);

UNIT_GROUPS.forEach((units, scaleGroup) => units.forEach(({name}) => add(UNIT_TO_GROUP_NAME, name, scaleGroup)));
