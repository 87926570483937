import {API, Resource} from '@coveo/platform-client';
import {AnswerConfiguration, AnswerPage, AnswerPagingParams} from '../../../interfaces/knowledge/AnswerInterfaces';

export class AnswerConfigurationResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/answer/v1/configs`;

    get(id: string): Promise<AnswerConfiguration> {
        return this.api.get<AnswerConfiguration>(`${AnswerConfigurationResource.baseUrl}/${id}`);
    }

    list(options?: AnswerPagingParams): Promise<AnswerPage<AnswerConfiguration>> {
        return this.api.get<AnswerPage<AnswerConfiguration>>(
            this.buildPath(AnswerConfigurationResource.baseUrl, options),
        );
    }

    create(name: string): Promise<AnswerConfiguration> {
        return this.api.post<AnswerConfiguration>(AnswerConfigurationResource.baseUrl, {name});
    }

    edit(id: string, name: string): Promise<AnswerConfiguration> {
        return this.api.put<AnswerConfiguration>(`${AnswerConfigurationResource.baseUrl}/${id}`, {name});
    }

    delete(id: string) {
        return this.api.delete(`${AnswerConfigurationResource.baseUrl}/${id}`);
    }
}
