import {Guard, GuardProps} from '@components/security';
import {
    Box,
    BoxProps,
    EmptyState,
    Text,
    factory,
    useDidUpdate,
    useProps,
    useStyles,
    type Factory,
    type StylesApiProps,
} from '@components/mantine';
import {LOCALIZED_APP_NAME} from '@core/configuration';
import {OwnedProps, useOwnership} from '@core/debug';
import {ReactNode, useEffect, useLayoutEffect, useRef, useState} from 'react';
import PageClasses from './Page.module.css';

import {PageProvider, useBlockingPrompt, usePageContext} from '../contexts';
import {usePageTitle} from '../hooks';
import {Locales} from '../strings';
import {PageBody, type PageBodyStylesNames} from './PageBody';
import {PageFooter, type PageFooterStylesNames} from './PageFooter';
import {PageHeader, type PageHeaderStylesNames} from './PageHeader';
import forbidden from './images/forbidden.svg';
import {PageLoader} from './PageLoader';

export type PageStyleNames = 'root' | PageHeaderStylesNames | PageBodyStylesNames | PageFooterStylesNames;

export type PageCssVariables = {
    root: '--page-container-size';
};

export type PageVariant = 'full' | 'centered';

export interface PageProps extends GuardProps, OwnedProps, BoxProps, StylesApiProps<PageFactory> {
    /**
     * The html title of the page
     */
    title: string;
    /**
     * Props for navigation blocking
     */
    navigationBlockerProps?: {allowedRoutes: string[]};
    /**
     * The layout of the page
     *
     * @default 'full'
     */
    variant?: PageVariant;
    children: ReactNode;
}

export type PageFactory = Factory<{
    props: PageProps;
    ref: HTMLDivElement;
    stylesNames: PageStyleNames;
    variant: PageVariant;
    vars: PageCssVariables;
    staticComponents: {
        Header: typeof PageHeader;
        Body: typeof PageBody;
        Footer: typeof PageFooter;
        Loader: typeof PageLoader;
    };
}>;

const defaultProps: Partial<PageProps> = {
    variant: 'full',
    canEdit: true,
    canRender: true,
};

export const Page = factory<PageFactory>((props, ref) => {
    const {
        classNames,
        styles,
        unstyled,
        children,
        title,
        owner,
        navigationBlockerProps,
        framework,
        canRender,
        canEdit,
        fallback,
        style,
        className,
        vars,
        ...others
    } = useProps('Page', defaultProps, props);
    const getStyles = useStyles<PageFactory>({
        name: 'Page',
        classes: PageClasses,
        props,
        style,
        className,
        classNames,
        styles,
        unstyled,
        vars,
    });

    const originalTitle = useRef(document.title);
    const [pageTitle, setPageTitle] = useState(title);
    useDidUpdate(() => {
        setPageTitle(title);
    }, [title]);
    useEffect(() => {
        document.title = Locales.format('page.metaTitle', {
            pageName: pageTitle,
            appName: LOCALIZED_APP_NAME,
        });
    }, [pageTitle]);
    useEffect(
        () => () => {
            document.title = originalTitle.current;
        },
        [],
    );

    useOwnership(owner, framework);

    const navigationBlocker = useBlockingPrompt();
    useLayoutEffect(() => {
        navigationBlocker?.setAllowedNavigationRoutes(navigationBlockerProps?.allowedRoutes ?? []);
    }, []);

    return (
        <PageProvider value={{variant: props.variant, getStyles, pageTitle, setPageTitle}}>
            <PageTitleSetter />
            <Box ref={ref} {...getStyles('root')} flex={1} {...others}>
                <Guard
                    canRender={canRender}
                    canEdit={canEdit}
                    fallback={
                        fallback ?? (
                            <EmptyState variant="feedback">
                                <EmptyState.Image src={forbidden} />
                                <EmptyState.Title>{Locales.format('page.forbidden.title')}</EmptyState.Title>
                                <EmptyState.Body>
                                    <Text size="lg">{Locales.format('page.forbidden.description', {title})}</Text>
                                </EmptyState.Body>
                            </EmptyState>
                        )
                    }
                >
                    {children}
                </Guard>
            </Box>
        </PageProvider>
    );
});

const PageTitleSetter = () => {
    const {pageTitle} = usePageContext();
    usePageTitle(pageTitle);
    return null;
};

Page.Header = PageHeader;
Page.Body = PageBody;
Page.Footer = PageFooter;
Page.Loader = PageLoader;
