import {API, PageModel, Resource} from '@coveo/platform-client';
import {RecommendationBaseRuleModel, RecommendationRulesListOptions} from '../../interfaces';

export class RecommendationRules extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/recommendations/slots/rules`;

    list<T extends RecommendationBaseRuleModel>(options: RecommendationRulesListOptions): Promise<PageModel<T>> {
        return this.api.get<PageModel<T>>(this.buildPath(`${RecommendationRules.baseUrl}`, options));
    }

    get<T extends RecommendationBaseRuleModel>(id: string): Promise<T> {
        return this.api.get<T>(`${RecommendationRules.baseUrl}/${id}`);
    }

    create<T extends RecommendationBaseRuleModel>(model: T): Promise<T> {
        return this.api.post<T>(RecommendationRules.baseUrl, model);
    }

    update<T extends RecommendationBaseRuleModel>(id: string, model: T): Promise<T> {
        return this.api.put<T>(`${RecommendationRules.baseUrl}/${id}`, model);
    }

    delete<T extends RecommendationBaseRuleModel>(id: string) {
        return this.api.delete<T>(`${RecommendationRules.baseUrl}/${id}`);
    }
}
