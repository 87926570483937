import {LoadingOverlay, Plasmantine, Popover, Table, createTheme} from '@components/mantine';
import {FunctionComponent, ReactNode} from 'react';

import TableClasses from './Table.module.css';

/**
 * This theme should only contain overrides that are specific to the platform app.
 * Style that could benefit other apps should go in `@coveord/plasma-mantine`
 */
const platformAppTheme = createTheme({
    fontSmoothing: true,
    components: {
        LoadingOverlay: LoadingOverlay.extend({
            defaultProps: {
                zIndex: 1400,
            },
        }),
        Popover: Popover.extend({
            defaultProps: {
                zIndex: 1300,
            },
        }),
        PlasmaTable: Table.extend({
            classNames: TableClasses,
        }),
    },
});

interface PlatformAppThemeProps {
    children?: ReactNode;
}

export const PlatformAppTheme: FunctionComponent<PlatformAppThemeProps> = ({children}) => (
    <Plasmantine theme={platformAppTheme}>{children}</Plasmantine>
);
