import {PrivilegeModel, RealmModel} from '@core/api';
import {Organization, OrganizationSelectors} from '@core/organization';
import {createSelector} from 'reselect';
import _ from 'underscore';
import {PrivilegesListSelector} from '../../privileges/selectors/PrivilegesListSelectors';
import {UserPrivilegesValidator} from '../utils/UserPrivilegesValidator';

import {UserRedux} from '../interfaces/UserInterface';
import {UserState} from '../store/UserStore';

const getPrivilegesValidator = createSelector(
    PrivilegesListSelector.getUserPrivilegesList,
    OrganizationSelectors.getOrganization,
    (userPrivileges: PrivilegeModel[], organization: Organization) =>
        new UserPrivilegesValidator(userPrivileges, organization),
);

const getUser = (state: UserState) => state.user;

const getUserFirstName = createSelector(getUser, (user: UserRedux) => user?.firstName);

const getUserLastName = createSelector(getUser, (user: UserRedux) => user?.lastName);

const getUserEmail = createSelector(getUser, (user: UserRedux) => user?.email);

const getUsername = createSelector(getUser, (user: UserRedux) => user?.username ?? '');

const getUserDisplayName = createSelector(
    getUser,
    getUsername,
    (user: UserRedux, username) => user?.displayName ?? user?.name ?? username,
);

const getUserRealms = createSelector(getUser, (user: UserRedux): RealmModel[] =>
    _.chain(user?.realms ?? [])
        .filter({member: true})
        .map('realm')
        .value(),
);

const isRelevanceInspectorAvailable = createSelector(
    getPrivilegesValidator,
    (validator) => validator.canExecuteQuery && validator.canReplayAnyQuery,
);

const getAdditionalInformation = createSelector(getUser, (user: UserRedux) => user?.additionalInformation ?? {});

const canViewAllContent = createSelector(getPrivilegesValidator, (user) => user.canViewAllContent);

export const UserSelectors = {
    getPrivilegesValidator,
    getUser,
    getUsername,
    getUserDisplayName,
    getUserRealms,
    getAdditionalInformation,
    getUserFirstName,
    getUserLastName,
    getUserEmail,
    isRelevanceInspectorAvailable,
    canViewAllContent,
};
