import {API, Resource} from '@coveo/platform-client';
import {
    DocumentPermissionForIdentityModel,
    DocumentSecurityIdentityModel,
} from '../interfaces/DocumentIdentityInterfaces';

/**
 * This API is used to get a document's permission model for a given identity.
 *
 * The API is publicly available, but intended for Coveo use (it's not in the public Swagger API).
 *
 * @private
 */
export class DocumentIdentityResource extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/indexes`;

    /**
     * Get the document permission model information for a security identity.
     *
     * @param {string} indexId The unique identifier of the target index.
     * @param {string} documentId The unique identifier of the item whose permissions to list.
     * @param {DocumentSecurityIdentityModel} the security identity for which we want the permissions from.
     *
     */
    getIdentityPermissions(indexId: string, documentId: string, identity: DocumentSecurityIdentityModel) {
        return this.api.post<DocumentPermissionForIdentityModel>(
            `${DocumentIdentityResource.baseUrl}/${indexId}/documents/${encodeURIComponent(
                encodeURIComponent(documentId),
            )}/permissions/identity`,
            identity,
        );
    }
}
