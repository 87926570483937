import {ColumnDef, createColumnHelper, useTable} from '@components/mantine';
import {ExtensionModel, Platform, useQuery} from '@core/api';
import {FunctionComponent} from 'react';
import {PrivilegeRow} from '../../PrivilegesTable.types';
import {GenericGranularPrivilegesTable} from '../GenericGranularPrivilegesTable';
import {Locales} from '../../../../strings/Locales';

const columnHelper = createColumnHelper<ExtensionModel>();

const columns: Array<ColumnDef<ExtensionModel>> = [
    columnHelper.accessor('name', {
        header: Locales.format('PrivilegesTable.Extensions.column.name.header'),
        enableSorting: false,
    }),
];

export const ExtensionsPrivilegesTable: FunctionComponent<PrivilegeRow> = (props) => {
    const store = useTable<ExtensionModel>();
    const query = useQuery({
        queryKey: ['extensions', 'list'],
        queryFn: async () => {
            const response = await Platform.extension.list();
            store.setTotalEntries(response.length);
            return response;
        },
    });
    return (
        <GenericGranularPrivilegesTable<ExtensionModel>
            store={store}
            columns={columns}
            query={query}
            resourceName={props.name.toLowerCase()}
            {...props}
        />
    );
};
