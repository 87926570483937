import {DOC_HOSTS, DocumentationHostsUrls} from '@core/configuration';

export interface GenerateDocLinkParams {
    id: string | number;
    host?: DOC_HOSTS;
    locale?: 'en' | 'fr';
    anchor?: string;
}

/**
 * Generate a link to the documentation website
 */
export const generateDocLink = (params: GenerateDocLinkParams): string => {
    const {host = DOC_HOSTS.DOCS, id, locale = 'en', anchor} = params;
    const endpoint: string = id ? `/${id}` : '';
    const section: string = anchor ? `#${anchor}` : '';

    return `${DocumentationHostsUrls[host]}/${locale}${endpoint}${section}`;
};
