import {Resource} from '@coveo/platform-client';
import {
    OrganizationMonitoringEndpointUsageModel,
    OrganizationRegionUsageModel,
} from '../interfaces/OrganizationMonitoringInterfaces';

/**
 * This API is used to monitor information about an organization's configuration. For now, it
 * is mainly used to query information about the custom DNS.
 *
 * The API is publicly available, but intended for Coveo use (it's not in the public Swagger API).
 */
export class OrganizationMonitoringResource extends Resource {
    static baseUrl = '/rest/monitoring/global/organizations';

    /**
     * @param organizationId The ID of the organization
     * @returns The organization's custom DNS endpoint usage
     */
    get(organizationId: string) {
        return this.api.get<OrganizationMonitoringEndpointUsageModel>(
            `${OrganizationMonitoringResource.baseUrl}/${organizationId}/endpoints/usage`,
        );
    }

    /**
     * @param organizationId The ID of the organization
     * @returns The organization's region usage
     */
    getRegionUsage(organizationId: string) {
        return this.api.get<OrganizationRegionUsageModel>(
            `${OrganizationMonitoringResource.baseUrl}/${organizationId}/usage/regions`,
        );
    }
}
