import {name, os} from 'platform';

import {TeamProject} from '../data';

const priority = '3'; // medium

type ReturnUseJiraLink = URL | undefined;

export const useJiraLink = (app: string, ownerProject?: TeamProject): ReturnUseJiraLink => {
    if (!ownerProject) {
        return undefined;
    }

    // https://coveord.atlassian.net/secure/WikiRendererHelpAction.jspa?section=all
    const description = `<Describe your issue here>
----
_Issue created using the contextual "Report a problem" button._
From URL: [${window.location.href}]
Window viewport size: *${window.innerWidth}px x ${window.innerHeight}px*
Window screen resolution size: *${screen.width}px x ${screen.height}px*
Browser: *${name}*
Operating System: *${os}*
`;

    // https://confluence.atlassian.com/jirakb/how-to-create-issues-using-direct-html-links-in-jira-server-159474.html
    const jiraTicketUrl = new URL('https://coveord.atlassian.net/jira/CreateIssueDetails!init.jspa');
    const jiraTicketParams = new URLSearchParams({
        pid: ownerProject.id,
        issuetype: ownerProject.bugIssueTypeId,
        priority,
        labels: `reported-from-${app}`,
        description,
    });
    jiraTicketUrl.search = jiraTicketParams.toString();

    return jiraTicketUrl;
};
