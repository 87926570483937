import {createContext} from 'react';

interface NavigationContextType {
    /**
     * Whether the Navigation.SideBar is collapsed
     */
    collapsed: boolean;
    /**
     * Allows to toggle the Navigation.SideBar between collapsed and expanded state
     */
    toggleCollapsed: (collapse?: React.SetStateAction<boolean>) => void;
    /**
     * Whether the navigation has a search feature (search box & Meta+K shortcut)
     */
    searchable?: boolean;
}

export const NavigationContext = createContext<NavigationContextType>(null);
