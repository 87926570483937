import {PrivilegeModel} from '@core/api';

export const granularPrivilegesIds = [
    'SOURCE_PLATFORM',
    'GROUP_PLATFORM',
    'API_KEY_PLATFORM',
    'INDEXING_PIPELINE_EXTENSION_PLATFORM',
    'QUERY_PIPELINE_SEARCH_API',
] as const;

/**
 * This constant seems useless, but its purpose is to have a memoized empty array to avoid unnecessary re-renders.
 */
export const noAccess: PrivilegeModel[] = [];
